<template>
  <div class="crud_form_wrapper single_show_content_wrapper">
    
    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <h5 class="mb-5">{{ $t('PLACEHOLDERS.subscribtions_log') }}</h5>
      <div class="row">
        <table class="table table-striped text-center">
          <thead>
            <td>{{ $t('TABLES.Addresses.serialNumber') }}</td>
            <td>{{ $t('PLACEHOLDERS.subscription_fee') }}</td>
            <td>{{ $t('PLACEHOLDERS.added_tax') }}</td>
            <td>{{ $t('PLACEHOLDERS.subscription_total') }}</td>
            <td>{{ $t('PLACEHOLDERS.subscription_date') }}</td>
            <td>{{ $t('PLACEHOLDERS.subscription_duration') }}</td>
            <td>{{ $t('PLACEHOLDERS.subscription_status') }}</td>
          </thead>
          <tbody>
          <template v-if="data.length > 0">
            <tr v-for="(item,key) in data">
              <td>{{ key + 1 }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.added_value }}</td>
              <td>{{ item.total }}</td>
              <td>{{ $t('PLACEHOLDERS.from') + ' ( ' + item.from_date + ' )' + ' ' + $t('PLACEHOLDERS.to') + ' ( ' + item.to_date + ' )'}}</td>
              <td>{{ item.duration }}</td>
              <td>{{ item.status ? $t('PLACEHOLDERS.sari') : $t('PLACEHOLDERS.montahi') }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="7">{{ $t('PLACEHOLDERS.there_is_no_subscriptions') }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <!-- End:: Single Step Form Content -->
  </div>
</template>

<script>
export default {
  name: "SubscriptionsClient",

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      // Start:: Loading Data
      isWaitingRequest: false,
      // End:: Loading Data

      // Start:: Table Data
      addressesTableHeaders: [
        {text: this.$t("TABLES.Addresses.serialNumber")},
        {text: this.$t("TABLES.Addresses.address")},
        {text: this.$t("TABLES.Addresses.longitude")},
        {text: this.$t("TABLES.Addresses.latitude")},
        {text: this.$t("TABLES.Addresses.type")},
        {text: this.$t("TABLES.Addresses.isDefault")},
      ],
      // End:: Table Data

      // Start:: Data
      data: {},
    };
  },

  methods: {
    async getDataToShow() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `provider/${this.id}/subscriptions`,
        });
        this.data = res.data.data.subscriptions;
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
  },

  created() {
    this.getDataToShow();
  },
};
</script>
