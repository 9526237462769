import Vue from "vue";
import VueRouter from "vue-router";

// Start:: Importing Middleware
import auth from "../middleware/auth.js";
// End:: Importing Middleware

// Start:: Importing Router Components
import Authentication from "../pages/Authentication.vue";
import LoginForm from "../views/Auth/LoginForm.vue";
import ResetPasswordEmailForm from "../views/Auth/ResetPasswordEmailForm.vue";
import EmailVerificationForm from "../views/Auth/EmailVerificationForm.vue";
import ChangePhoneNumberForm from "../views/Auth/ChangePhoneNumber.vue";
import ResetPasswordForm from "../views/Auth/ResetPasswordForm.vue";
import AppContentWrapper from "../pages/AppContentWrapper.vue";

// ============== Start:: Home Page Route
import HomePage from "../pages/HomePage.vue";
// ============== End:: Home Page Route

// ============== Start:: Clients Routes
import ClientsHome from "../views/Cruds/Clients/Home.vue";
import AllClients from "../views/Cruds/Clients/ShowAll.vue";
import ShowClient from "../views/Cruds/Clients/Show.vue";
import SubscriptionsClient from "../views/Cruds/Clients/Subscriptions.vue";
import DocumentedSubscriptionsClient from "../views/Cruds/Clients/Documented.vue";
// ============== End:: Clients Routes
 
// ============== Start:: Posts Routes
import PostsHome from "../views/Cruds/Posts/Home.vue";
import AllPosts from "../views/Cruds/Posts/ShowAll.vue";
import ShowPost from "../views/Cruds/Posts/Show.vue";
// ============== End:: Posts Routes
// 
// ============== Start:: Chats Routes
import ChatsHome from "../views/Cruds/Chats/Home.vue";
import AllChats from "../views/Cruds/Chats/ShowAll.vue";
import ShowChat from "../views/Cruds/Chats/Show.vue";
// ============== End:: Chats Routes
// 
// ============== Start:: PromotedPosts Routes
import PromotedPostsHome from "../views/Cruds/PromotedPosts/Home.vue";
import AllPromotedPosts from "../views/Cruds/PromotedPosts/ShowAll.vue";
import ShowPromotedPost from "../views/Cruds/PromotedPosts/Show.vue";
// ============== End:: PromotedPosts Routes

// ============== Start:: DocumentedSubscriptions Routes
import DocumentedSubscriptionsHome from "../views/Cruds/DocumentedSubscriptions/Home.vue";
import AllDocumentedSubscriptions from "../views/Cruds/DocumentedSubscriptions/ShowAll.vue";
import ShowDocumentedSubscriptions from "../views/Cruds/DocumentedSubscriptions/Show.vue";
// ============== End:: DocumentedSubscriptions Routes

// ============== Start:: Stories Routes
import StoriesHome from "../views/Cruds/Stories/Home.vue";
import AllStories from "../views/Cruds/Stories/ShowAll.vue";
import ShowStory from "../views/Cruds/Stories/Show.vue";
// ============== End:: Stories Routes

// ============== Start:: Drivers Requests Routes
import DriverRequestsHome from "../views/Cruds/Drivers/DriversRequests/Home.vue";
import AllDriverRequests from "../views/Cruds/Drivers/DriversRequests/ShowAll.vue";
import ShowDriverRequest from "../views/Cruds/Drivers/DriversRequests/Show.vue";
// ============== End:: Drivers Requests Routes

// ============== Start:: Drivers Edits Requests Routes
import DriverEditsRequestsHome from "../views/Cruds/Drivers/DriversEditsRequests/Home.vue";
import AllDriverEditsRequests from "../views/Cruds/Drivers/DriversEditsRequests/ShowAll.vue";
import ShowDriverEditsRequest from "../views/Cruds/Drivers/DriversEditsRequests/Show.vue";
// ============== End:: Drivers Edits Requests Routes

// ============== Start:: Drivers Management Routes
import DriverManagementsHome from "../views/Cruds/Drivers/DriversManagement/Home.vue";
import AllDriverManagements from "../views/Cruds/Drivers/DriversManagement/ShowAll.vue";
import ShowDriverManagement from "../views/Cruds/Drivers/DriversManagement/Show.vue";
import SubscriptionsProvider from "../views/Cruds/Drivers/DriversManagement/Subscriptions.vue";
// ============== End:: Drivers Management Routes

// ============== Start:: Trips Routes
import TripsHome from "../views/Cruds/Trips/Home.vue";
import AllTrips from "../views/Cruds/Trips/ShowAll.vue";
import ShowTrip from "../views/Cruds/Trips/Show.vue";
import ShowTripChat from "../views/Cruds/Trips/Chat.vue";
// ============== End:: Trips Routes

// ============== Start:: Category Routes
import CategoryHome from "../views/Cruds/Category/Home.vue";
import AllCategory from "../views/Cruds/Category/ShowAll.vue";
import CreateCategory from "../views/Cruds/Category/Create.vue";
import EditCategory from "../views/Cruds/Category/Edit.vue";
import ShowCategory from "../views/Cruds/Category/Show.vue";
// ============== End:: Category Routes

// ============== Start:: Contact Messages Routes
import ContactMessagesHome from "../views/Cruds/ContactMessages/Home.vue";
import AllContactMessages from "../views/Cruds/ContactMessages/ShowAll.vue";
// ============== End:: Contact Messages Routes

// ============== Start:: App Content Routes
import AppContentHome from "../views/Cruds/AppContent/Home.vue";
import AboutUs from "../views/Cruds/AppContent/AboutUs.vue";
import Terms from "../views/Cruds/AppContent/Terms.vue";
import PrivacyPolicy from "../views/Cruds/AppContent/PrivacyPolicy.vue";
import AqarUses from "../views/Cruds/AppContent/AqarUses.vue";
import AqarConditions from "../views/Cruds/AppContent/AqarConditions.vue";
import BankAccounts from "../views/Cruds/AppContent/BankAccounts.vue";
// ============== End:: App Content Routes

// ============== Start:: App Settings Routes
import AppSettingsHome from "../views/Cruds/AppSettings/Home.vue";
import GeneralSettings from "../views/Cruds/AppSettings/GeneralSettings.vue";
import AppSettings from "../views/Cruds/AppSettings/AppSettings.vue";
// ============== End:: App Settings Routes

// ============== Start:: Interests Routes
import InterestsHome from "../views/Cruds/Interests/Home.vue";
import AllInterests from "../views/Cruds/Interests/ShowAll.vue";
import CreateInterests from "../views/Cruds/Interests/Create.vue";
import EditInterests from "../views/Cruds/Interests/Edit.vue";
import ShowInterests from "../views/Cruds/Interests/Show.vue";
// ============== End:: Interests Routes

// ============== Start:: Sports Routes
import SportsHome from "../views/Cruds/Sports/Home.vue";
import AllSports from "../views/Cruds/Sports/ShowAll.vue";
import CreateSports from "../views/Cruds/Sports/Create.vue";
import EditSports from "../views/Cruds/Sports/Edit.vue";
import ShowSports from "../views/Cruds/Sports/Show.vue";
// ============== End:: Sports Routes

// ============== Start:: Teams Routes
import TeamsHome from "../views/Cruds/Teams/Home.vue";
import AllTeams from "../views/Cruds/Teams/ShowAll.vue";
import CreateTeams from "../views/Cruds/Teams/Create.vue";
import EditTeams from "../views/Cruds/Teams/Edit.vue";
import ShowTeams from "../views/Cruds/Teams/Show.vue";
// ============== End:: Teams Routes
// 
// ============== Start:: Sliders Routes
import SlidersHome from "../views/Cruds/Sliders/Home.vue";
import AllSliders from "../views/Cruds/Sliders/ShowAll.vue";
import CreateSliders from "../views/Cruds/Sliders/Create.vue";
import EditSliders from "../views/Cruds/Sliders/Edit.vue";
import ShowSliders from "../views/Cruds/Sliders/Show.vue";
// ============== End:: Teams Routes

// ============== Start:: Leagues Routes
import LeaguesHome from "../views/Cruds/Leagues/Home.vue";
import AllLeagues from "../views/Cruds/Leagues/ShowAll.vue";
import CreateLeagues from "../views/Cruds/Leagues/Create.vue";
import EditLeagues from "../views/Cruds/Leagues/Edit.vue";
import ShowLeagues from "../views/Cruds/Leagues/Show.vue";
// ============== End:: Leagues Routes

// ============== Start:: Packages Routes
import PackagesHome from "../views/Cruds/Packages/Home.vue";
import AllPackages from "../views/Cruds/Packages/ShowAll.vue";
import CreatePackages from "../views/Cruds/Packages/Create.vue";
import EditPackages from "../views/Cruds/Packages/Edit.vue";
import ShowPackages from "../views/Cruds/Packages/Show.vue";
// ============== End:: Packages Routes

// ============== Start:: News Routes
import NewsHome from "../views/Cruds/News/Home.vue";
import AllNews from "../views/Cruds/News/ShowAll.vue";
import CreateNews from "../views/Cruds/News/Create.vue";
import EditNews from "../views/Cruds/News/Edit.vue";
import ShowNews from "../views/Cruds/News/Show.vue";
// ============== End:: News Routes

// ============== Start:: Countries Routes
import CountriesHome from "../views/Cruds/Countries/Home.vue";
import AllCountries from "../views/Cruds/Countries/ShowAll.vue";
import CreateCountries from "../views/Cruds/Countries/Create.vue";
import EditCountries from "../views/Cruds/Countries/Edit.vue";
import ShowCountries from "../views/Cruds/Countries/Show.vue";
// ============== End:: Countries Routes

// ============== Start:: Cities Routes
import CitiesHome from "../views/Cruds/Cities/Home.vue";
import AllCities from "../views/Cruds/Cities/ShowAll.vue";
import CreateCities from "../views/Cruds/Cities/Create.vue";
import EditCities from "../views/Cruds/Cities/Edit.vue";
import ShowCities from "../views/Cruds/Cities/Show.vue";
// ============== End:: Cities Routes

// ============== Start:: Areas Routes
import AreasHome from "../views/Cruds/Areas/Home.vue";
import AllAreas from "../views/Cruds/Areas/ShowAll.vue";
import CreateAreas from "../views/Cruds/Areas/Create.vue";
import EditAreas from "../views/Cruds/Areas/Edit.vue";
import ShowAreas from "../views/Cruds/Areas/Show.vue";
// ============== End:: Areas Routes

// ============== Start:: Nationalities Routes
import NationalitiesHome from "../views/Cruds/Nationalities/Home.vue";
import AllNationalities from "../views/Cruds/Nationalities/ShowAll.vue";
import CreateNationalities from "../views/Cruds/Nationalities/Create.vue";
import EditNationalities from "../views/Cruds/Nationalities/Edit.vue";
// ============== End:: Nationalities Routes


// ============== Start:: Payments Routes
import PaymentsHome from "../views/Cruds/Payments/Home.vue";
import AllPayments from "../views/Cruds/Payments/ShowAll.vue";
// ============== End:: Payments Routes

// ============== Start:: Roles Routes
import RolesHome from "../views/Cruds/Roles/Home.vue";
import AllRoles from "../views/Cruds/Roles/ShowAll.vue";
import CreateRole from "../views/Cruds/Roles/Create.vue";
import EditRole from "../views/Cruds/Roles/Edit.vue";
// ============== End:: Roles Routes

// ============== Start:: Pages Routes
import PagesHome from "../views/Cruds/Pages/Home.vue";
import AllPages from "../views/Cruds/Pages/ShowAll.vue";
import CreatePages from "../views/Cruds/Pages/Create.vue";
import EditPages from "../views/Cruds/Pages/Edit.vue";
import ShowPages from "../views/Cruds/Pages/Show.vue";
// ============== End:: Pages Routes

// ============== Start:: Admins Routes
import AdminsHome from "../views/Cruds/Admins/Home.vue";
import AllAdmins from "../views/Cruds/Admins/ShowAll.vue";
import CreateAdmin from "../views/Cruds/Admins/Create.vue";
import EditAdmin from "../views/Cruds/Admins/Edit.vue";
import ShowAdmin from "../views/Cruds/Admins/Show.vue";
// ============== End:: Admins Routes

// ============== Start:: All Notifications Routes
import NotificationsHome from "../views/Cruds/Notifications/Home.vue";
import AllNotifications from "../views/Cruds/Notifications/ShowAll.vue";
import CreateNotifications from "../views/Cruds/Notifications/Create.vue";
import ShowNotifications from "../views/Cruds/Notifications/Show.vue";
// ============== End:: All Notifications Routes

// ============== Start:: Banks Routes
import BanksHome from "../views/Cruds/Banks/Home.vue";
import AllBanks from "../views/Cruds/Banks/ShowAll.vue";
import CreateBanks from "../views/Cruds/Banks/Create.vue";
import EditBanks from "../views/Cruds/Banks/Edit.vue";
import ShowBanks from "../views/Cruds/Banks/Show.vue";
// ============== End:: Banks Routes

// ============== Start:: Opinion Routes
import OpinionHome from "../views/Cruds/Opinions/Home.vue";
import AllOpinion from "../views/Cruds/Opinions/ShowAll.vue";
import ShowOpinion from "../views/Cruds/Opinions/Show.vue";
// ============== End:: Opinion Routes

// ============== Start:: Services Routes
import ServicesHome from "../views/Cruds/Services/Home.vue";
import AllServices from "../views/Cruds/Services/ShowAll.vue";
import CreateServices from "../views/Cruds/Services/Create.vue";
import EditServices from "../views/Cruds/Services/Edit.vue";
import ShowServices from "../views/Cruds/Services/Show.vue";
// ============== End:: Services Routes

// ============== Start:: SubServices Routes
import SubServicesHome from "../views/Cruds/SubServices/Home.vue";
import AllSubServices from "../views/Cruds/SubServices/ShowAll.vue";
import CreateSubServices from "../views/Cruds/SubServices/Create.vue";
import EditSubServices from "../views/Cruds/SubServices/Edit.vue";
import ShowSubServices from "../views/Cruds/SubServices/Show.vue";
// ============== End:: SubServices Routes

// ============== Start:: DocumentedSubscriptions Reports Routes
import SubscriptionReportsHome from "../views/Cruds/SubscripionReports/Home.vue";
import AllSubscriptionReports from "../views/Cruds/SubscripionReports/ShowAll.vue";
// ============== End:: DocumentedSubscriptions Reports Routes
// 
// ============== Start:: Reports Routes
import ReportsHome from "../views/Cruds/Reports/Home.vue";
import AllReports from "../views/Cruds/Reports/ShowAll.vue";
// ============== End::  Reports Routes

// ============== Start:: Finished Orders Reports Routes
import FinishedOrdersReportsHome from "../views/Cruds/FinishedOrdersReports/Home.vue";
import AllFinishedOrdersReports from "../views/Cruds/FinishedOrdersReports/ShowAll.vue";
// ============== End:: Finished Orders Reports Routes

// ============== Start:: Un-Finished Orders Reports Routes
import UnFinishedOrdersReportsHome from "../views/Cruds/UnFinishedOrdersReports/Home.vue";
import AllUnFinishedOrdersReports from "../views/Cruds/UnFinishedOrdersReports/ShowAll.vue";
// ============== End:: Un-Finished Orders Reports Routes

// ============== Start:: Forbidden Route
import ForbiddenPage from "../pages/ForbiddenPage.vue";
// ============== End:: Forbidden Route

// ============== Start:: Not Found Route
import NotFoundPage from "../pages/NotFound.vue";
// ============== End:: Not Found Route
// End:: Importing Router Components

Vue.use(VueRouter);
const routes = [
  // ***************** Start:: Auth Routes Config
  {
    path: "/",
    component: Authentication,
    children: [
      {
        path: "/",
        component: LoginForm,
        name: "LoginForm",
      },
      {
        path: "/reset-password-email",
        name: "ResetPasswordEmailForm",
        component: ResetPasswordEmailForm,
      },
      {
        path: "/email-verification/:verification_type",
        name: "EmailVerificationForm",
        component: EmailVerificationForm,
      },
      {
        path: "/chang-phone-number",
        name: "ChangePhoneNumberForm",
        component: ChangePhoneNumberForm,
      },
      {
        path: "/reset-password",
        name: "ResetPasswordForm",
        component: ResetPasswordForm,
      },
    ],
  },
  // ***************** End:: Auth Routes Config

  // ***************** Start:: Content Routes Config
  {
    path: "/home",
    component: AppContentWrapper,
    children: [
      // Start:: Home Route Config
      {
        path: "/home",
        component: HomePage,
        meta: {
          middleware: [auth],
        },
      },
      // End:: Home Route Config

      // Start:: Clients Routes Config
      {
        path: "/clients",
        name: "ClientsHome",
        component: ClientsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllClients",
            component: AllClients,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "clients index",
                subject: "clients",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowClient",
            component: ShowClient,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "clients index",
                subject: "clients",
              },
            },
          },          
          {
            path: "subscriptions/:id",
            name: "SubscriptionsClient",
            component: SubscriptionsClient,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "clients index",
                subject: "clients",
              },
            },
          },
          {
            path: "documented-subscriptions/:id",
            name: "DocumentedSubscriptionsClient",
            component: DocumentedSubscriptionsClient,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "subscriptions index",
                subject: "subscriptions",
              },
            },
          },
        ],
      },
      // End:: Clients Routes Config
      
      // Start:: Posts Routes Config
      {
        path: "/posts",
        name: "PostsHome",
        component: PostsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllPosts",
            component: AllPosts,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "posts index",
                subject: "posts",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowPost",
            component: ShowPost,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "posts index",
                subject: "posts",
              },
            },
          },
        ],
      },
      // End:: Posts Routes Config  
      // Start:: Chats Routes Config
      {
        path: "/chats",
        name: "ChatsHome",
        component: ChatsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllChats",
            component: AllChats,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "chats index",
                subject: "chats",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowChat",
            component: ShowChat,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "chats index",
                subject: "chats",
              },
            },
          },
        ],
      },
      // End:: Chats Routes Config 
      // Start:: Posts Routes Config
      {
        path: "/promotedPosts",
        name: "PromotedPostsHome",
        component: PromotedPostsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllPromotedPosts",
            component: AllPromotedPosts,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "promoted-posts index",
                subject: "promoted-posts",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowPromotedPost",
            component: ShowPromotedPost,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "promoted-posts index",
                subject: "promoted-posts",
              },
            },
          },
        ],
      },
      // End:: PromotedPosts Routes Config
      {
        path: "/documentedsubscriptions",
        name: "DocumentedSubscriptions",
        component: DocumentedSubscriptionsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllDocumentedSubscriptions",
            component: AllDocumentedSubscriptions,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "subscriptions index",
                subject: "subscriptions",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowDocumentedSubscriptions",
            component: ShowDocumentedSubscriptions,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "subscriptions index",
                subject: "subscriptions",
              },
            },
          },
        ],
      },
      // End:: PromotedPosts Routes Config
      
      // Start:: Stories Routes Config
      {
        path: "/stories",
        name: "StoriesHome",
        component: StoriesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllStories",
            component: AllStories,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "stories index",
                subject: "stories",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowStory",
            component: ShowStory,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "stories index",
                subject: "stories",
              },
            },
          },
        ],
      },
      // End:: Stories Routes Config
      
      // Start:: Drivers Requests Routes Config
      {
        path: "/providers-requests",
        name: "DriverRequestsHome",
        component: DriverRequestsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllDriversRequests",
            component: AllDriverRequests,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "providers requests index",
                subject: "providers_requests",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowDriverRequest",
            component: ShowDriverRequest,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "providers requests index",
                subject: "providers_requests",
              },
            },
          },
        ],
      },
      // End:: Drivers Requests Routes Config

      // Start:: Drivers Edits Requests Routes Config
      {
        path: "/drivers-edits-requests",
        name: "DriverEditsRequestsHome",
        component: DriverEditsRequestsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllDriverEditsRequests",
            component: AllDriverEditsRequests,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "amendment requests index",
                subject: "amendment_requests",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowDriverEditsRequest",
            component: ShowDriverEditsRequest,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "damendment requests index",
                subject: "amendment_requests",
              },
            },
          },
        ],
      },
      // End:: Drivers Edits Requests Routes Config

      // Start:: Trips Routes Config
      {
        path: "/orders",
        name: "TripsHome",
        component: TripsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllTrips",
            component: AllTrips,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "trips index",
                subject: "trips",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowTrip",
            component: ShowTrip,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "trips show",
                subject: "trips",
              },
            },
          },          
          {
            path: "chat/:id",
            name: "ShowTripChat",
            component: ShowTripChat,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "trips show",
                subject: "trips",
              },
            },
          },
        ],
      },
      // End:: Trips Routes Config
      
      // Start:: Drivers Management Routes Config
      {
        path: "/providers-management",
        name: "DriversManagementHome",
        component: DriverManagementsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllDriverManagements",
            component: AllDriverManagements,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "providers index",
                subject: "providers",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowDriverManagement",
            component: ShowDriverManagement,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "providers show",
                subject: "providers",
              },
            },
          },          
          {
            path: "subscriptions/:id",
            name: "ShowSubscriptionsManagement",
            component: SubscriptionsProvider,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "providers show",
                subject: "providers",
              },
            },
          },
        ],
      },
      // End:: Drivers Management Routes Config

      // Start:: Category  Config
      {
        path: "/category",
        name: "CategoryHome",
        component: CategoryHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllCategory",
            component: AllCategory,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "categories index",
                subject: "categories",
              },
            },
          },
          {
            path: "create",
            name: "CreateCategory",
            component: CreateCategory,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "categories create",
                subject: "categories",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditCategory",
            component: EditCategory,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "categories edit",
                subject: "categories",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowCategory",
            component: ShowCategory,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "categories show",
                subject: "categories",
              },
            },
          },
        ],
      },
      // End:: Category Config

      // Start:: Interests  Config
      {
        path: "/interests",
        name: "InterestsHome",
        component: InterestsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllInterests",
            component: AllInterests,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "interests index",
                subject: "interests",
              },
            },
          },
          {
            path: "create",
            name: "CreateInterests",
            component: CreateInterests,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "interests create",
                subject: "interests",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditInterests",
            component: EditInterests,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "interests edit",
                subject: "interests",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowInterests",
            component: ShowInterests,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "interests show",
                subject: "interests",
              },
            },
          },
        ],
      },
      // End:: Interests Config
      
      // Start:: Sports  Config
      {
        path: "/sports",
        name: "SportsHome",
        component: SportsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllSports",
            component: AllSports,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "sports index",
                subject: "sports",
              },
            },
          },
          {
            path: "create",
            name: "CreateSports",
            component: CreateSports,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "sports create",
                subject: "sports",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditSports",
            component: EditSports,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "sports edit",
                subject: "sports",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowSports",
            component: ShowSports,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "sports show",
                subject: "sports",
              },
            },
          },
        ],
      },
      // End:: Sports Config
      
      // Start:: Teams  Config
      {
        path: "/teams",
        name: "TeamsHome",
        component: TeamsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllTeams",
            component: AllTeams,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "teams index",
                subject: "teams",
              },
            },
          },
          {
            path: "create",
            name: "CreateTeams",
            component: CreateTeams,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "teams create",
                subject: "teams",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditTeams",
            component: EditTeams,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "teams edit",
                subject: "teams",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowTeams",
            component: ShowTeams,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "teams show",
                subject: "teams",
              },
            },
          },
        ],
      },
      // End:: Teams Config  

      // Start:: Sliders  Config
      {
        path: "/sliders",
        name: "SlidersHome",
        component: SlidersHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllSliders",
            component: AllSliders,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "sliders index",
                subject: "sliders",
              },
            },
          },
          {
            path: "create",
            name: "CreateSliders",
            component: CreateSliders,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "sliders create",
                subject: "sliders",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditSliders",
            component: EditSliders,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "sliders edit",
                subject: "sliders",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowSliders",
            component: ShowSliders,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "sliders show",
                subject: "sliders",
              },
            },
          },
        ],
      },
      // End:: Sliders Config  

      // Start:: Leagues  Config
      {
        path: "/leagues",
        name: "LeaguesHome",
        component: LeaguesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllLeagues",
            component: AllLeagues,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "leagues index",
                subject: "leagues",
              },
            },
          },
          {
            path: "create",
            name: "CreateLeagues",
            component: CreateLeagues,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "leagues create",
                subject: "leagues",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditLeagues",
            component: EditLeagues,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "leagues edit",
                subject: "leagues",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowLeagues",
            component: ShowLeagues,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "leagues show",
                subject: "leagues",
              },
            },
          },
        ],
      },
      // End:: Leagues Config 
      // Start:: Packages  Config
      {
        path: "/packages",
        name: "PackagesHome",
        component: PackagesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllPackages",
            component: AllPackages,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "packages index",
                subject: "packages",
              },
            },
          },
          {
            path: "create",
            name: "CreatePackages",
            component: CreatePackages,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "packages create",
                subject: "packages",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditPackages",
            component: EditPackages,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "packages edit",
                subject: "packages",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowPackages",
            component: ShowPackages,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "packages show",
                subject: "packages",
              },
            },
          },
        ],
      },
      // End:: Packages Config
      
      // Start:: News  Config
      {
        path: "/news",
        name: "NewsHome",
        component: NewsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllNews",
            component: AllNews,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "news index",
                subject: "news",
              },
            },
          },
          {
            path: "create",
            name: "CreateNews",
            component: CreateNews,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "news create",
                subject: "news",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditNews",
            component: EditNews,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "news edit",
                subject: "news",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowNews",
            component: ShowNews,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "news show",
                subject: "news",
              },
            },
          },
        ],
      },
      // End:: News Config
      
      // Start:: Cities  Config
      {
        path: "/cities",
        name: "CitiesHome",
        component: CitiesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllCities",
            component: AllCities,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "cities index",
                subject: "cities",
              },
            },
          },
          {
            path: "create",
            name: "CreateCities",
            component: CreateCities,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "cities create",
                subject: "cities",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditCities",
            component: EditCities,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "cities edit",
                subject: "cities",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowCities",
            component: ShowCities,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "cities show",
                subject: "cities",
              },
            },
          },
        ],
      },
      // End:: Cities Config 
      // 
      // Start:: Countries  Config
      {
        path: "/countries",
        name: "CountriesHome",
        component: CountriesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllCountries",
            component: AllCountries,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "countries index",
                subject: "countries",
              },
            },
          },
          {
            path: "create",
            name: "CreateCountries",
            component: CreateCountries,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "countries create",
                subject: "countries",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditCountries",
            component: EditCountries,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "countries edit",
                subject: "countries",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowCountries",
            component: ShowCountries,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "countries show",
                subject: "countries",
              },
            },
          },
        ],
      },
      // End:: Countries Config

      // Start:: Nationalities  Config
      {
        path: "/nationalities",
        name: "NationalitiesHome",
        component: NationalitiesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllNationalities",
            component: AllNationalities,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "nationalities index",
                subject: "nationalities",
              },
            },
          },
          {
            path: "create",
            name: "CreateNationalities",
            component: CreateNationalities,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "nationalities create",
                subject: "nationalities",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditNationalities",
            component: EditNationalities,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "nationalities edit",
                subject: "nationalities",
              },
            },
          },
        ],
      },
      // End:: Nationalities Config

      // Start:: Payments  Config
      {
        path: "/payments",
        name: "PaymentsHome",
        component: PaymentsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllPayments",
            component: AllPayments,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "payments index",
                subject: "payments",
              },
            },
          }
        ],
      },
      // End:: Payments Config

      // Start:: Areas  Config
      {
        path: "/states",
        name: "AreasHome",
        component: AreasHome,
        meta: {
          middleware: [auth],
        },
        children: [
            {
            path: "all",
            name: "AllAreas",
            component: AllAreas,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "states index",
                subject: "states",
              },
            },
          },
          {
            path: "create",
            name: "CreateAreas",
            component: CreateAreas,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "states create",
                subject: "states",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditAreas",
            component: EditAreas,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "states edit",
                subject: "states",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowAreas",
            component: ShowAreas,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "states show",
                subject: "states",
              },
            },
          },
        ],
      },
      // End:: Areas Config

      // Start:: Subscription Reports Routes Config
      {
        path: "/subscription-reports",
        name: "SubscriptionReportsHome",
        component: SubscriptionReportsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "index",
            name: "AllSubscriptionReports",
            component: AllSubscriptionReports,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "subscriptions reports index",
                subject: "subscriptions_reports",
              },
            },
          },
        ],
      },
      // End:: Subscription Reports Routes Config
      
      // Start::  Reports Routes Config
      {
        path: "/reports",
        name: "ReportsHome",
        component: ReportsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "index",
            name: "AllReports",
            component: AllReports,
            meta: {
              middleware: [auth],
              // requiresPermission: {
              //   action: "reports index",
              //   subject: "reports",
              // },
            },
          },
        ],
      },
      // End:: Reports Routes Config

      // Start:: Finished Orders Reports Routes Config
      {
        path: "/finished-orders-reports",
        name: "FinishedOrdersReportsHome",
        component: FinishedOrdersReportsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "index",
            name: "AllFinishedOrdersReports",
            component: AllFinishedOrdersReports,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "finished orders reports index",
                subject: "finished_orders_reports",
              },
            },
          },
        ],
      },
      // End:: Finished Orders Reports Routes Config
      
      // Start:: Un-Finished Orders Reports Routes Config
      {
        path: "/active-orders-reports",
        name: "UnFinishedOrdersReportsHome",
        component: UnFinishedOrdersReportsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "index",
            name: "UnAllFinishedOrdersReports",
            component: AllUnFinishedOrdersReports,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "un finished orders reports index",
                subject: "un_finished_orders_reports",
              },
            },
          },
        ],
      },
      // End:: Un-Finished Orders Reports Routes Config
      

      // Start:: banks Routes Config
      {
        path: "/banks",
        name: "BanksHome",
        component: BanksHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllBanks",
            component: AllBanks,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "banks index",
                subject: "banks",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowBanks",
            component: ShowBanks,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "banks show",
                subject: "banks",
              },
            },
          },
          {
            path: "create",
            name: "CreateBanks",
            component: CreateBanks,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "banks create",
                subject: "banks",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditBanks",
            component: EditBanks,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "banks edit",
                subject: "banks",
              },
            },
          },
        ],
      },
      // End:: banks Routes Config

      // Start:: opinion Routes Config
      {
        path: "/opinions",
        name: "OpinionHome",
        component: OpinionHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllOpinion",
            component: AllOpinion,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "opinions index",
                subject: "opinions",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowOpinion",
            component: ShowOpinion,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "opinions show",
                subject: "opinions",
              },
            },
          },
        ],
      },
      // End:: opinion Routes Config

      // Start:: services Routes Config
      {
        path: "/services",
        name: "ServicesHome",
        component: ServicesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllServices",
            component: AllServices,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "services index",
                subject: "services",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowServices",
            component: ShowServices,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "services show",
                subject: "services",
              },
            },
          },
          {
            path: "create",
            name: "CreateServices",
            component: CreateServices,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "services create",
                subject: "services",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditServices",
            component: EditServices,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "services edit",
                subject: "services",
              },
            },
          },
        ],
      },
      // End:: services Routes Config

      // Start:: sub-services Routes Config
      {
        path: "/sub-services",
        name: "SubServicesHome",
        component: SubServicesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllSubServices",
            component: AllSubServices,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "subservices index",
                subject: "subservices",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowSubServices",
            component: ShowSubServices,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "subservices show",
                subject: "subservices",
              },
            },
          },
          {
            path: "create",
            name: "CreateSubServices",
            component: CreateSubServices,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "subservices create",
                subject: "subservices",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditSubServices",
            component: EditSubServices,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "subservices edit",
                subject: "subservices",
              },
            },
          },
        ],
      },
      // End:: sub-services Routes Config

      // Start:: Contact Messages Routes Config
      {
        path: "/contact-messages",
        name: "ContactMessagesHome",
        component: ContactMessagesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllContactMessages",
            component: AllContactMessages,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "contactuses index",
                subject: "contactuses",
              },
            },
          },
        ],
      },
      // End:: Contact Messages Routes Config

      // Start:: App Content Routes Config
      {
        path: "/app-content",
        name: "AppContentHome",
        component: AppContentHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "about-us",
            name: "AboutUs",
            component: AboutUs,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "settings create",
                subject: "settings",
              },
            },
          },
          {
            path: "terms",
            name: "Terms",
            component: Terms,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "settings create",
                subject: "settings",
              },
            },
          },
          {
            path: "policy",
            name: "PrivacyPolicy",
            component: PrivacyPolicy,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "settings create",
                subject: "settings",
              },
            },
          },
          {
            path: "bank-accounts",
            name: "BankAccounts",
            component: BankAccounts,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "settings create",
                subject: "settings",
              },
            },
          },
        ],
      },
      // End:: App Content Routes Config

      // Start:: Pages  Config
      {
        path: "/pages",
        name: "PagesHome",
        component: PagesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllPages",
            component: AllPages,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "pages index",
                subject: "pages",
              },
            },
          },
          {
            path: "create",
            name: "CreatePages",
            component: CreatePages,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "pages create",
                subject: "pages",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditPages",
            component: EditPages,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "pages edit",
                subject: "pages",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowPages",
            component: ShowPages,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "pages show",
                subject: "pages",
              },
            },
          },
        ],
      },
      // End:: Pages Config

      // Start:: App Settings Routes Config
      {
        path: "/settings",
        component: AppSettings,
        meta: {
          middleware: [auth],
          requiresPermission: {
            action: "settings create",
            subject: "settings",
          },
        },
      },
      // End:: App Settings Routes Config

      // Start:: App Settings Routes Config
      {
        path: "/contact_settings",
        component: GeneralSettings,
        meta: {
          middleware: [auth],
          requiresPermission: {
            action: "settings create",
            subject: "settings",
          },
        },
      },
      // End:: App Settings Routes Config

      // Start:: Roles Routes Config
      {
        path: "/roles",
        name: "RolesHome",
        component: RolesHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllRoles",
            component: AllRoles,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "roles index",
                subject: "roles",
              },
            },
          },
          {
            path: "create",
            name: "CreateRole",
            component: CreateRole,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "roles create",
                subject: "roles",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditRole",
            component: EditRole,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "roles edit",
                subject: "roles",
              },
            },
          },
        ],
      },
      // End:: Roles Routes Config

      // Start:: Admins Routes Config
      {
        path: "/admins",
        name: "AdminsHome",
        component: AdminsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllAdmins",
            component: AllAdmins,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "users index",
                subject: "users",
              },
            },
          },
          {
            path: "create",
            name: "CreateAdmin",
            component: CreateAdmin,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "users create",
                subject: "users",
              },
            },
          },
          {
            path: "edit/:id",
            name: "EditAdmin",
            component: EditAdmin,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "users edit",
                subject: "users",
              },
            },
          },
          {
            path: "show/:id",
            name: "ShowAdmin",
            component: ShowAdmin,
            props: true,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "users show",
                subject: "users",
              },
            },
          },
        ],
      },
      // End:: Admins Routes Config

      // Start:: All Notifications Route Config
      {
        path: "/all-notifications",
        name: "NotificationsHome",
        component: NotificationsHome,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "all",
            name: "AllAdmins",
            component: AllNotifications,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "notifications index",
                subject: "notifications",
              },
            },
          },
          {
            path: "create",
            name: "CreateNotificationss",
            component: CreateNotifications,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "notifications create",
                subject: "notifications",
              },
            },
          },
          {
            path: "show",
            name: "ShowNotificationss",
            component: ShowNotifications,
            meta: {
              middleware: [auth],
              requiresPermission: {
                action: "notifications show",
                subject: "notifications",
              },
            },
          },
        ],
      },
      // End:: All Notifications Route Config
    ],
  },
  // ***************** End:: Content Routes Config

  // ***************** Start:: Forbidden Route Config
  {
    path: "/forbidden",
    name: "ForbiddenPage",
    component: ForbiddenPage,
  },
  // ***************** End:: Forbidden Route Config

  // ***************** Start:: Not Found Route Config
  {
    path: "/:notFound(.*)",
    name: "NotFoundPage",
    component: NotFoundPage,
  },
  // ***************** End:: Not Found Route Config
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
  routes,
});

// Start:: Middleware And Router Guards
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

// Authentication and Permission Check Middleware
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem(
    "Fans_admin_dashboard_user_token"
  );

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = { from, next, router, to };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  } else if (isAuthenticated) {
    const publicRoutes = [
      "LoginForm",
      "ResetPasswordEmailForm",
      "PhoneVerification",
      "ChangePhoneNumberForm",
      "ResetPasswordForm",
    ];

    if (publicRoutes.includes(to.name)) {
      return next("/home");
    }
  }

  if (to.meta.requiresPermission) {
    const { action, subject } = to.meta.requiresPermission;
    const storedPermissions = JSON.parse(
      localStorage.getItem("Fans_admin_roles")
    )?.permissions;

    if (
      storedPermissions &&
      storedPermissions.some(
        (permission) =>
          permission.action === action && permission.subject === subject
      )
    ) {
      return next();
    } else {
      return next("/forbidden");
    }
  }
  

  return next();
});

export default router;
