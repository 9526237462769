<template>
  <div class="crud_form_wrapper single_show_content_wrapper">
    <div class="form_title_wrapper">
      <h4>{{ $t("TITLES.showClient", {name: data.name}) }}</h4>
    </div>
    
    <div class="single_step_form_content_wrapper">
      <form>
        <div class="row">
          <div class="row">
            <div>
              <p>
                <span class="font-weight-bold">{{$t('PLACEHOLDERS.login_count')}} : </span>
                <span>{{data.login_number ?? 0}}</span>
              </p>
              <p>
                <span class="font-weight-bold">{{$t('PLACEHOLDERS.last_login_time')}} : </span>
                <span>{{data.last_login ?? '--'}}</span>
              </p>
            </div>
          <table class="table table-striped">
            <tbody>
            <tr>
              <td>{{ $t('PLACEHOLDERS.personalImage') }}</td>
              <td>
                <base-image-upload-input col="12" identifier="admin_image" :preSelectedImage="data.avatar" />
              </td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.provider_type') }}</td>
              <td>{{ data.flag_text }}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.rating_avg') }}</td>
              <td>{{ '--' }}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.name') }}</td>
              <td>{{ data.name }}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.email') }}</td>
              <td>{{ data.email }}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.mobile') }}</td>
              <td>{{ data.mobile }}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.experience_years') }}</td>
              <td>{{ data.experience }}</td>
            </tr>
            
            
            <template v-if="data.flag == 1">
              <tr>
                <td>{{ $t('PLACEHOLDERS.company_name') }}</td>
                <td>{{ data.company_name }}</td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.Commercial_register') }}</td>
                <td>
                  <base-image-upload-input col="12" identifier="commercial" :preSelectedImage="data.commercial" />
                </td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.Company_logo') }}</td>
                <td>
                  <base-image-upload-input col="12" identifier="company_logo" :preSelectedImage="data.company_logo" />
                </td>
              </tr>
            </template>

            <template v-if="data.main_service">
              <tr>
                <td style="font-size: 18px;font-weight: bold">
                  {{ $t('PLACEHOLDERS.main_services') }}
                </td>
              </tr>
              <tr v-for="main in data.main_service">
                <td>{{main.name}}</td>
              </tr>
            </template>

            <template v-if="data.sub_service">
              <tr>
                <td style="font-size: 18px;font-weight: bold">
                  {{ $t('PLACEHOLDERS.sub_services') }}
                </td>
              </tr>
              <tr v-for="main in data.sub_service">
                <td>{{main.name}}</td>
              </tr>
            </template>
            <template v-if="data.works">
              <tr>
                <td style="font-size: 18px;font-weight: bold">
                  {{ $t('PLACEHOLDERS.provider_works') }}
                </td>
              </tr>
              <template v-if="data.works.length">
                <tr v-for="main in data.works">
                  <td>{{main.name}}</td>
                  <td>
                    <img :src="main.file" style="width: 80px;height: 80px">
                  </td>
                </tr>
              </template>
              <template v-else>
                {{ $t('PLACEHOLDERS.no_works') }}
              </template>

            </template>
            <template v-if="data.bank">
              <tr>
                <td style="font-size: 18px;font-weight: bold">
                  {{ $t('PLACEHOLDERS.bank_details') }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.bank_name') }}</td>
                <td>{{ data.bank?.name }}</td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.iban') }}</td>
                <td>{{ data.iban }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShowProviderManagement",

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isWaitingRequest: false,
      addressesTableHeaders: [
        {text: this.$t("TABLES.Addresses.serialNumber")},
        {text: this.$t("TABLES.Addresses.address")},
        {text: this.$t("TABLES.Addresses.longitude")},
        {text: this.$t("TABLES.Addresses.latitude")},
        {text: this.$t("TABLES.Addresses.type")},
        {text: this.$t("TABLES.Addresses.isDefault")},
      ],
      data: {},
    }
  },

  methods: {
    async getDataToShow() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `providers/${this.id}`,
        });
        this.data = res.data.data.provider;
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
  },
  mounted() {

  },
  created() {
    this.getDataToShow();
  },
};
</script>
