<template>
  <div class="crud_form_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("PLACEHOLDERS.show_sub_services") }}</h4>
    </div>
    <!-- End:: Title -->

    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">

          <table class="table table-striped">
            <tbody>
              <tr>
                <td>{{ $t('PLACEHOLDERS.servcie_image') }}</td>
                <td>
                  <base-image-upload-input col="12" identifier="admin_image" :preSelectedImage="data.image.path" />
                </td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.nameEn') }}</td>
                <td>
                  {{data.nameEn}}
                </td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.nameAr') }}</td>
                <td>
                  {{data.nameAr}}
                </td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.main_service') }}</td>
                <td>
                  {{data.parent?.name}}
                </td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.active') }}</td>
                <td>
                  {{data.active ? $t('PLACEHOLDERS.active') : $t('PLACEHOLDERS.notActive')}}
                </td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.createdAt') }}</td>
                <td>
                  {{data.created_at}}
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </form>
    </div>
    <!-- END:: Single Step Form Content -->
  </div>
</template>

<script>

export default {
  name: "showRegion",

  data() {
    return {
      // Start:: Loader Control Data
      isWaitingRequest: false,
      // End:: Loader Control Data

      // Start:: Data Collection To Send
      data: {
        image: {
          path: null,
          file: null,
        },
        nameAr: null,
        nameEn: null,
        country_id: null,
        active: null
      },
      // End:: Data Collection To Send
    };
  },

  computed: {
  },

  methods: {
    // start show data
    async showData() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `services/${this.$route.params.id}`,
        });
        this.data.image.path = res.data.data.Service.image;
        this.data.nameAr = res.data.data.Service.name_ar;
        this.data.nameEn = res.data.data.Service.name_en;
        this.data.parent = res.data.data.Service.parent;
        this.data.active = res.data.data.Service.is_active;
        this.data.created_at = res.data.data.Service.created_at;
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },
    // end show data


  },

  created() {
    // Start:: Fire Methods
    this.showData();
    // End:: Fire Methods
  },
};
</script>
