<template>
  <div class="crud_form_wrapper single_show_content_wrapper">
    <div class="form_title_wrapper">
      <h4>{{ $t("TITLES.showClient", {name: data.name}) }}</h4>
    </div>
    
    <div class="single_step_form_content_wrapper">
      <form>
        <div class="row">
          <table class="table table-striped">
            <tbody>
            <tr>
              <td>{{ $t('PLACEHOLDERS.personalImage') }}</td>
              <td>
                <base-image-upload-input col="12" identifier="admin_image" :preSelectedImage="data.avatar" />
              </td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.provider_type') }}</td>
              <td>{{ data.flag_text }}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.name') }}</td>
              <td>{{ data.name }}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.email') }}</td>
              <td>{{ data.email }}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.mobile') }}</td>
              <td>{{ data.mobile }}</td>
            </tr>            
            <tr>
              <td>{{ $t('PLACEHOLDERS.experience_years') }}</td>
              <td>{{ data.experience }}</td>
            </tr>

            <template v-if="data.main_service">
              <tr>
                <td style="font-size: 18px;font-weight: bold" :colspan="data.main_service.length">
                  {{ $t('PLACEHOLDERS.main_services') }}
                </td>
              </tr>
              <tr v-for="main in data.main_service">
                <td>{{main.name}}</td>
              </tr>
            </template>

            <template v-if="data.sub_service">
              <tr>
                <td style="font-size: 18px;font-weight: bold" :colspan="data.sub_service.length">
                  {{ $t('PLACEHOLDERS.sub_services') }}
                </td>
              </tr>
              <tr v-for="main in data.sub_service">
                <td>{{main.name}}</td>
              </tr>
            </template>            
            <template v-if="data.works">
              <tr>
                <td style="font-size: 18px;font-weight: bold" :colspan="data.works.length">
                  {{ $t('PLACEHOLDERS.provider_works') }}
                </td>
              </tr>
              <tr v-for="main in data.works">
                <td>{{main.name}}</td>
                <td>
                  <img :src="main.file" style="width: 80px;height: 80px">
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyC2MTR_vRbsbQQRPt5f5ZLCvvaKOpzkzlA">
</script>
<script>
export default {
  name: "ShowDriverRequest",

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isWaitingRequest: false,
      data: {},
    }
  },

  methods: {
    async getDataToShow() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `providers-requests/${this.id}`,
        });
        this.data = res.data.data.provider_request;
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
  },
  mounted() {

  },
  created() {
    this.getDataToShow();
  },
};
</script>
