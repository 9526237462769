<template>
  <div class="crud_form_wrapper single_show_content_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("TITLES.showClient", {name: data.name}) }}</h4>
    </div>
    <!-- End:: Title -->


    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <!-- ==== Start:: Status Badges ==== -->
      <div class="badges_wrapper justify-content-between">
        <div class="wrapper">
          <v-chip color="amber darken-2" text-color="white">
            {{ $t("TITLES.numberOfVisits", {number: data.numberOfVisits}) }}
          </v-chip>
          <v-chip color="amber darken-2" text-color="white">
            {{ $t("TITLES.lastVisit", {date: data.lastVisit}) }}
          </v-chip>
        </div>

        <v-chip :color="data.active ? 'green' : 'red'" text-color="white">
          {{ data.active ? $t("STATUS.active") : $t("STATUS.notActive") }}
        </v-chip>
      </div>
      <div class="badges_wrapper justify-content-between">
        <div class="wrapper">
          <h3>{{$t('TITLES.interests')}}</h3>
          <v-chip color="amber darken-2" text-color="white" v-for="interest in data.interests">
            {{ interest.name }}
          </v-chip>
        </div>
      </div>
      <div class="badges_wrapper justify-content-between">
        <div class="wrapper">
          <h3>{{$t('TITLES.statistic')}}</h3>
          <v-chip color="amber darken-2" text-color="white">
            {{ $t("TITLES.followers_count") +':' + data.followers_count }} 
          </v-chip>
          <v-chip color="amber darken-2" text-color="white">
            {{ $t("TITLES.following_count") +':' +  data.following_count }}
          </v-chip>
          <v-chip color="amber darken-2" text-color="white">
            {{ $t("TITLES.saved_posts_count") +':' +  data.saved_posts_count }}
          </v-chip>
          <v-chip color="amber darken-2" text-color="white">
            {{ $t("TITLES.normal_posts_count") +':' +  data.normal_posts_count }}
          </v-chip>
          <v-chip color="amber darken-2" text-color="white">
            {{ $t("TITLES.live_posts_count") +':' +  data.live_posts_count }}
          </v-chip>
          <v-chip color="amber darken-2" text-color="white">
            {{ $t("TITLES.stories_count") +':' +  data.stories_count }}
          </v-chip>
        </div>
      </div>
      <!-- ==== End:: Status Badges ==== -->

      <!-- ==== Start:: Client Main Data ==== -->
      <form>
        <div class="row">
          <!-- Start:: Image Upload Input -->
          <base-image-upload-input col="12" identifier="client_image" :placeholder="$t('PLACEHOLDERS.personalImage')"
                                   :preSelectedImage="data.image" :documented="data.is_documented" disabled class="disabled_input"/>
          <!-- End:: Image Upload Input -->

          <!-- Start:: Ar Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.name')" v-model.trim="data.name" disabled
                      class="disabled_input"/>
          <!-- End:: Ar Name Input -->

          <!-- Start:: Phone Input -->
          <base-input col="6" type="tel" :placeholder="$t('PLACEHOLDERS.phone')" v-model.trim="data.phone" readonly
                      class="disabled_input"/>
          <!-- End:: Phone Input -->

          <!-- <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.registration_otp_status')"
            v-model.trim="data.registration_otp_status" disabled class="disabled_input" /> -->

          <!-- Start:: Email Input -->
          <base-input col="6" type="email" :placeholder="$t('PLACEHOLDERS.email')" v-model.trim="data.email" readonly
                      class="disabled_input"/>
          <!-- End:: Email Input -->
          <!-- Start:: Email Input -->
          <base-input col="6" type="text" :placeholder="$t('TABLES.Clients.username')" v-model.trim="data.username" readonly
                      class="disabled_input"/>
          
          <base-input col="6" type="text" :placeholder="$t('TABLES.Clients.account_type')" v-model.trim="data.account_type" readonly
                      class="disabled_input"/>

          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.joiningDate')" v-model.trim="data.joiningDate"
                      readonly class="disabled_input"/>
          
          <base-input col="12" type="text" :placeholder="$t('PLACEHOLDERS.bio')" v-model.trim="data.bio"
                      readonly class="disabled_input"/>
          <!-- End:: Email Input -->

          <!-- Start:: Joining Date Input -->

        </div>
      </form>
      
    </div>
    <!-- End:: Single Step Form Content -->
  </div>
</template>

<script>
export default {
  name: "SingleClient",

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      // Start:: Loading Data
      isWaitingRequest: false,
      // End:: Loading Data

      // Start:: Data
      data: {
        image: null,
        name: null,
        phone: null,
        username: null,
        registration_otp_status: null,
        email: null,
        joiningDate: null,
        active: false,
        gender: null,
        id_number: null,
        is_documented: false,
        interests: [],
        account_type: null,
        bio: null,
        followers_count: null,
        following_count: null,
        saved_posts_count: null,
        normal_posts_count: null,
        live_posts_count: null,
        stories_count: null,
        auto_blocked_posts_count: null,
      },
    };
  },

  methods: {
    async getDataToShow() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `users/${this.id}`,
        });
        this.data.image = res.data.data.User.image;
        this.data.name = res.data.data.User.name;
        this.data.phone = res.data.data.User.mobile;
        this.data.username = res.data.data.User.username;
        this.data.bio = res.data.data.User.bio;
        this.data.account_type = res.data.data.User.account_type;
        this.data.registration_otp_status = res.data.data.User.is_verified;
        this.data.email = res.data.data.User.email;
        this.data.is_documented = res.data.data.User.is_documented;
        this.data.joiningDate = res.data.data.User.created_at;
        this.data.numberOfVisits = res.data.data.User.login_numbers;
        this.data.lastVisit = res.data.data.User.last_login_date;
        this.data.active = res.data.data.User.is_active;
        this.data.interests = res.data.data.User.interests;
        this.data.followers_count = res.data.data.User.statistic?.followers_count;
        this.data.following_count = res.data.data.User.statistic?.following_count;
        this.data.saved_posts_count = res.data.data.User.statistic?.saved_posts_count;
        this.data.normal_posts_count = res.data.data.User.statistic?.normal_posts_count;
        this.data.live_posts_count = res.data.data.User.statistic?.live_posts_count;
        this.data.stories_count = res.data.data.User.statistic?.stories_count;
        this.data.auto_blocked_posts_count = res.data.data.User.statistic?.auto_blocked_posts_count;
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
  },

  created() {
    this.getDataToShow();
  },
};
</script>
