<template>
  <div class="crud_form_wrapper single_show_content_wrapper">
    <!-- Start:: Title -->
    <!--<div class="form_title_wrapper">-->
      <!--<h4>{{ $t("TITLES.showClient", {name: data.title}) }}</h4>-->
    <!--</div>-->
    <!-- End:: Title -->


    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">

      <!-- ==== Start:: Client Main Data ==== -->
      <form>
        <div class="row">
          <!-- Start:: Image Upload Input -->
          <div v-if="data.type=='image'" class="single_image_input_wrapper" @click="showMediaModal(data.media,data.type)">
            <div class="wrapper">
              <label ></label>
              <img class="image_uploaded" :src="data.media" alt="Image To Upload" width="100" height="100" 
              />
            </div>
          </div>
          
          <!--<base-image-upload-input col="12" v-if="data.type=='image'" identifier="media" :placeholder="$t('TABLES.Posts.media')" :preSelectedImage="data.media" disabled @click="showMediaModal(data.media,data.type)" class="disabled_input"/>-->

          <video controls v-if="data.type=='video'" height="250">
            <source :src="data.media" type="video/mp4">
            <source :src="data.media" type="video/webm">
            <source :src="data.media" type="video/ogg">
            Your browser does not support the video tag.
          </video>
          <!-- End:: Image Upload Input -->

          <!-- Start:: Ar Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.title')" v-model.trim="data.title" disabled
                      class="disabled_input"/>
          <!-- End:: Ar Name Input -->

          <!-- Start:: Ar Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.date')" v-model.trim="data.created_at" disabled
                      class="disabled_input"/>
          <!-- End:: Ar Name Input -->

          <div class="badges_wrapper justify-content-between">
            <div class="wrapper">
              <h3>{{$t('TABLES.Posts.mentions')}}</h3>
              <v-chip color="amber darken-2" text-color="white" v-for="item in data.mentions">
                {{ item.name }}
              </v-chip>
            </div>
          </div>
          
          <div class="badges_wrapper justify-content-between">
            <div class="wrapper">
              <h3>{{$t('TABLES.Posts.views_number')}}</h3>
              <v-chip color="amber darken-2" text-color="white">
                {{ data.views }}
              </v-chip>
            </div>
          </div>
          <div class="badges_wrapper justify-content-between">
            <div class="wrapper">
              <h3>{{$t('TABLES.Posts.likes_number')}}</h3>
              <v-chip color="amber darken-2" text-color="white">
                {{ data.likes }}
              </v-chip>
            </div>
          </div>
          <!-- <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.registration_otp_status')"
            v-model.trim="data.registration_otp_status" disabled class="disabled_input" /> -->

          <!-- Start:: Email Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.status')" v-model.trim="data.status" readonly
                      class="disabled_input"/>
          
          <base-input col="6" type="text" v-if="data.status!='published'" :placeholder="$t('PLACEHOLDERS.reason')" v-model.trim="data.reason" readonly
                      class="disabled_input"/>
          <!-- End:: Email Input -->
          <!-- Start:: Email Input -->
          <base-input col="6" type="text" :placeholder="$t('TABLES.Clients.name')" v-model.trim="data.user.name" readonly
                      class="disabled_input"/>
          
          <base-input col="6" type="text" :placeholder="$t('TABLES.Clients.username')" v-model.trim="data.user.username" readonly
                      class="disabled_input"/>
          
          <base-input col="6" type="text" :placeholder="$t('TABLES.Clients.phone')" v-model.trim="data.user.mobile" readonly
                      class="disabled_input"/>

          <!--<div class="badges_wrapper justify-content-between">-->
            <!--<div class="wrapper">-->
              <!--<h3>{{$t('TABLES.Posts.comments')}}</h3>-->
              <!--<div  v-for="comment in data.comments" style="display: block">-->
              <!--<v-chip class="ma-2" color="info" text-color="white" label>-->
                <!--<i class="fa fa-user"></i>-->
                <!--{{comment.user.name}}-->
              <!--</v-chip>-->
              <!--<v-chip color="amber darken-2" text-color="white" v-if="comment.type=='text'">-->
                <!--{{ comment.comment }}-->
              <!--</v-chip>-->
                <!--<v-chip color="amber darken-2" text-color="white" v-if="comment.type=='image'">-->
                <!--<img :src="comment.media" width="100" height="100">-->
              <!--</v-chip>-->
                <!--<v-chip color="amber darken-2" text-color="white" v-if="comment.type=='audio'">-->
                  <!--<audio controls>-->
                    <!--<source :src="comment.media" type="audio/mpeg">-->
                    <!--<source :src="comment.media" type="audio/ogg">-->
                    <!--Your browser does not support the audio element.-->
                  <!--</audio>-->
              <!--</v-chip>-->
                <!--<v-chip class="ma-2" color="pink" text-color="white" label>-->
                  <!--<i class="fa fa-heart"></i>-->
                <!--{{comment.likes}}-->
                <!--</v-chip>-->
                <!--<br>-->
              <!--</div>-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
          <!--</div>-->
          <image-modal v-if="dialogMedia" :modalIsOpen="dialogMedia" :modalImage="mediaSrc" @toggleModal="dialogMedia = !dialogMedia" />

          <video-modal v-if="dialogVideo" :modalIsOpen="dialogVideo" :modalVideo="mediaSrc" @toggleModal="dialogVideo = !dialogVideo" />
          <!--<div v-if="data.comments" v-for="comment in data.comments">-->
            <!--<p>{{comment.comment}}</p>-->
          <!--</div>-->
          <!--<div>-->
            <!--<p>{{comment.user.name}} <span>&bull;</span>{{ comment.created_at}}</p>-->
          <!--</div>-->
          <!-- End:: Email Input -->

          <!-- Start:: Joining Date Input -->

        </div>
      </form>
      
    </div>
    <!-- End:: Single Step Form Content -->
  </div>
</template>

<script>
export default {
  name: "SingleStory",

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialogMedia:false,
      dialogVideo:false,
      mediaSrc:'',
      mediaType:'',
      // Start:: Loading Data
      isWaitingRequest: false,
      // End:: Loading Data

      // Start:: Data
      data: {
        // image: null,
        // name: null,
        // phone: null,
        // username: null,
        // registration_otp_status: null,
        // email: null,
        // joiningDate: null,
        // active: false,
        // gender: null,
        // id_number: null,
        // is_documented: false,
        // interests: [],
        // account_type: null,
        // bio: null,
        // followers_count: null,
        // following_count: null,
        // saved_posts_count: null,
        // normal_posts_count: null,
        // live_posts_count: null,
        // stories_count: null,
        // auto_blocked_posts_count: null,
      },
    };
  },

  methods: {
    async getDataToShow() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `stories/${this.id}`,
        });
        this.data = res.data.data.Story;
        // this.data.image = res.data.data.User.image;
        // this.data.name = res.data.data.User.name;
        // this.data.phone = res.data.data.User.mobile;
        // this.data.username = res.data.data.User.username;
        // this.data.bio = res.data.data.User.bio;
        // this.data.account_type = res.data.data.User.account_type;
        // this.data.registration_otp_status = res.data.data.User.is_verified;
        // this.data.email = res.data.data.User.email;
        // this.data.is_documented = res.data.data.User.is_documented;
        // this.data.joiningDate = res.data.data.User.created_at;
        // this.data.numberOfVisits = res.data.data.User.login_numbers;
        // this.data.lastVisit = res.data.data.User.last_login_date;
        // this.data.active = res.data.data.User.is_active;
        // this.data.interests = res.data.data.User.interests;
        // this.data.followers_count = res.data.data.User.statistic?.followers_count;
        // this.data.following_count = res.data.data.User.statistic?.following_count;
        // this.data.saved_posts_count = res.data.data.User.statistic?.saved_posts_count;
        // this.data.normal_posts_count = res.data.data.User.statistic?.normal_posts_count;
        // this.data.live_posts_count = res.data.data.User.statistic?.live_posts_count;
        // this.data.stories_count = res.data.data.User.statistic?.stories_count;
        // this.data.auto_blocked_posts_count = res.data.data.User.statistic?.auto_blocked_posts_count;
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
    showMediaModal(media,type){
      if(type=='image'){
        this.dialogMedia = true;
      }else{
        this.dialogVideo = true;
      }
      this.mediaType = type;
      this.mediaSrc = media;
    },
      },

  created() {
    this.getDataToShow();
  },
};
</script>
