<template>
  <div class="crud_form_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("TITLES.showAdmin") }}</h4>
    </div>
    <!-- End:: Title -->

    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">
          <div>
            <p>
              <span class="font-weight-bold">{{$t('PLACEHOLDERS.login_count')}} : </span>
              <span>{{data.login_number ?? 0}}</span>
            </p>
            <p>
              <span class="font-weight-bold">{{$t('PLACEHOLDERS.last_login_time')}} : </span>
              <span>{{data.last_login ?? '--'}}</span>
            </p>
            
            <p>
              <span class="font-weight-bold">{{$t('PLACEHOLDERS.role')}} : </span>
              <span v-for="(item,key) in data.role">{{key==0?item.name:' ,'+item.name }}</span>
            </p>

            <!--<v-chip :color="data.active ? 'green' : 'red'" text-color="white">-->
              <!--{{ data.active ? $t("STATUS.active") : $t("STATUS.notActive") }}-->
            <!--</v-chip>-->
          </div>
          <!-- Start:: Image Upload Input -->
          <base-image-upload-input col="12" identifier="admin_image" :preSelectedImage="data.image.path"
            :placeholder="$t('PLACEHOLDERS.personalImage')" disabled />
          <!-- End:: Image Upload Input -->

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.name')" v-model.trim="data.name" disabled />
          <!-- End:: Name Input -->

          <!-- Start:: Email Input -->
          <base-input col="6" type="email" :placeholder="$t('PLACEHOLDERS.email')" v-model.trim="data.email" disabled />
          <!-- End:: Email Input -->

          <!-- Start:: Phone Input -->
          <base-input col="6" type="tel" :placeholder="$t('PLACEHOLDERS.phone')" v-model.trim="data.phone" disabled />
          <!-- End:: Phone Input -->

          <div class="input_wrapper switch_wrapper my-5">
          <v-switch color="green" :label="data.active ? $t('PLACEHOLDERS.active') : $t('PLACEHOLDERS.notActive')"
          v-model="data.active" disabled hide-details></v-switch>
          </div>
          <!-- Start:: Roles Input -->
          <!--<base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.role')" v-model.trim="data.role.name" disabled />-->
          <!-- End:: Roles Input -->

        </div>
      </form>
    </div>
    <!-- END:: Single Step Form Content -->
  </div>
</template>

<script>

export default {
  name: "ShowAdmin",

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      // Start:: Loader Control Data
      isWaitingRequest: false,
      // End:: Loader Control Data

      // Start:: Data Collection To Send
      data: {
        image: {
          path: null,
          file: null,
        },
        name: null,
        email: null,
        phone: null,
        role: null,
        enableEditPassword: false,
        password: null,
        passwordConfirmation: null,
        active: true,
        last_login: '',
        login_number: 0,
      },
      // End:: Data Collection To Send
    };
  },

  computed: {
  },

  methods: {

    // Start:: Get Data To Edit
    async getDataToEdit() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `admins/${this.id}`,
        });
        // console.log( "DATA TO EDIT =>", res.data.data.region );
        // Start:: Set Data
        this.data.image.path = res.data.data.Admin.avatar;
        this.data.name = res.data.data.Admin.name;
        this.data.email = res.data.data.Admin.email;
        this.data.phone = res.data.data.Admin.mobile;
        this.data.role = res.data.data.Admin.role_obj;
        this.data.active = res.data.data.Admin.is_active;
        this.data.last_login = res.data.data.Admin.last_login_date;
        this.data.login_number = res.data.data.Admin.login_numbers;
        // End:: Set Data
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
    // End:: Get Data To Edit

  },

  async created() {
    // Start:: Fire Methods
    this.getDataToEdit();
    // End:: Fire Methods
  },
};
</script>
