<template>
  <div class="show_all_content_wrapper">
    <!-- Start:: Main Section -->
    <main>
      <!--  =========== Start:: Filter Form =========== -->
      <div class="filter_content_wrapper" :class="{ active: filterFormIsActive }">
        <button class="filter_toggler" @click="filterFormIsActive = !filterFormIsActive">
          <i class="fal fa-times"></i>
        </button>
        <div class="filter_title_wrapper">
          <h5>{{ $t("TITLES.searchBy") }}</h5>
        </div>
        <div class="filter_form_wrapper">
          <form @submit.prevent="submitFilterForm">
            <div class="row justify-content-center align-items-center w-100">
              <base-input col="3" type="text" :placeholder="$t('PLACEHOLDERS.name')" v-model.trim="filterOptions.name" />
              
              <base-input col="3" type="text" :placeholder="$t('TABLES.Chat.admin')" v-model.trim="filterOptions.owner" />
              
              <base-select-input col="3" :optionsList="RoomType" :placeholder="$t('PLACEHOLDERS.type')"
                v-model="filterOptions.type" />
            </div>
            
            <div class="btns_wrapper">

              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>{{ $t("BUTTONS.search") }}</span>
                </template>
                <span class="submit_btn" @click="submitFilterForm" :disabled="isWaitingRequest">
                  <i class="fal fa-search"></i>
                </span>
              </a-tooltip>

              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>{{ $t("BUTTONS.rseet_search") }}</span>
                </template>
                <span class="reset_btn" :disabled="isWaitingRequest" @click="resetFilter">
                  <i class="fal fa-redo"></i>
                </span>
              </a-tooltip>

            </div>
          </form>
        </div>
      </div>
      <!--  =========== End:: Filter Form =========== -->

      <!--  =========== Start:: Table Title =========== -->
      <div class="table_title_wrapper">
        <div class="title_text_wrapper">
          <h5>{{ $t("TABLES.Chat.Rooms") }}</h5>
          <button v-if="!filterFormIsActive" class="filter_toggler"
            @click.stop="filterFormIsActive = !filterFormIsActive">
            <i class="fal fa-search"></i>
          </button>
        </div>
      </div>
      <!--  =========== End:: Table Title =========== -->

      <!--  =========== Start:: Data Table =========== -->
      <v-data-table class="thumb" :loading="loading" :loading-text="$t('TABLES.loadingData')" :search="searchValue"
        :headers="tableHeaders" :items="tableRows" item-class="ltr" :items-per-page="paginations.items_per_page"
        hide-default-footer>
        <!-- Start:: No Data State -->
        <template v-slot:no-data>
          {{ $t("TABLES.noData") }}
        </template>
        <!-- Start:: No Data State -->

        <template v-slot:[`item.id`]="{ item, index }">
          <div class="table_image_wrapper">
            <h6 class="text-danger" v-if="!item.id"> {{ $t("TABLES.noData") }} </h6>
            <p v-else>{{ (paginations.current_page - 1) * paginations.items_per_page + index + 1 }}</p>
          </div>
        </template>

        <!-- Start:: Name -->
        <template v-slot:[`item.owner.name`]="{ item }">
          <h6 class="text-danger" v-if="!item.owner"> {{ $t("TABLES.noData") }} </h6>
          <h6 v-else> {{ item.owner.name }}
          <i v-if="item.owner && item.owner.is_documented" class="fa fa-certificate" style="color: blue"></i>
          </h6>
        </template>
        <!-- End:: Name -->

        <!-- Start:: Actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <a-tooltip placement="bottom" v-if="hasPermission('chats-show')">
              <template slot="title">
                <span>{{ $t("BUTTONS.show") }}</span>
              </template>
              <button class="btn_show" @click="showItem(item)">
                <i class="fal fa-eye"></i>
              </button>
            </a-tooltip>

          </div>
        </template>
        <!-- End:: Actions -->

        <!-- ======================== Start:: Dialogs ======================== -->
        <!-- ======================== End:: Dialogs ======================== -->

      </v-data-table>
      <!--  =========== End:: Data Table =========== -->
    </main>
    <!-- End:: Main Section -->

    <!-- Start:: Pagination -->
    <template>
      <div class="pagination_container text-center mt-3 mb-0">
        <v-pagination class="py-0" square v-model="paginations.current_page" :length="paginations.last_page"
          :total-visible="6" @input="updateRouterQueryParam($event)" :prev-icon="getAppLocale == 'ar' ? 'fal fa-angle-right' : 'fal fa-angle-left'
            " :next-icon="getAppLocale == 'ar' ? 'fal fa-angle-left' : 'fal fa-angle-right'
    " />
      </div>
    </template>
    <!-- End:: Pagination -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AllChats",

  computed: {
    ...mapGetters({
      getAppLocale: "AppLangModule/getAppLocale",
    }),

  RoomType() {
      return [
        {
          id: 1,
          name: this.$t("STATUS.private"),
          value: 'private',
        },
        {
          id: 2,
          name: this.$t("STATUS.public"),
          value: 'public',
        },
        {
          id: 3,
          name: this.$t("STATUS.all"),
          value: '',
        },
      ];
    },
  },

  data() {
    return {
      // Start:: Loading Data
      loading: false,
      promoted: false,
      isWaitingRequest: false,
      // End:: Loading Data

      // Start:: Filter Data
      filterFormIsActive: false,
      filterOptions: {
        name: null,
        owner: null,
        type: null,
      },
      // End:: Filter Data

      // Start:: Table Data
      searchValue: "",
      tableHeaders: [
        {
          text: this.$t("TABLES.Chat.serialNumber"),
          value: "id",
          align: "center",
          width: "80",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Chat.name"),
          value: "name",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Chat.admin"),
          value: "owner.name",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Chat.type"),
          value: "type_trans",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Chat.date"),
          value: "created_at",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Clients.actions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      tableRows: [],
      // End:: Table Data

      // Start:: Pagination Data
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 6,
      },
      // End:: Pagination Data

      dialogDeactivate: false,
      itemToChangeActivationStatus: null,
      deactivateReason: null,

      dialogBalance: false,
      itemToBalance: null,

    };
  },

  watch: {
    // Start:: Page Query Param Watcher To Get Page Data Based On It's Change
    ["$route.query.page"]() {
      this.paginations.current_page = +this.$route.query.page;
      this.setTableRows();
    },
    // End:: Page Query Param Watcher To Get Page Data Based On It's Change
  },

  methods: {
    
    // Start:: Handel Filter
    async submitFilterForm() {
      if (this.$route.query.page !== '1') {
        await this.$router.push({ path: '/chats/all', query: { page: 1 } });
      }
      this.setTableRows();
    },
    async resetFilter() {
      this.filterOptions.name = null;
      this.filterOptions.owner = null;
      this.filterOptions.type = null;
      if (this.$route.query.page !== '1') {
        await this.$router.push({ path: '/chats/all', query: { page: 1 } });
      }
      this.setTableRows();
    },
    // End:: Handel Filter

    // Start:: Set Table Rows
    updateRouterQueryParam(pagenationValue) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: pagenationValue,
        },
      });

      // Scroll To Screen's Top After Get Products
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    async setTableRows() {
      this.loading = true;
      try {

        // let type = this.filterOptions.type;
        // if (!type) {
        //   type = null;
        // }
        
        let res = await this.$axios({
          method: "GET",
          url: "chats",
          params: {
            page: this.paginations.current_page,
            name: this.filterOptions.name,
            type: this.filterOptions.type?.value,
            owner: this.filterOptions.owner,
          },
        });
        this.loading = false;
        // console.log("All Data ==>", res.data.data);
        this.tableRows = res.data.data;
        this.paginations.last_page = res.data.meta.last_page;
        this.paginations.items_per_page = res.data.meta.per_page;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    // async changeActivationStatus(item) {
    //   try {
    //     await this.$axios({
    //       method: "POST",
    //       url: `chats/activate/${item.user.id}`,
    //     });
    //     this.setTableRows();
    //     this.$message.success(this.$t("MESSAGES.changeActivation"));
    //   } catch (error) {
    //     this.$message.error(error.response.data.message);
    //   }
    // },

    showItem(item) {
      this.$router.push({ path: `/chats/show/${item.id}` });
    },
    // selectDeactivateItem(item) {
    //   this.dialogDeactivate = true;
    //   this.itemToChangeActivationStatus = item;
    // },
    // async HandlingItemActivationStatus(selectedItem) {
    //   this.dialogDeactivate = false;
    //   let targetItem = this.itemToChangeActivationStatus ? this.itemToChangeActivationStatus : selectedItem;
    //   const REQUEST_DATA = {};
    //   REQUEST_DATA.status = targetItem.status=='published'?'blocked':'published';
    //   if(targetItem.status=='published'){
    //   REQUEST_DATA.reason = this.deactivateReason;
    // }
    //   try {
    //     await this.$axios({
    //       method: "PUT",
    //       url: `chats/${targetItem.id}`,
    //       data: REQUEST_DATA,
    //     });
    //     this.$message.success(this.$t("MESSAGES.changeActivation"));
    //     this.setTableRows();
    //     this.itemToChangeActivationStatus = null;
    //     this.deactivateReason = null;
    //   } catch (error) {
    //     this.$message.error(error.response.data.message);
    //   }
    // },
    // ===== End:: Handling Activation & Deactivation
    
    // ==================== End:: Crud ====================
  },

  created() {
    // Start:: Fire Methods
    window.addEventListener("click", () => {
      this.filterFormIsActive = false;
    });
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setTableRows();
    // End:: Fire Methods
  },
};
</script>
<style>
span.submit_btn {
  width: 45px;
  height: 45px;
  font-size: 16px;
  border-radius: 10px;
  color: var(--white_clr);
  transition: all 0.3s linear;
  background-color: #9B6300;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>