<template>
  <div class="crud_form_wrapper">
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">

          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.value_added')"
                      v-model.trim="data.tax"/>            
          
          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.min_age')"
                      v-model.trim="data.min_age"/>            
          
          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.subscribe_reminder')"
                      v-model.trim="data.subscribe_reminder"/>           
          
          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.max_live_together')"
                      v-model.trim="data.max_live_together"/>          
          
          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.promotion_price')"
                      v-model.trim="data.promotion_price"/>             
          
          <base-input col="6" type="number" :placeholder="$t('PLACEHOLDERS.items_before_promotion')"
                      v-model.trim="data.items_before_promotion"/>

          <!--<base-image-upload-input col="12" identifier="admin_image" :preSelectedImage="data.video.path"-->
                                   <!--:placeholder="$t('PLACEHOLDERS.app_video')" @selectImage="selectImage" />-->


          <div class="btn_wrapper">
            <base-button class="mt-2" styleType="primary_btn" :btnText="$t('BUTTONS.save')"
                         :isLoading="isWaitingRequest"
                         :disabled="isWaitingRequest"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {
  name: "GeneralSettings",

  data() {
    return {
      // Start:: Loader Control Data
      isWaitingRequest: false,
      // End:: Loader Control Data

      // Start:: Data
      data: {
        video: {
          path: null,
          file: null,
        }
        },
      // End:: Data

    };
  },

  computed: {
    // Start:: Vuex Getters
    ...mapGetters({
      getAuthenticatedUserData: "AuthenticationModule/getAuthenticatedUserData"
    }),
    // End:: Vuex Getters
  },


  methods: {
    selectImage(selectedImage) {
      this.data.video = selectedImage;
    },
    // Start:: Get Data To Edit
    async getDataToEdit() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `settings?key=general`,
        });
        this.data = res.data.data[0].value;
        // this.data.video.path = res.data.data[0].value.video;

      } catch (error) {
        console.log(error.response.data.message);
      }
    },
    // End:: Get Data To Edit

    // Start:: Submit Form
    async submitForm() {
      this.isWaitingRequest = !this.isWaitingRequest;

      const REQUEST_DATA = new FormData();
      REQUEST_DATA.append("key", "general");
      
      REQUEST_DATA.append("value[tax]", this.data.tax);
      REQUEST_DATA.append("value[min_age]", this.data.min_age);
      REQUEST_DATA.append("value[subscribe_reminder]", this.data.subscribe_reminder);
      REQUEST_DATA.append("value[max_live_together]", this.data.max_live_together);
      REQUEST_DATA.append("value[promotion_price]", this.data.promotion_price);
      REQUEST_DATA.append("value[items_before_promotion]", this.data.items_before_promotion);
      // if (this.data.video.file) {
      //   REQUEST_DATA.append("value[video]", this.data.video.file);
      // }

      try {
        await this.$axios({
          method: "POST",
          url: `settings`,
          data: REQUEST_DATA,
        });
        this.isWaitingRequest = false;
        this.$message.success(this.$t("MESSAGES.savedSuccessfully"));
        this.getDataToEdit();
      } catch (error) {
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.errors);
      }
    },
    // End:: Submit Form

    // Start:: validate Form Inputs
    validateFormInputs() {
      this.isWaitingRequest = true;
      this.submitForm();
      return;
      if (!this.data.min_age || this.data.min_age == 'null') {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.min_age"));
        return;
      } else if (!this.data.max_live_together || this.data.max_live_together == 'null') {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.max_live_together"));
        return;
      }else if (!this.data.subscribe_reminder || this.data.subscribe_reminder == 'null') {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.subscribe_reminder"));
        return;
      } else {
        this.submitForm();
        return;
      }
    },
    // End:: validate Form Inputs
  },

  created() {
    // Start:: Fire Methods
    this.getDataToEdit();
    // End:: Fire Methods
  },
};
</script>


<style lang="scss" scoped>
.all_action {
  display: flex;
  gap: 15px
}

.add_another {
  border: none;
  padding: 8px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--light_gray_clr);
  border-radius: 50%;
  font-size: 18px;
  color: var(--light_gray_clr);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: auto;

  .fa-trash {
    color: #ff2159;
    cursor: pointer
  }
}
</style>