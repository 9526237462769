<template>
  <div class="crud_form_wrapper single_show_content_wrapper">
    <div class="form_title_wrapper">
      <h4>{{ $t("TITLES.showClient", {name: data.serial_number}) }}</h4>
    </div>
    
    <div class="single_step_form_content_wrapper">
      <form>
        <div class="row">
          <table class="table table-striped">
            <tbody>
            <tr>
              <td style="font-size: 18px;font-weight: bold" colspan="2">
                {{ $t('PLACEHOLDERS.request_details') }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.orderNumber') }}</td>
              <td>{{data.serial_number}}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.request_date') }}</td>
              <td>{{data.created_at}}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.request_status') }}</td>
              <td>{{data.status_text}}</td>
            </tr>
            <tr>
              <td style="font-size: 18px;font-weight: bold" colspan="2">
                {{ $t('PLACEHOLDERS.service_details') }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.description') }}</td>
              <td>{{data.description}}</td>
            </tr>
            <tr>
              <td style="font-size: 18px;font-weight: bold" colspan="2">
                {{ $t('PLACEHOLDERS.client_details') }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.name') }}</td>
              <td>{{data.client?.name}}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.mobile') }}</td>
              <td>{{data.client?.mobile}}</td>
            </tr>
            <tr>
              <td style="font-size: 18px;font-weight: bold" colspan="2">
                {{ $t('PLACEHOLDERS.provider_details') }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.name') }}</td>
              <td>{{data.provider?.name}}</td>
            </tr>
            <tr>
              <td>{{ $t('PLACEHOLDERS.mobile') }}</td>
              <td>{{data.provider?.mobile}}</td>
            </tr>
            <template v-if="data.sub_total">
              <tr>
                <td style="font-size: 18px;font-weight: bold" colspan="2">
                  {{ $t('PLACEHOLDERS.agreement_price') }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.agreement_price') }}</td>
                <td>{{data.sub_total}} {{ $t("PLACEHOLDERS.sar") }}</td>
              </tr>              
              <tr>
                <td>{{ $t('PLACEHOLDERS.The service provider approval of the terms of the attached contract') }}</td>
                <td><input style="scale: 1.5;" type="checkbox" disabled :checked="data.is_agree_contract"></td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.The agreed upon contract is attached') }}</td>
                <td>
                  <img :src="data.contract_file" v-if="!this.checkFileType(data.contract_file)" style="width: 80px;height: 80px;" />
                </td>
              </tr>
            </template>

            <template v-if="data.status == 'agreement_accepted' || data.status == 'completed' || data.status == 'completion_confirmed' ">
              <tr>
                <td style="font-size: 18px;font-weight: bold" colspan="2">
                  {{ $t('PLACEHOLDERS.Price and payment information') }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.The agreement amount is without tax') }}</td>
                <td>{{data.sub_total}} {{ $t("PLACEHOLDERS.sar") }}</td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.tax') }}</td>
                <td>{{data.tax}} {{ $t("PLACEHOLDERS.sar") }}</td>
              </tr>              
              <tr>
                <td>{{ $t('PLACEHOLDERS.Order price with tax') }}</td>
                <td>{{data.sub_total + data.tax}} {{ $t("PLACEHOLDERS.sar") }}</td>
              </tr>              
              <tr>
                <td>{{ $t('PLACEHOLDERS.Application commission') }}</td>
                <td>{{data.commission_value}} {{ $t("PLACEHOLDERS.sar") }}</td>
              </tr>              
              <tr>
                <td>{{ $t("PLACEHOLDERS.The service provider net remuneration minus the commission") }}</td>
                <td>{{data.sub_total + data.tax - data.commission_value}} {{ $t("PLACEHOLDERS.sar") }}</td>
              </tr>
            </template>

            <template v-if="data.status == 'rejected' || data.status == 'canceled' ">
              <tr>
                <td style="font-size: 18px;font-weight: bold" colspan="2">
                  {{ $t('PLACEHOLDERS.Reason for cancellation/rejection') }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('PLACEHOLDERS.Reason for cancellation/rejection') }}</td>
                <td>{{data.status == 'rejected' ? data.reject_reason : data.cancel_reason}}</td>
              </tr>              
              <tr>
                <td>{{ $t('PLACEHOLDERS.Canceled/rejected by') }}</td>
                <td>{{data.status == 'rejected' ? data.reject_by : data.cancel_by}}</td>
              </tr>              
              <tr>
                <td>{{ $t('PLACEHOLDERS.Date of cancellation/rejection') }}</td>
                <td>{{data.status == 'rejected' ? data.reject_date : data.cancel_date}}</td>
              </tr>
            </template>
            
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShowDriverManagement",

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      routeCoordinates : [],
      polylineOptions: {
        strokeColor: 'red',
        strokeOpacity: 0.8,
        strokeWeight: 6,
      },
      tripRating:5,
      isWaitingRequest: false,
      addressesTableHeaders: [
        {text: this.$t("TABLES.Addresses.serialNumber")},
        {text: this.$t("TABLES.Addresses.address")},
        {text: this.$t("TABLES.Addresses.longitude")},
        {text: this.$t("TABLES.Addresses.latitude")},
        {text: this.$t("TABLES.Addresses.type")},
        {text: this.$t("TABLES.Addresses.isDefault")},
      ],
      data: {},
    }
  },
  methods: {
    checkFileType($path) {
      const extension = this.getFileExtension($path);
      if (extension.toLowerCase() === 'pdf') {
        return true;
      } else {
        return false;
      }
    },
    getFileExtension(url) {
      return url.split('.').pop();
    },
    async getDataToShow() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `orders/${this.id}`,
        });
        this.data = res.data.data.order;
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
  },
  mounted() {

  },
  created() {
    this.getDataToShow();
  },
};
</script>
