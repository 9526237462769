import generalMutations from "./mutations";
import generalActions from "./actions.js";
import generalGetters from "./getters.js";

export default {
    namespaced: true,
    state: {
      carBrands :[],
      carCategories :[],
    },
    mutations: generalMutations,
    actions: generalActions,
    getters: generalGetters,
};
