<template>
  <div class="crud_form_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("PLACEHOLDERS.add_news") }}</h4>
    </div>
    <!-- End:: Title -->

    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameAr')" v-model.trim="data.nameAr" required />
          <!-- End:: Name Input -->

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameEn')" v-model.trim="data.nameEn" required />
          <!-- End:: Name Input -->
          <base-select-input col="6" :optionsList="sportList" :placeholder="$t('SIDENAV.Sports.name')"
                             v-model="data.sport" />
          
          <base-select-input col="6" :optionsList="types" :placeholder="$t('TABLES.Chat.type')"
                             v-model="data.type" />
          <base-text-editor col="12" type="text" :placeholder="$t('TABLES.News.newsEN')"
                      v-model.trim="data.descriptionAr" required />

          <base-text-editor col="12" type="text" :placeholder="$t('TABLES.News.newsAR')"
                      v-model.trim="data.descriptionEn" required />
          
          <base-image-upload-input col="6" identifier="image" :preSelectedImage="data.image.path"
                                   :placeholder="$t('TABLES.News.image')" @selectImage="selectImage" required />

          <span class="form-label">
                {{ $t('TABLES.News.images') }}
              </span>
          <div class="wrapper">
            <input type="file" ref="file" multiple="multiple">
          </div>
          <!-- Start:: Deactivate Switch Input -->
          <div class="input_wrapper switch_wrapper my-5">
            <v-switch color="green" :label="data.active ? $t('PLACEHOLDERS.active') : $t('PLACEHOLDERS.notActive')"
              v-model="data.active" hide-details></v-switch>
          </div>
          <!-- End:: Deactivate Switch Input -->

          <!-- Start:: Submit Button Wrapper -->
          <div class="btn_wrapper">
            <base-button class="mt-2" styleType="primary_btn" :btnText="$t('BUTTONS.save')" :isLoading="isWaitingRequest"
              :disabled="isWaitingRequest" />
          </div>
          <!-- End:: Submit Button Wrapper -->
        </div>
      </form>
    </div>
    <!-- END:: Single Step Form Content -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CreateStore",
  typeList() {
    return [
      {
        id: 3,
        name: this.$t("TABLES.News.public"),
        value: 'public',
      },
      {
        id: 1,
        name: this.$t("TABLES.News.private"),
        value: 'private',
      },
    ];
  },
  data() {
    return {
      types:[ {
        id: 3,
        name: this.$t("TABLES.News.public"),
        value: 'public',
      },
        {
          id: 1,
          name: this.$t("TABLES.News.private"),
          value: 'private',
        },],
      // Start:: Loader Control Data
      sportList: [],
      isWaitingRequest: false,
      // End:: Loader Control Data

      // Start:: Data Collection To Send
      data: {
        nameAr: null,
        nameEn: null,
        descriptionAr: null,
        sport: null,
        type: null,
        descriptionEn: null,
        image: {
          path: null,
          file: null,
        },
        images: {},
        active: true,
      },
      // End:: Data Collection To Send
    };
  },

  computed: {
  
  },

  methods: {
    async getSports() {
  try {
    let res = await this.$axios({
      method: "GET",
      url: `sports?page=0&limit=0`,
    });
    this.sportList=res.data.data;
  } catch (error) {
    console.log(error.response.data.message);
  }
},
    selectImage(selectedImage) {
      this.data.image = selectedImage;
    },
    // Start:: validate Form Inputs
    validateFormInputs() {
      this.isWaitingRequest = true;

      if (!this.data.nameAr) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.nameAr"));
        return;
      } else if (!this.data.nameEn) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.nameEn"));
        return;
      } else if (!this.data.descriptionEn) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.descriptionEn"));
        return;
      }else if (!this.data.descriptionAr) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.descriptionAr"));
        return;
      }  else {
        this.submitForm();
        return;
      }
    },
    // End:: validate Form Inputs

    // Start:: Submit Form
    async submitForm() {
      const REQUEST_DATA = new FormData();
      // Start:: Append Request Data
      REQUEST_DATA.append("name[ar]", this.data.nameAr);
      REQUEST_DATA.append("name[en]", this.data.nameEn);
      REQUEST_DATA.append("description[ar]", this.data.descriptionAr);
      REQUEST_DATA.append("description[en]", this.data.descriptionEn);
      REQUEST_DATA.append("sport_id", this.data.sport?.id);
      REQUEST_DATA.append("type", this.data.type.value);
     
      REQUEST_DATA.append("is_active", +this.data.active);
  if (this.data.image.file) {
    REQUEST_DATA.append("image[]", this.data.image.file);
  }
  for (var i = 0; i < this.$refs.file.files.length; i++ ){
    REQUEST_DATA.append("image[]", this.$refs.file.files[i]);
    // let file = this.$refs.file.files[i];
    // formData.append('files[' + i + ']', file);
  }
      // Start:: Append Request Data

      try {
        await this.$axios({
          method: "POST",
          url: `news`,
          data: REQUEST_DATA,
        });
        this.isWaitingRequest = false;
        this.$message.success(this.$t("MESSAGES.addedSuccessfully"));
        this.$router.push({ path: "/news/all" });
      } catch (error) {
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.message);
      }
    },
    // End:: Submit Form


  },

  created() {
  this.getSports()
    // Start:: Fire Methods
    // End:: Fire Methods
  },
};
</script>
<style scoped>
  form .input_wrapper .text_editor{
    display: block !important;
  }
</style>