<template>
  <div class="show_all_content_wrapper">
    <main>
      <div class="filter_content_wrapper" :class="{ active: filterFormIsActive }">
        <button class="filter_toggler" @click="filterFormIsActive = !filterFormIsActive">
          <i class="fal fa-times"></i>
        </button>
        <div class="filter_title_wrapper">
          <h5>{{ $t("TITLES.searchBy") }}</h5>
        </div>

        <div class="filter_form_wrapper">
          <form @submit.prevent="submitFilterForm">
            <div class="row justify-content-center align-items-center w-100">

              <base-input col="4" type="text" :placeholder="$t('PLACEHOLDERS.Name of the customer/service provider')"
                          v-model.trim="filterOptions.name"/>
              
              <base-input col="4" type="text" :placeholder="$t('PLACEHOLDERS.Customer/service provider mobile number')"
                          v-model.trim="filterOptions.mobile"/>

              <base-picker-input col="4" type="date" :placeholder="$t('TABLES.Coupons.startDate')"
                                 v-model.trim="filterOptions.dateFrom"/>

              <base-picker-input col="4" type="date" :placeholder="$t('TABLES.Coupons.endDate')"
                                 v-model.trim="filterOptions.dateTo"/>
            </div>

            <div class="btns_wrapper">
              <button class="submit_btn" :disabled="isWaitingRequest">
                <i class="fal fa-search"></i>
              </button>
              <button class="reset_btn" type="button" :disabled="isWaitingRequest" @click="resetFilter">
                <i class="fal fa-redo"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
      <!--  =========== End:: Filter Form =========== -->

      <!--  =========== Start:: Table Title =========== -->
      <div class="table_title_wrapper">
        <div class="title_text_wrapper">
          <h5>{{ $t("SIDENAV.subscription_reports.title") }}</h5>
          <button v-if="!filterFormIsActive" class="filter_toggler"
                  @click.stop="filterFormIsActive = !filterFormIsActive">
            <i class="fal fa-search"></i>
          </button>
        </div>
        <div class="title_route_wrapper">
          <base-button class="mt-0 pdf_btn" styleType="solid_btn" :btnText="$t('BUTTONS.downloadPdf')"
                       @fireClick="downloadPdf" :disabled="pdfDownloadBtnIsLoading">
            <template v-slot:btn_icon>
              <i class="fal fa-file-pdf"></i>
            </template>
          </base-button>


          <download-excel
            class="btn btn-default"
            :data="json_data"
            :fields="json_fields"
            worksheet="My Worksheet"
            :name="$t('SIDENAV.subscription_reports.title')">

            <base-button
              class="mt-0 excel_btn"
              styleType="solid_btn"
              :btnText="$t('BUTTONS.downloadExcel')"
            >
              <template v-slot:btn_icon>
                <i class="fal fa-file-excel"></i>
              </template>
            </base-button>

          </download-excel>
        </div>
      </div>
      <!--  =========== End:: Table Title =========== -->

      <!--  =========== Start:: Data Table =========== -->
      <v-data-table class="thumb" :loading="loading" :loading-text="$t('TABLES.loadingData')" :search="searchValue"
                    :headers="tableHeaders" :items="tableRows" item-class="ltr"
                    hide-default-footer>
        <!-- Start:: No Data State -->
        <template v-slot:no-data>
          {{ $t("TABLES.noData") }}
        </template>

        <template v-slot:[`item.id`]="{ item, index }">
          <div class="table_image_wrapper">
            <h6 class="text-danger" v-if="!item.id"> {{ $t("TABLES.noData") }} </h6>
            <p v-else>{{ index + 1 }}</p>
          </div>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <p class="text-danger" v-if="!item.title"> {{ $t("TABLES.noData") }} </p>
          <p v-else>{{ item.title }}</p>
        </template>

        <template v-slot:[`item.all_subscription_with_tax`]="{ item }">
          <router-link :to="{path: item.user_type == 'provider' ? `/providers-management/subscriptions/${item.id}` : `/clients/subscriptions/${item.id}`} ">
          <p style="color: darkblue;" v-if="!item.all_subscription_with_tax"> 0.000 </p>
          <p style="color: darkblue;" v-else>{{ parseFloat(item.all_subscription_with_tax).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</p>
          </router-link>
        </template>

        <template v-slot:[`item.total_app_gain`]="{ item }">
          <p v-if="!item.total_app_gain"> 0.000</p>
          <p v-else>{{ parseFloat(item.total_app_gain).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</p>
        </template>

        <template v-slot:[`item.total_tax`]="{ item }">
          <p v-if="!item.total_tax"> 0.000</p>
          <p v-else>{{ parseFloat(item.total_tax).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</p>
        </template>

        <template slot="body.append">
          <tr class="text-center">
            <td colspan="4"><strong>{{ $t('PLACEHOLDERS.totals') }}</strong></td>
            <td style="padding: 12px 0 0 0"><strong>{{ sumTotals('all_subscription_with_tax') }}
              {{ $t("PLACEHOLDERS.sar") }}</strong></td>
            <td><strong>{{ sumTotals('total_app_gain') }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
            <td><strong>{{ sumTotals('total_tax') }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
          </tr>
        </template>

      </v-data-table>
    </main>

    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
                  :filename="$t('SIDENAV.subscription_reports.title')" :pdf-quality="2" pdf-format="a4" :manual-pagination="false"
                  :paginate-elements-by-height="1400" pdf-content-width="100%"
                  @progress="bdfDownloadBtnIsLoading = true"
                  @hasGenerated="$message.success($t('PLACEHOLDERS.generatedSuccessfully'))" ref="html2Pdf">
      <section slot="pdf-content">
        <div class="pdf_file_content text-center">
          <h1 class="file_title"> {{ $t('SIDENAV.subscription_reports.title') }} </h1>

          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th v-for="header in tableHeaders" :key="header.value">
                  {{ header.text }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="row in tableRows" :key="row.id">
                <td>{{ row.id }}</td>
                <td>{{ row.name }}</td>
                <td>{{ row.mobile }}</td>
                <td>{{ row.type }}</td>
                <td>{{ row.all_subscription_with_tax ? parseFloat(row.all_subscription_with_tax).toFixed(3) : '0.000' }}
                  {{ $t("PLACEHOLDERS.sar") }}
                </td>
                <td>{{ row.total_app_gain ? parseFloat(row.total_app_gain).toFixed(3) : '0.000' }}
                  {{ $t("PLACEHOLDERS.sar") }}
                </td>
                <td>{{ row.total_tax ? parseFloat(row.total_tax).toFixed(3) : '0.000' }}
                  {{ $t("PLACEHOLDERS.sar") }}
                </td>
              </tr>
              <tr class="text-center">
                <td colspan="4"><strong>{{ $t('PLACEHOLDERS.totals') }}</strong></td>
                <td><strong>{{ sumTotals('all_subscription_with_tax') }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
                <td><strong>{{ sumTotals('total_app_gain') }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
                <td><strong>{{ sumTotals('total_tax') }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </div>
      </section>
    </vue-html2pdf>

  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import VueHtml2pdf from 'vue-html2pdf';

export default {
  name: "AllSubscriptionReports",
  components: {
    VueHtml2pdf,
  },
  computed: {
    ...mapGetters({
      getAppLocale: "AppLangModule/getAppLocale",
    }),
    activeStatuses() {
      return [
        {
          id: 1,
          name: this.$t("STATUS.active"),
          value: 1,
        },
        {
          id: 2,
          name: this.$t("STATUS.notActive"),
          value: 0,
        },
        {
          id: null,
          name: this.$t("STATUS.all"),
          value: 'both',
        },
      ];
    },
  },

  data() {
    return {
      drivers: [],
      json_fields: {},
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      loading: false,
      pdfDownloadBtnIsLoading: false,
      showTotals: true,
      isWaitingRequest: false,
      filterFormIsActive: false,
      filterOptions: {
        mobile: null,
        name: null,
        dateFrom: null,
        dateTo: null,
      },
      searchValue: "",
      tableHeaders: [
        {
          text: this.$t("TABLES.StoresTypes.serialNumber"),
          value: "id",
          align: "center",
          width: "80",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.name"),
          value: "name",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("PLACEHOLDERS.mobile"),
          value: "mobile",
          align: "center",
          sortable: false,
          width: "120",
        },
        {
          text: this.$t("PLACEHOLDERS.user_type"),
          value: "type",
          align: "center",
          sortable: false,
          width: "120",
        },
        {
          text: this.$t("PLACEHOLDERS.Total tax subscriptions"),
          value: "all_subscription_with_tax",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.Total app profit"),
          value: "total_app_gain",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("PLACEHOLDERS.Total added tax"),
          value: "total_tax",
          sortable: false,
          align: "center",
        },
      ],
      tableRows: [],
      dialogImage: false,
      selectedItemImage: null,
      dialogDescription: false,
      selectedDescriptionTextToShow: "",
      dialogDelete: false,
      dialogDeactivate: false,
      itemToChangeActivationStatus: false,
      itemToDelete: null,
      // End:: Dialogs Control Data
    };
  },
  methods: {
    async downloadPdf() {
      await this.$refs.html2Pdf.generatePdf();
      this.pdfDownloadBtnIsLoading = false;
    },
    sumTotals(prop) {
      console.log(prop)
      let total = 0;
      for (const item of this.tableRows) {
        total += parseFloat(item[prop]) || 0;
      }
      return total.toFixed(3);
    },
    selectDeactivateItem(item) {
      this.dialogDeactivate = true;
      this.itemToChangeActivationStatus = item;
    },
    async HandlingItemActivationStatus(selectedItem) {
      this.dialogDeactivate = false;
      let targetItem = this.itemToChangeActivationStatus ? this.itemToChangeActivationStatus : selectedItem;
      try {
        await this.$axios({
          method: "POST",
          url: `car-brand/${targetItem.id}/toggle-car-brand`,
        });
        this.$message.success(this.$t("MESSAGES.changeActivation"));
        let filteredElemet = this.tableRows.find(element => element.id === targetItem.id);
        filteredElemet.is_active = !filteredElemet.is_active;
        this.itemToChangeActivationStatus = null;
      } catch (error) {
        this.$message.error(error.response.data.message);
      }
    },

    async submitFilterForm() {
      this.setTableRows();
    },
    async resetFilter() {
      this.filterOptions.mobile = null;
      this.filterOptions.name = null;
      this.filterOptions.dateFrom = null;
      this.filterOptions.dateTo = null;
      this.setTableRows();
    },
    updateRouterQueryParam(pagenationValue) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: pagenationValue,
        },
      });
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    async setTableRows() {
      this.loading = true;
      try {
        let res = await this.$axios({
          method: "GET",
          url: "reports/subscription-reports",
          params: {
            mobile: this.filterOptions.mobile,
            dateFrom: this.filterOptions.dateFrom,
            dateTo: this.filterOptions.dateTo,
            name: this.filterOptions.name,
          },
        });
        this.loading = false;
        this.tableRows = res.data.data;
        this.json_data = res.data.data
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },
    handleExcel() {
      this.tableHeaders.map((item) => {
        this.json_fields[item.text] = item.value;
      })

      for (let key in this.json_fields) {
        if (this.json_fields.hasOwnProperty(key)) {
          if (this.json_fields[key] === 'app_subscriptions_gain') {
            this.json_fields[key] = {
              field: "app_subscriptions_gain",
              callback: value => {
                return value > 0 ? value : '0.000';
              }
            }
          }

          if (this.json_fields[key] === 'driver_gain_amount') {
            this.json_fields[key] = {
              field: "driver_gain_amount",
              callback: value => {
                return value > 0 ? value : '0.000';
              }
            }
          }

          if (this.json_fields[key] === 'total_tax_count') {
            this.json_fields[key] = {
              field: "total_tax_count",
              callback: value => {
                return value > 0 ? value : '0.000';
              }
            }
          }
        }
      }

    }
  },

  created() {
    window.addEventListener("click", () => {
      this.filterFormIsActive = false;
    });
    this.setTableRows();
    this.handleExcel()
  },
};
</script>
