<template>
  <div class="crud_form_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("PLACEHOLDERS.edit_news") }}</h4>
    </div>
    <!-- End:: Title -->

    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameAr')" v-model.trim="data.nameAr" required />
          <!-- End:: Name Input -->

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameEn')" v-model.trim="data.nameEn" required />
          <!-- End:: Name Input -->
          <base-select-input col="6" :optionsList="sportList" :placeholder="$t('SIDENAV.Sports.name')"
                             v-model="data.sport" />

          <base-select-input col="6" :optionsList="types" :placeholder="$t('TABLES.Chat.type')"
                             v-model="data.type" />
          <!-- Start:: Name Input -->
          <base-text-editor col="12" type="text" :placeholder="$t('TABLES.News.newsEN')"
                            v-model.trim="data.descriptionAr" required />

          <base-text-editor col="12" type="text" :placeholder="$t('TABLES.News.newsAR')"
                            v-model.trim="data.descriptionEn" required />
          <!-- End:: Name Input -->
          <!-- Start:: Image Upload Input -->
          <base-image-upload-input col="6" identifier="image" :preSelectedImage="data.image.path"
                                   @selectImage="selectImage" :placeholder="$t('TABLES.News.image')" required/>
          <!-- End:: Image Upload Input -->
              <span class="form-label">
                {{ $t('TABLES.News.images') }}
              </span>
              <div class="wrapper">
                <input type="file" ref="file" multiple="multiple">
              </div>
          <base-image-upload-input v-for="image in data.images " col="3" identifier="image_id"
                                   :preSelectedImage="image.url"
                                   @deleteImage="deleteImage" disabled deleted :imageId="image.id"/>
          <!-- Start:: Deactivate Switch Input -->
          <div class="input_wrapper switch_wrapper my-5">
            <v-switch color="green" :label="data.active ? $t('PLACEHOLDERS.active') : $t('PLACEHOLDERS.notActive')"
              v-model="data.active" hide-details></v-switch>
          </div>
          <!-- End:: Deactivate Switch Input -->

          <!-- Start:: Submit Button Wrapper -->
          <div class="btn_wrapper">
            <base-button class="mt-2" styleType="primary_btn" :btnText="$t('BUTTONS.save')" :isLoading="isWaitingRequest"
              :disabled="isWaitingRequest" />
          </div>
          <!-- End:: Submit Button Wrapper -->
        </div>
      </form>
    </div>
    <!-- END:: Single Step Form Content -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CreateNews",
  typeList() {
    return [
      {
        id: 3,
        name: this.$t("TABLES.News.public"),
        value: 'public',
      },
      {
        id: 1,
        name: this.$t("TABLES.News.private"),
        value: 'private',
      },
    ];
  },
  data() {
    return {
      types:[ {
        id: 3,
        name: this.$t("TABLES.News.public"),
        value: 'public',
      },
        {
          id: 1,
          name: this.$t("TABLES.News.private"),
          value: 'private',
        },],
      sportList: [],
      // Start:: Loader Control Data
      isWaitingRequest: false,
      // End:: Loader Control Data

      // Start:: Data Collection To Send
      data: {
        nameAr: null,
        nameEn: null,
        descriptionAr: null,
        descriptionEn: null,
        active: true,
        sport: null,
        type: null,
        image: {
          path: null,
          file: null,
        },
        images: {},
        image_id:null
      },
      // End:: Data Collection To Send
    };
  },

  computed: {
    // Start:: Vuex Getters
    // End:: Vuex Getters
    
  },

  methods: {
    async getSports() {
  try {
    let res = await this.$axios({
      method: "GET",
      url: `sports?page=0&limit=0`,
    });
    this.sportList=res.data.data;
  } catch (error) {
    console.log(error.response.data.message);
  }
},
    
    selectImage(selectedImage) {
      this.data.image = selectedImage;
    },

    async deleteImage(imageId) {
      console.log(imageId)
      try {
        await this.$axios({
          method: "DELETE",
          url: `media/${imageId}`,
        });
       this.data.images=this.data.images.filter(item => item.id !== imageId);
      } catch (error) {
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.message);
      }
    },
    // Start:: validate Form Inputs
    validateFormInputs() {
      this.isWaitingRequest = true;

      if (!this.data.nameAr) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.nameAr"));
        return;
      } else if (!this.data.nameEn) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.nameEn"));
        return;
      } else if (!this.data.descriptionEn) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.descriptionEn"));
        return;
      }else if (!this.data.descriptionAr) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.descriptionAr"));
        return;
      } else {
        this.submitForm();
        return;
      }
    },
    // End:: validate Form Inputs

    // Start:: Submit Form
    async submitForm() {
      const REQUEST_DATA = new FormData();
      // Start:: Append Request Data
      REQUEST_DATA.append("name[ar]", this.data.nameAr);
      REQUEST_DATA.append("name[en]", this.data.nameEn);
      REQUEST_DATA.append("description[ar]", this.data.descriptionAr);
      REQUEST_DATA.append("description[en]", this.data.descriptionEn);
      REQUEST_DATA.append("sport_id", this.data.sport?.id);
      REQUEST_DATA.append("type", this.data.type.value);
  if (this.data.image.file) {
    REQUEST_DATA.append("image[]", this.data.image.file);
  }
  for (var i = 0; i < this.$refs.file.files.length; i++ ){
    REQUEST_DATA.append("image[]", this.$refs.file.files[i]);
    // let file = this.$refs.file.files[i];
    // formData.append('files[' + i + ']', file);
  }
      REQUEST_DATA.append("is_active", +this.data.active);
      REQUEST_DATA.append("_method", "PUT");
      // Start:: Append Request Data

      try {
        await this.$axios({
          method: "POST",
          url: `news/${this.$route.params.id}`,
          data: REQUEST_DATA,
        });
        this.isWaitingRequest = false;
        this.$message.success(this.$t("MESSAGES.editedSuccessfully"));
        this.$router.push({ path: "/news/all" });
      } catch (error) {
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.message);
      }
    },
    // End:: Submit Form

    // start all news data
    async getNewsData() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `news/${this.$route.params.id}`,
        });
        this.data.nameAr = res.data.data.News.trans.name.ar;
        this.data.nameEn = res.data.data.News.trans.name.en;
        this.data.descriptionAr = res.data.data.News.trans.description.ar;
        this.data.descriptionEn = res.data.data.News.trans.description.en;
        this.data.active = res.data.data.News.is_active;
        this.data.image.path = res.data.data.News.image;
        this.data.images = res.data.data.News.images;
        this.data.sport = res.data.data.News.sport;
        this.data.type = this.types.find(type => type.value === res.data.data.News.type);
        // console.log(res.data.body.news)
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },
    // end all news data


  },

  created() {
    // Start:: Fire Methods
    this.getNewsData();
  this.getSports()
    // End:: Fire Methods
  },
};
</script>
<style>
  form .input_wrapper .text_editor{
    display: block !important;
  }
</style>