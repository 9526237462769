<template>
  <div class="crud_form_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("PLACEHOLDERS.show_news") }}</h4>
    </div>
    <!-- End:: Title -->

    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameAr')" v-model.trim="data.nameAr" disabled />
          <!-- End:: Name Input -->

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameEn')" v-model.trim="data.nameEn" disabled />
          <base-input col="6" type="text" :placeholder="$t('SIDENAV.Sports.name')" v-model.trim="data.sport.name" disabled />
          <base-input col="6" type="text" :placeholder="$t('TABLES.Chat.type')" v-model.trim="data.type" disabled />
          <!-- End:: Name Input -->
          
          <!-- Start:: Name Input -->
          <base-text-editor col="12" type="text" :placeholder="$t('TABLES.News.newsEN')"
                            v-model.trim="data.descriptionAr" disabled />

          <base-text-editor col="12" type="text" :placeholder="$t('TABLES.News.newsAR')"
                            v-model.trim="data.descriptionEn" disabled />
          <!--<base-input col="12" type="text" :placeholder="$t('TABLES.News.descriptionAr')" v-model.trim="data.descriptionAr" disabled />-->
          <!--<base-input col="12" type="text" :placeholder="$t('TABLES.News.descriptionEN')" v-model.trim="data.descriptionEn" disabled />-->
          <!-- End:: Name Input -->
          <base-image-upload-input col="6" identifier="image" :preSelectedImage="data.image"
                                   disabled :placeholder="$t('TABLES.News.image')"/>
          <h4>{{$t('TABLES.News.images')}}</h4>
          <base-image-upload-input v-for="image in data.images " col="3" identifier="image" :preSelectedImage="image.url"
                                    disabled />
          <!-- Start:: Deactivate Switch Input -->
          <div class="input_wrapper switch_wrapper my-5">
            <v-switch color="green" :label="data.active ? $t('PLACEHOLDERS.active') : $t('PLACEHOLDERS.notActive')"
              v-model="data.active" hide-details disabled></v-switch>
          </div>
          <div class="badges_wrapper justify-content-between">
            <div class="wrapper">
              <h3>{{$t('TABLES.Posts.comments_number')}}</h3>
              <v-chip color="amber darken-2" text-color="white">
                {{ data.comments_count }}
              </v-chip>
            </div>
          </div>
          <div class="badges_wrapper justify-content-between">
            <div class="wrapper">
              <h3>{{$t('TABLES.Posts.likes_number')}}</h3>
              <v-chip color="amber darken-2" text-color="white">
                {{ data.likes }}
              </v-chip>
            </div>
          </div>
          <div class="wrapper">
            <h3>{{$t('TABLES.Posts.comments')}}</h3>
            <div v-for="comment in data.comments" style="display: block">
              <router-link :to="{path:`/clients/${comment.user.id}`} ">
              <v-chip class="ma-2" color="info" text-color="white" label>
                <i class="fa fa-user"></i>
                {{comment.user.name}}
              </v-chip>
              </router-link>
              <v-chip color="amber darken-2" text-color="white" v-if="comment.type=='text'">
                {{ comment.comment }}
              </v-chip>
              <v-chip color="amber darken-2" text-color="white" v-if="comment.type=='image'">
                <img :src="comment.media" width="100" height="100">
              </v-chip>
              <v-chip color="amber darken-2" text-color="white" v-if="comment.type=='audio'">
                <audio controls>
                  <source :src="comment.media" type="audio/mpeg">
                  <source :src="comment.media" type="audio/ogg">
                  Your browser does not support the audio element.
                </audio>
              </v-chip>
              <v-chip class="ma-2" color="pink" text-color="white" label>
                <i class="fa fa-heart"></i>
                {{comment.likes}}
              </v-chip>
              <v-chip class="ma-2" color="primary" text-color="white" label>
                <i class="fa fa-calendar"></i>
                {{comment.created_at}}
              </v-chip>
              <br>
            </div>
            <!--</div>-->
          </div>
          <!-- End:: Deactivate Switch Input -->
        </div>
      </form>
    </div>
    <!-- END:: Single Step Form Content -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CreateNews",

  data() {
    return {
      // Start:: Loader Control Data
      isWaitingRequest: false,
      // End:: Loader Control Data

      // Start:: Data Collection To Send
      data: {
        nameAr: null,
        nameEn: null,
        descriptionAr: null,
        descriptionEn: null,
        sport: null,
        type: null,
        images:{},
        image:null,
        comments:{},
        comments_count:{},
        likes:{},
        active: true,
      },
      // End:: Data Collection To Send
    };
  },

  computed: {
    // Start:: Vuex Getters
    ...mapGetters({
      allOrderTypes: "ApiGetsModule/allOrderTypesData",
    }),
    // End:: Vuex Getters

    show_image() {
      return [
        {
          id: 1,
          name: this.$t('PLACEHOLDERS.yes'),
          value: 1
        },
        {
          id: 0,
          name: this.$t('PLACEHOLDERS.no'),
          value: 0
        }
      ]
    }
  },

  methods: {


    // start all news data
    async getNewsData() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `news/${this.$route.params.id}`,
        });
        this.data.nameAr = res.data.data.News.trans.name.ar;
        this.data.nameEn = res.data.data.News.trans.name.en;
        this.data.images = res.data.data.News.images;
        this.data.image = res.data.data.News.image;
        this.data.descriptionAr = res.data.data.News.trans.description.ar;
        this.data.descriptionEn = res.data.data.News.trans.description.en;
        this.data.active = res.data.data.News.is_active;
        this.data.comments = res.data.data.News.comments;
        this.data.comments_count = res.data.data.News.comments_count;
        this.data.likes = res.data.data.News.likes;
        this.data.sport = res.data.data.News.sport;
        this.data.type = res.data.data.News.type_trans;
        // console.log(res.data.body.news)
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },
    // end all news data


  },

  created() {
    // Start:: Fire Methods
    this.getNewsData();
    // End:: Fire Methods
  },
};
</script>
