import i18n from "@/plugins/i18n.js";
import axios from "axios";

export default {
  getCarBrands(context, payload) {
    axios({
      method: "GET",
      url: `car-brand/get-car-brands`,
    })
      .then((data) => {
        context.commit("setCarBrandsData",data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  },
  getCarCategories(context, payload) {
    axios({
      method: "GET",
      url: `car-category/get-car-categories/${payload}`,
    })
      .then((data) => {
        context.commit("setCarCategoriesData",data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  },
};
