<template>
  <div class="show_all_content_wrapper">
    <!-- Start:: Main Section -->
    <main>
      <!--  =========== Start:: Filter Form =========== -->
      <div class="filter_content_wrapper" :class="{ active: filterFormIsActive }">
        <button class="filter_toggler" @click="filterFormIsActive = !filterFormIsActive">
          <i class="fal fa-times"></i>
        </button>
        <div class="filter_title_wrapper">
          <h5>{{ $t("TITLES.searchBy") }}</h5>
        </div>
        <div class="filter_form_wrapper">
          <form @submit.prevent="submitFilterForm">
            <div class="row justify-content-center align-items-center w-100">
              <base-input col="4" type="text" :placeholder="$t('PLACEHOLDERS.orderNumber')" v-model.trim="filterOptions.serial"/>

              <base-select-input col="4" :optionsList="clients" :placeholder="$t('PLACEHOLDERS.client_name')"
                                 v-model="filterOptions.client"/>
              
              <base-input col="4" type="tel" :placeholder="$t('PLACEHOLDERS.client_mobile')"
                          v-model.trim="filterOptions.clientMobile"/>

              <base-select-input col="4" :optionsList="drivers" :placeholder="$t('PLACEHOLDERS.service_provider')"
                                 v-model="filterOptions.driver"/>
              
              <base-select-input col="3" :optionsList="statuses" :placeholder="$t('PLACEHOLDERS.order_status')"
                                 v-model="filterOptions.status" />

            </div>


            <div class="btns_wrapper">

              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>{{ $t("BUTTONS.search") }}</span>
                </template>
                <span class="submit_btn" @click="submitFilterForm" :disabled="isWaitingRequest">
                  <i class="fal fa-search"></i>
                </span>
              </a-tooltip>

              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>{{ $t("BUTTONS.rseet_search") }}</span>
                </template>
                <span class="reset_btn" :disabled="isWaitingRequest" @click="resetFilter">
                  <i class="fal fa-redo"></i>
                </span>
              </a-tooltip>

            </div>
          </form>
        </div>
      </div>
      <!--  =========== End:: Filter Form =========== -->

      <!--  =========== Start:: Table Title =========== -->
      <div class="table_title_wrapper">
        <div class="title_text_wrapper">
          <h5>{{ $t("SIDENAV.Trips.title") }}</h5>
          <button v-if="!filterFormIsActive" class="filter_toggler"
                  @click.stop="filterFormIsActive = !filterFormIsActive">
            <i class="fal fa-search"></i>
          </button>
        </div>
      </div>
      <!--  =========== End:: Table Title =========== -->

      <!--  =========== Start:: Data Table =========== -->
      <v-data-table class="thumb" :loading="loading" :loading-text="$t('TABLES.loadingData')" :search="searchValue"
                    :headers="tableHeaders" :items="tableRows" item-class="ltr"
                    :items-per-page="paginations.items_per_page"
                    hide-default-footer>
        <!-- Start:: No Data State -->
        <template v-slot:no-data>
          {{ $t("TABLES.noData") }}
        </template>
        <!-- Start:: No Data State -->

        <template v-slot:[`item.id`]="{ item, index }">
          <div class="table_image_wrapper">
            <h6 class="text-danger" v-if="!item.id"> {{ $t("TABLES.noData") }} </h6>
            <p v-else>{{ (paginations.current_page - 1) * paginations.items_per_page + index + 1 }}</p>
          </div>
        </template>

        <!-- Start:: Name -->
        <template v-slot:[`item.name`]="{ item }">
          <h6 class="text-danger" v-if="!item.name"> {{ $t("TABLES.noData") }} </h6>
          <h6 v-else> {{ item.name }} </h6>
        </template>

        <template v-slot:[`item.client.name`]="{ item }">
          <h6 class="text-danger" v-if="!item.client?.name"> - </h6>
          <h6 v-else> {{ item.client?.name }} </h6>
        </template>

        <template v-slot:[`item.provider.name`]="{ item }">
          <h6 class="text-danger" v-if="!item.provider?.name"> - </h6>
          <h6 v-else> {{ item.provider?.name }} </h6>
        </template>        
        
        <template v-slot:[`item.sub_total`]="{ item }">
          <h6 class="text-danger" v-if="!item.sub_total"> - </h6>
          <h6 v-else> {{ item.sub_total }} {{ $t("PLACEHOLDERS.sar") }}</h6>
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <h6 class="text-danger" v-if="!item.total"> - </h6>
          <h6 v-else> {{ item.total }} </h6>
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <h6> {{ parseFloat(item.total).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }} </h6>
        </template>

        <template v-slot:[`item.status_text`]="{ item }">
          <span class="text-success">
            {{item.status_text}}
          </span>
        </template>
        

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <a-tooltip placement="bottom" v-if="$can('orders show', 'orders')">
              <template slot="title">
                <span>{{ $t("BUTTONS.show") }}</span>
              </template>
              <button class="btn_show" @click="showItem(item)">
                <i class="fal fa-eye"></i>
              </button>
            </a-tooltip>

            <a-tooltip placement="bottom" v-if="$can('orders show', 'orders')">
              <template slot="title">
                <span>{{ $t("PLACEHOLDERS.download") }}</span>
              </template>
              <button class="btn_show" @click="selectDownloadedItem(item)">
                <i class="fal fa-download"></i>
              </button>
            </a-tooltip>            
            
            <a-tooltip placement="bottom" v-if="$can('orders show', 'orders')">
              <template slot="title">
                <span>{{ $t("PLACEHOLDERS.chat") }}</span>
              </template>
              <button class="btn_show" @click="selectChatItem(item)">
                <i class="fab fa-rocketchat"></i>
              </button>
            </a-tooltip>
          </div>
        </template>

        <template v-slot:top>

          <v-dialog v-model="dialogDownloaded">
            <v-card>
              <v-card-title class="text-h5 justify-center" v-if="tripToDownloaded">
                <a target="_blank" :href="tripToDownloaded.invoice_link">{{ $t('PLACEHOLDERS.download_trip_invoice') }}</a>
              </v-card-title>
              <v-card-actions>
                <v-btn class="modal_cancel_btn" @click="dialogDownloaded = false">{{ $t("BUTTONS.close") }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>


        </template>

      </v-data-table>
    </main>
    <template>
      <div class="pagination_container text-center mt-3 mb-0">
        <v-pagination class="py-0" square v-model="paginations.current_page" :length="paginations.last_page"
                      :total-visible="6" @input="updateRouterQueryParam($event)" :prev-icon="getAppLocale == 'ar' ? 'fal fa-angle-right' : 'fal fa-angle-left'
            " :next-icon="getAppLocale == 'ar' ? 'fal fa-angle-left' : 'fal fa-angle-right'
    "/>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AllDriverManagements",

  computed: {
    ...mapGetters({
      getAppLocale: "AppLangModule/getAppLocale",
    }),
    activeStatuses() {
      return [
        {
          id: 1,
          name: this.$t("STATUS.active"),
          value: 1,
        },
        {
          id: 2,
          name: this.$t("STATUS.notActive"),
          value: 0,
        },
        {
          id: 3,
          name: this.$t("STATUS.all"),
          value: null,
        },
      ];
    },
  },

  data() {
    return {
      clients: [],
      drivers: [],
      statuses: [],
      loading: false,
      isWaitingRequest: false,
      filterFormIsActive: false,
      filterOptions: {
        serial: null,
        client: null,
        clientMobile: null,
        driver: null,
        status: null,
      },
      searchValue: "",
      tableHeaders: [
        {
          text: this.$t("TABLES.Clients.serialNumber"),
          value: "id",
          align: "center",
          width: "80",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.orderNumber"),
          value: "serial_number",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.client_name"),
          value: "client.name",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.driver_name"),
          value: "provider.name",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.agreement_price"),
          value: "sub_total",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.total_order"),
          value: "total",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.order_status"),
          value: "status_text",
          align: "center",
          sortable: false,
        },        
        {
          text: this.$t("PLACEHOLDERS.order_date"),
          value: "created_at",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Clients.actions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      tableRows: [],
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 6,
      },
      dialogDeactivate: false,
      itemToChangeActivationStatus: null,
      deactivateReason: null,
      dialogBalance: false,
      itemToBalance: null,
      dialogDelete: false,
      itemToDelete: null,
      dialogDownloaded: false,
      tripToDownloaded: null,
    };
  },

  watch: {
    ["$route.query.page"]() {
      this.paginations.current_page = +this.$route.query.page;
      this.setTableRows();
    },
  },

  methods: {
    selectDownloadedItem(trip){
      this.dialogDownloaded = true;
      this.tripToDownloaded = trip;
    },
    selectChatItem(item){
      this.$router.push({path: `/orders/chat/${item.id}`});
    },
    async getClients() {
      try {
        await this.$axios({
          method: "GET",
          url: `orders/clients/get-clients`,
        }).then(({data}) => {
          this.clients = data
        });

      } catch (error) {

      }
    },
    async getDrivers() {
      try {
        await this.$axios({
          method: "GET",
          url: `orders/dashboard/accepted-providers`,
        }).then(({data}) => {
          this.drivers = data
        });

      } catch (error) {

      }
    },
    async getTripStatus() {
      try {
        await this.$axios({
          method: "GET",
          url: `orders/clients/status`,
        }).then(({data}) => {
          this.statuses = data
          if (this.$route.query.status){
            this.filterOptions.status = this.statuses.find(item => item.id === this.$route.query.status)
            this.setTableRows()
          }
        });

      } catch (error) {

      }
    },
    async submitFilterForm() {
      if (this.$route.query.page !== '1') {
        await this.$router.push({path: '/orders/all', query: {page: 1}});
      }
      this.setTableRows();
    },
    async resetFilter() {
      this.filterOptions.serial = null;
      this.filterOptions.client = null;
      this.filterOptions.clientMobile = null;
      this.filterOptions.driver = null;
      this.filterOptions.status = null;
      if (this.$route.query.page !== '1') {
        await this.$router.push({path: '/orders/all', query: {page: 1}});
      }
      this.setTableRows();
    },
    updateRouterQueryParam(pagenationValue) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: pagenationValue,
        },
      });
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    async setTableRows() {
      this.loading = true;
      try {

        let res = await this.$axios({
          method: "GET",
          url: "orders",
          params: {
            page: this.paginations.current_page,
            serial: this.filterOptions.serial,
            client: this.filterOptions.client?.id,
            clientMobile: this.filterOptions.clientMobile,
            status: this.filterOptions.status?.id,
            provider: this.filterOptions.driver?.id,
          },
        });
        this.loading = false;
        this.tableRows = res.data.data;
        this.paginations.last_page = res.data.meta.last_page;
        this.paginations.items_per_page = res.data.meta.per_page;
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },

    showItem(item) {
      this.$router.push({path: `/orders/show/${item.id}`});
    },
  },

  async created() {
    window.addEventListener("click", () => {
      this.filterFormIsActive = false;
    });
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    await this.getClients();
    await this.getDrivers();
    await this.getTripStatus();

    if (this.$route.query.provider) {
      this.filterOptions.driver = this.drivers.find(user => user.id == this.$route.query.provider)
      await this.setTableRows();
    }else{
      await this.setTableRows();
    }
  },
};
</script>
<style>
span.submit_btn {
  width: 45px;
  height: 45px;
  font-size: 16px;
  border-radius: 10px;
  color: var(--white_clr);
  transition: all 0.3s linear;
  background-color: #9B6300;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>