<template>
  <div class="crud_form_wrapper">
    <div class="form_title_wrapper">
      <h4>{{ $t("BUTTONS.add_notification") }}</h4>
    </div>

    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row align-items-center">

          <!--<base-select-input col="6" :optionsList="receiverTypes" :placeholder="$t('TABLES.Notifications.receiverType')"-->
                             <!--v-model="data.receiverType" required @input="getMethods"/>-->

          <div class="col-6">

            <div class="row">
              <base-select-input v-if="(clients.length)"
                                 class="col-12" :optionsList="clients" :placeholder="$t('TITLES.clients')"
                                 v-model="data.clients" required
                                 multiple/>
            </div>

          </div>

          <div class="col-12">
            <div class="row">

              <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.titleAr')" v-model.trim="data.titleAr"
                          required/>

              <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.titleEn')" v-model.trim="data.titleEn"
                          required/>

              <base-input col="12" rows="7" type="textarea" :placeholder="$t('PLACEHOLDERS.content')"
                          v-model.trim="data.contentAr" required/>

            </div>
          </div>

          <!-- Start:: Submit Button Wrapper -->
          <div class="btn_wrapper">
            <base-button class="mt-2" styleType="primary_btn" :btnText="$t('BUTTONS.save')"
                         :isLoading="isWaitingRequest"
                         :disabled="isWaitingRequest"/>
          </div>
          <!-- End:: Submit Button Wrapper -->
        </div>
      </form>
    </div>
    <!-- END:: Single Step Form Content -->
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "CreateNotification",

  computed: {
    
  },

  data() {
    return {
      isWaitingRequest: false,
      data: {
        ids: [],
        clients: [],
        titleAr: null,
        titleEn: null,
        contentAr: null,
        contentEn: null,
      },
      clients: [],
    };
  },

  methods: {
    validateFormInputs() {
      if (!this.data.titleAr) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.titleAr"));
      } else if (!this.data.titleEn) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.titleEn"));
      } else if (!this.data.contentAr) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.content"));
      } else {
        this.submitForm();
      }
    },

    async submitForm() {
      const REQUEST_DATA = new FormData();

      // if (!this.data.clients) {
      //   this.data.clients = [];
      // }
      // if (!this.data.drivers) {
      //   this.data.drivers = [];
      // }

      this.ids = [];
      if ( this.data.clients.length) {
        this.data.clients.forEach((element) => {
          this.data.ids.push(element.id)
        });
      }

      if (this.data.ids.length) {
        this.data.ids.forEach((element, index) => {
          REQUEST_DATA.append(`ids[${index}]`, element);
        });
      }

      REQUEST_DATA.append("title_ar", this.data.titleAr);
      REQUEST_DATA.append("title_en", this.data.titleEn);
      REQUEST_DATA.append("body", this.data.contentAr);
      REQUEST_DATA.append("to_all", 0);
      // REQUEST_DATA.append("type", this.data.receiverType?.value);

      try {
        await this.$axios({
          method: "POST",
          url: `notification/store`,
          data: REQUEST_DATA,
        });
        this.isWaitingRequest = false;
        this.$message.success(this.$t("MESSAGES.sentSuccessfully"));
        this.$router.push({path: "/all-notifications/all"});
      } catch (error) {
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.message);
      }
    },

    async getClients() {
      this.loading = true;
      try {
        let res = await this.$axios({
          method: "GET",
          url: "users",
          params: {"status": 1,'type':'client','page':0}
        });
        this.loading = false;
        this.clients = res.data.data.map(client => client.user);
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },
    async getDrivers() {
      this.loading = true;
      try {
        let res = await this.$axios({
          method: "GET",
          url: "providers",
          params: {"status": 1,'page':0}
        });
        this.loading = false;
        this.drivers = res.data.data;
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },

    getMethods() {

      this.data.clients = null;

      if (this.data.receiverType.value === "client") {
        this.getClients();
      } else if (this.data.receiverType.value === "provider") {
        this.getDrivers();
      }

    },

    // End:: Set Table Rows
  },

  created() {
    this.getClients();
  },
};
</script>
