<template>
  <div class="side_nav_wrapper" :class="{ active: navIsActive }">
    <!-- Start:: Toggle Btn -->
    <button class="close_btn" @click="$emit('fireToggleNavDrawerEmit')">
      <i class="fal fa-times"></i>
    </button>
    <!-- End:: Toggle Btn -->

    <!-- Start:: Logo -->
    <div class="logo_wrapper">
      <router-link class="logo" to="/home">
        <img src="@/assets/media/logo/logo.webp" alt="Logo" width="170" height="125" />
        <!--<img v-else src="@/assets/media/logo/logoWhite.png" alt="Logo" width="170" height="125" />-->
      </router-link>
    </div>
    <!-- End:: Logo -->

    <div class="side_nav_links_wrapper">
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')">
        <router-link to="/home">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/home.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.dashboard") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')"
        v-if="hasPermission('notifications-index')">
        <router-link to="/all-notifications/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/notifications.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.notifications.title") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('banks-index')">
        <router-link to="/banks/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/store.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("PLACEHOLDERS.bank_management") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('services-index')">
        <router-link to="/services/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/store.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("PLACEHOLDERS.main_services") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('subservices-index')">
        <router-link to="/sub-services/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/store.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("PLACEHOLDERS.sub_services") }}
          </span>
        </router-link>
      </div>

      <!--<div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('orders-index')">-->
        <!--<router-link to="/orders/all">-->
          <!--<span class="route_icon">-->
            <!--<img src="@/assets/media/icons/ui_icons/store.svg" alt="icon" width="40" height="40" />-->
          <!--</span>-->
          <!--<span class="route_text">-->
            <!--{{ $t("CHARTS.orders") }}-->
          <!--</span>-->
        <!--</router-link>-->
      <!--</div>-->

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('opinions-index')">
        <router-link to="/opinions/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/store.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("PLACEHOLDERS.opinions") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('users-index')">
        <router-link to="/clients/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/clients.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Clients.title") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('interests-index')">
        <!--v-if="$can('interests index', 'interests')">-->
        <router-link to="/interests/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/interests2.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Interests.title") }}
          </span>
        </router-link>
      </div>
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('sliders-index')">
        <!--v-if="$can('interests index', 'interests')">-->
        <router-link to="/sliders/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/sliders.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.ImagesSpaces.title") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('sports-index')">
        <!--v-if="$can('sports index', 'sports')">-->
        <router-link to="/sports/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/sports.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Sports.title") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('leagues-index')">
        <!--v-if="$can('leagues index', 'leagues')">-->
        <router-link to="/leagues/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/leagues.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Leagues.title") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('teams-index')">
        <!--v-if="$can('teams index', 'teams')">-->
        <router-link to="/teams/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/teams.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Teams.title") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('countries-index')">
           <!--v-if="$can('countries index', 'countries')">-->
        <router-link to="/countries/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/city.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Countries.title") }}
          </span>
        </router-link>
      </div>
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('cities-index')">
           <!--v-if="$can('cities index', 'cities')">-->
        <router-link to="/cities/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/city.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Cities.title") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')"  v-if="hasPermission('states-index')">
           <!--v-if="$can('areas index', 'areas')">-->
        <router-link to="/states/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/areas.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Areas.title") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')"  v-if="hasPermission('news-index')">
           <!--v-if="$can('areas index', 'areas')">-->
        <router-link to="/news/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/news.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("PLACEHOLDERS.news") }}
          </span>
        </router-link>
      </div>
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')"  v-if="hasPermission('packages-index')">
           <!--v-if="$can('areas index', 'areas')">-->
        <router-link to="/packages/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/packages.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("PLACEHOLDERS.packages") }}
          </span>
        </router-link>
      </div>
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')"  v-if="hasPermission('subscriptions-index')">
           <!--v-if="$can('areas index', 'areas')">-->
        <router-link to="/documentedSubscriptions/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/subscription.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("PLACEHOLDERS.documentedSubscriptions") }}
          </span>
        </router-link>
      </div>
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('chats-index')">
        <router-link to="/chats/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/chats.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("TABLES.Chat.Rooms") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('posts-index')">
        <router-link to="/posts/all?live=1">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/live.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Posts.live") }}
          </span>
        </router-link>
      </div>
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('posts-index')">
        <router-link to="/posts/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/posts.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Posts.title") }}
          </span>
        </router-link>
      </div>
 
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('promoted-posts-index')">
        <router-link to="/posts/all?promoted=1">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/posts.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("TABLES.Posts.promotedPosts") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('stories-index')">
        <router-link to="/stories/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/stories.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Stories.title") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('promoted-posts-index')">
        <router-link to="/promotedPosts/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/subscription.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("TABLES.PromotedPosts.name") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('admins-index')">
        <router-link to="/admins/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/admins.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Admins.title") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" 
           v-if="hasPermission('roles-index')"
      >
        <router-link to="/roles/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/roles.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Roles.title") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('nationalities-index')">
        <router-link to="/nationalities/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/roles.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Nationalities.title") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('payments-index')">
        <router-link to="/payments/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/roles.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Payments.title") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('contactuses-index')">
        <router-link to="/contact-messages/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/messages.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.ContactMessages.title") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('subscriptions-reports-index')">
        <router-link to="/subscription-reports/index">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/messages.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.subscription_reports.title") }}
          </span>
        </router-link>
      </div>      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('reports-index')">
        <router-link to="/reports/index">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/report.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Reports.title") }}
          </span>
        </router-link>
      </div>      
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('finished-orders-reports-index')">
        <router-link to="/finished-orders-reports/index">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/messages.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.finished_order_reports.title") }}
          </span>
        </router-link>
      </div>
      
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('un-finished-orders-reports-index')">
        <router-link to="/active-orders-reports/index">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/messages.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.un_finished_order_reports.title") }}
          </span>
        </router-link>
      </div>

      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('pages-create')">
        <router-link to="/pages/all">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/box.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.Pages.title") }}
          </span>
        </router-link>
      </div>
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('settings-create')">
        <router-link to="/settings">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/settings.svg" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.settings.title") }}
          </span>
        </router-link>
      </div>
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')" v-if="hasPermission('settings-create')">
        <router-link to="/contact_settings">
          <span class="route_icon">
            <img src="@/assets/media/icons/ui_icons/communications.png" alt="icon" width="40" height="40" />
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.settings.contact") }}
          </span>
        </router-link>
      </div>
      <div class="side_routes_wrapper">
        <a-menu style="width: 100%" mode="vertical" :open-keys="openKeys" @openChange="onOpenChange">
          <!-- Start:: Side Nav Tab -->
          <a-sub-menu  v-if="checkPermissionsChildren(item.children)"
                       v-for="item in sideNavbarList" :key="item.key" :data-type="!item.children ? 'single_route' : ''">
            <template v-if="item.children">
              <!-- ========= Start:: Main Tab -->
              <span slot="title">
                <img :src="item.icon" alt="icon" width="35" height="35" />
                <span> {{ item.title }} </span>
              </span>
              <!-- ========= End:: Main Tab -->

              <!-- ========= Start:: Nested Tabs -->
              <!-- v-if="childItem.hasPermission" -->
              <a-menu-item v-for="childItem in item.children" :key="childItem.hey" v-if="childItem.hasPermission">
                <button class="text-start w-100" @click="$emit('fireToggleNavDrawerEmit')">
                  <router-link :to="childItem.route">
                    <i class="fad fa-circle"></i>
                    {{ childItem.title }}
                  </router-link>
                </button>
              </a-menu-item>
              <!-- ========= End:: Nested Tabs -->
            </template>

          </a-sub-menu>
          <!-- End:: Side Nav Tab -->
        </a-menu>
      </div>
      <div class="side_routes_wrapper">
        <a-menu style="width: 100%" mode="inline">
          <!-- Start:: Logout Tab -->
          <a-sub-menu key="logout" @titleClick="logoutConfirmationModalIsOpen = true">
            <!-- ========= Start:: Main Tab -->
            <span slot="title" class="logout_btn">
              <img src="../../assets/media/icons/ui_icons/logout.svg" alt="icon" width="35" height="35" />
              <span style="color: #313131">
                {{ $t("SIDENAV.Logout.title") }}
              </span>
            </span>
            <!-- ========= End:: Main Tab -->
          </a-sub-menu>
          <!-- End:: Logout Tab -->
        </a-menu>
      </div>

      <v-dialog v-model="logoutConfirmationModalIsOpen">
        <v-card>
          <v-card-title class="text-h5 justify-center">
            {{ $t("TITLES.logoutConfirmingMessage") }}
          </v-card-title>
          <v-card-actions>
            <v-btn class="modal_confirm_btn" @click="logout">{{
              $t("BUTTONS.logout")
            }}</v-btn>

            <v-btn class="modal_cancel_btn" @click="logoutConfirmationModalIsOpen = false">{{ $t("BUTTONS.cancel")
            }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SideNav",

  emits: ["fireToggleNavDrawerEmit"],

  props: {
    navIsActive: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    // Start:: Vuex Get Authenticated User Data
    ...mapGetters({
      getAuthenticatedUserData: "AuthenticationModule/getAuthenticatedUserData",
      getAppTheme: "AppThemeModule/getAppTheme",
    }),
  
    // End:: Vuex Get Authenticated User Data

    // Start:: Extract Side Nav Keys
    extractSideNavItemsKeys() {
      let extractedKeys = [];
      this.sideNavbarList.forEach((element) => {
        extractedKeys.push(element["key"]);
      });
      return extractedKeys;
    },
    // End:: Extract Side Nav Keys

  },

  data() {
    return {
      // Start:: Controle Open Tabs Data
      openKeys: [],
      // End:: Controle Open Tabs Data

      // Start:: Side Navbar List
      sideNavbarList: [
        {
          key: "appDrivers",
          title: this.$t("PLACEHOLDERS.providers"),
          icon: require("@/assets/media/icons/ui_icons/book_mark.svg"),
          children: [
            {
              key: "appDrivers",
              title: this.$t("PLACEHOLDERS.providers_requests"),
              route: "/providers-requests/all",
              hasPermission: this.$can('providers requests index', 'providers_requests'),
            },
            {
              key: "appDrivers",
              title: this.$t("PLACEHOLDERS.providers_data_requests"),
              route: "/drivers-edits-requests/all",
              hasPermission: this.$can('amendment requests index', 'amendment_requests'),
            },
            {
              key: "appDrivers",
              title: this.$t("PLACEHOLDERS.providers_management"),
              route: "/providers-management/all",
              hasPermission: this.$can('providers index', 'providers'),
            }
          ],
        },
        {
          key: "appContent",
          title: this.$t("SIDENAV.AppContent.title"),
          icon: require("@/assets/media/icons/ui_icons/book_mark.svg"),
          children: [
            {
              key: "aboutUs",
              title: this.$t("SIDENAV.AppContent.aboutUs"),
              route: "/app-content/about-us",
              hasPermission: this.$can('about us index', 'about_us_index'),
            },
            {
              key: "termsAndConditions",
              title: this.$t("SIDENAV.AppContent.termsAndConditions"),
              route: "/app-content/terms",
              hasPermission: this.$can('terms index', 'terms_index'),
            },
            {
              key: "privacyPolicy",
              title: this.$t("SIDENAV.AppContent.privacyPolicy"),
              route: "/app-content/policy",
              hasPermission: this.$can('privacy index', 'privacy_index'),
            },
            {
              key: "contact",
              title: this.$t("PLACEHOLDERS.contact_admins"),
              route: "/contact_settings",
              hasPermission: this.$can('contact index', 'contact_index'),
            },
            // {
            //   key: "bank-accounts",
            //   title: this.$t("PLACEHOLDERS.bank_accounts_settings"),
            //   route: "/app-content/bank-accounts",
            //   hasPermission: this.$can('settings create', 'settings'),
            // },
          ],
        },
      ],
      logoutConfirmationModalIsOpen: false,
    };
  },

  methods: {
    // Start:: Vuex Auth Actions
    ...mapActions({
      logout: "AuthenticationModule/logout"
    }),

    //   hasPermission(permission) {
    //   return JSON.parse(localStorage.getItem("Fans_admin_roles")).only_permissions.includes(permission);
    // },
    
    checkPermissionsChildren(children){
      let permissions = children.map(child => child.hasPermission)
      return permissions.some(element => element === true);
    },
    // End:: Vuex Auth Actions

    // Start:: Controle Open Tabs
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.extractSideNavItemsKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    // End:: Controle Open Tabs
  },

  created() {
    console.log('data');
    console.log(JSON.parse(localStorage.getItem("Fans_admin_roles")).only_permissions);
  },
};
</script>
