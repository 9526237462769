import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

// Start:: Importing Main Sass Styles File
import "./assets/sass/main.scss";
// End:: Importing Main Sass Styles File

// Start:: Importing Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
// End:: Importing Bootstrap

// Start:: Importing Plugins
import i18n from "./plugins/i18n";
import axios from "./plugins/axios";
import vuetify from "./plugins/vuetify";
import Antd from "./plugins/antDesign";
import "./plugins/googleMaps";
import "./plugins/formComponents";
import "./plugins/globalComponents";
import "./plugins/3rdPartyLibraries";
import JsonExcel from "vue-json-excel";

import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');


const USER_TOKEN =
  store.getters["AuthenticationModule/getAuthenticatedUserData"].token;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: "5b85d115b0c9cc6a906c",
  cluster: "eu",
  encrypted: true,
});

import firebase from "firebase/app";
import "firebase/firebase-messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCihSYx5RQ8nFfpGj3i7PThu1H9mthc7r8",
  authDomain: "fans-1dd36.firebaseapp.com",
  projectId: "fans-1dd36",
  storageBucket: "fans-1dd36.appspot.com",
  messagingSenderId: "354824800937",
  appId: "1:354824800937:web:c303bcc0fdaa2b5e24f7b8",
  measurementId: "G-RQQQ7RLY8Z"
};

firebase.initializeApp(firebaseConfig);
// End:: Importing Plugins

// casl vue permissions

import ability from "./plugins/ability";
import { abilitiesPlugin } from "@casl/vue";
Vue.use(abilitiesPlugin, ability);

// casl vue permissions

// Start:: Set App Lang  & Theme
store.dispatch("AppLangModule/handelAppDefaultLanguage");
store.dispatch("AppThemeModule/handelAppDefaultTheme");
// End:: Set App Lang  & Theme

Vue.use(Antd);
Vue.component("downloadExcel", JsonExcel);

const hasPermission = {
  methods: {
    hasPermission(permission) {
      return JSON.parse(localStorage.getItem("Fans_admin_roles"))?.only_permissions.includes(permission);
    },
  }
};

// Mix the global mixin into all components
Vue.mixin(hasPermission);


Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
