<template>
  <div class="crud_form_wrapper single_show_content_wrapper">
    <div class="form_title_wrapper">
      <h4>{{ $t("TITLES.showClient", {name: data.provider?.name}) }}</h4>
    </div>
    
    <div class="single_step_form_content_wrapper">
      <table class="table table-striped">
        <tbody>
        <template v-if="data.type == 'bank'">
          <tr>
            <td>{{ $t('PLACEHOLDERS.bank') }}</td>
            <td>{{ anotherData.bank?.name }}</td>
          </tr>
          <tr>
            <td>{{ $t('PLACEHOLDERS.iban') }}</td>
            <td>{{ data.data?.iban }}</td>
          </tr>
        </template>
        <template v-if="data.type == 'company'">
          <tr v-if="data.data?.company_name">
            <td>{{ $t('PLACEHOLDERS.company_name') }}</td>
            <td>{{ data.data?.company_name }}</td>
          </tr>
          <tr v-if="anotherData.commercial">
            <td>{{ $t('PLACEHOLDERS.commercialProfileImage') }}</td>
            <td>
              <img :src="anotherData.commercial" alt="icon" style="width: 80px;height: 80px;" class="img-thumbnail" />
            </td>
          </tr>         
          <tr v-if="anotherData.company_logo">
            <td>{{ $t('PLACEHOLDERS.Company_logo') }}</td>
            <td>
              <img :src="anotherData.company_logo" alt="icon" style="width: 80px;height: 80px;" class="img-thumbnail" />
            </td>
          </tr>
        </template>

        <template v-if="data.type == 'service'">
          <tr v-if="data.data?.experience">
            <td>{{ $t('PLACEHOLDERS.experience_years') }}</td>
            <td>{{ data.data?.experience }}</td>
          </tr>
          <template v-if="anotherData.mainServices">
            <tr colspan="2">
              <td colspan="2" style="font-size: 18px;font-weight: bold">
                {{ $t('PLACEHOLDERS.main_services') }}
              </td>
            </tr>
            <tr colspan="2" v-for="main in anotherData.mainServices">
              <td colspan="2">{{main.name}}</td>
            </tr>
          </template>

          <template v-if="anotherData.subServices">
            <tr colspan="2">
              <td colspan="2" style="font-size: 18px;font-weight: bold">
                {{ $t('PLACEHOLDERS.sub_services') }}
              </td>
            </tr>
            <tr colspan="2" v-for="main in anotherData.subServices">
              <td colspan="2">{{main.name}}</td>
            </tr>
          </template>
        </template>
        <template v-if="data.type == 'work'">
          <tr>
            <td style="font-size: 18px;font-weight: bold">
              {{ $t('PLACEHOLDERS.provider_works') }}
            </td>
          </tr>
          <template v-if="anotherData.works.length">
            <tr v-for="main in anotherData.works">
              <td>{{main.name}}</td>
              <td>
                <img :src="main.file" style="width: 80px;height: 80px">
              </td>
            </tr>
          </template>
        </template>
        
        </tbody>
      </table>
    </div>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyC2MTR_vRbsbQQRPt5f5ZLCvvaKOpzkzlA">
</script>
<script>
export default {
  name: "ShowproviderRequest",

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      anotherData: {},
      isWaitingRequest: false,
      addressesTableHeaders: [
        {text: this.$t("TABLES.Addresses.serialNumber")},
        {text: this.$t("TABLES.Addresses.address")},
        {text: this.$t("TABLES.Addresses.longitude")},
        {text: this.$t("TABLES.Addresses.latitude")},
        {text: this.$t("TABLES.Addresses.type")},
        {text: this.$t("TABLES.Addresses.isDefault")},
      ],
      data: {},
    }
  },

  methods: {
    async getDataToShow() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `amendment-requests/${this.id}`,
        });
        this.data = res.data.data.AmendmentRequests;
        this.anotherData = res.data.data.data;
        console.log(this.anotherData)
      } catch (error) {
        console.log(error.response.data.message);
      }
    },
  },
  mounted() {

  },
  created() {
    this.getDataToShow();
  },
};
</script>
