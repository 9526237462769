<template>
  <div class="my-5" :class="col ? `col-lg-${col}` : ''">
    <div class="single_image_input_wrapper">
      <span class="input_placeholder" v-if="placeholder">
        {{ placeholder }}
        <span class="text-danger" v-if="required"> * </span>
      </span>
      <div class="wrapper">
        <label :for="identifier"></label>
        <img
          :class="(preSelectedImage || image.file) ? 'image_uploaded' : 'image_placeholder'"
          :src="preSelectedImage ? preSelectedImage : image.path"
          alt="Image To Upload"
          width="100"
          height="100"
        />
        <span v-if="documented" class="badge" 
              style=" 
          position: absolute;
          top: 0;
          right: 0;
          
          z-index: 999999;
          color: white;
          padding: 5px 10px;
          border-radius: 50%;
          font-size: 12px;"
        ><i class="fa fa-certificate fa-2x" style="color: blue"></i></span>
        <span v-if="deleted" class="badge" 
              style=" 
          position: absolute;
          top: 0;
          right: 0;
          
          z-index: 999999;
          color: white;
          padding: 5px 10px;
          border-radius: 50%;
          font-size: 12px;"
        ><i @click="removeImage" class="fa fa-trash fa-2x" style="color: orangered"></i></span>
        <input
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          class="form-control fileInput"
          :id="identifier"
          @change="selectImageToUpload"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import predictFileType from "@/utils/predictFileType.js";

  export default {
    name: "BaseImageUploadInput",

    emits: ["selectImage"],

    props: {
      // ====== Start:: General Inputs Props ====== //
      col: {
        required: false,
        type: String,
      },
      identifier: {
        required: true,
        type: String,
      },
      preSelectedImage: {
        required: false,
        type: String,
      },
      placeholder: {
        required: false,
        type: String,
      },
      required: {
        required: false,
        type: Boolean,
        default: false,
      },
      disabled: {
        required: false,
        type: Boolean,
        default: false,
      },
      documented: {
        required: false,
        type: Boolean,
        default: false,
      },
      deleted: {
        required: false,
        type: Boolean,
        default: false,
      },
      imageId: {
        required: false,
        type: Number,
      },
      // ====== End:: General Inputs Props ====== //
    },

    data() {
      return {
        image: {
          path: require("@/assets/media/images/upload_image.svg"),
          file: null,
        },
        image_id:this.imageId
      };
    },

    methods: {
      // Start:: Select Image
      selectImageToUpload(e) {
        if (predictFileType(e.target.files[0].name) !== "image_file") {
          this.$message.error(this.$t("VALIDATION.selectedFileMustBeImage"));
          return;
        } else {
          this.image.path = URL.createObjectURL(e.target.files[0]);
          this.image.file = e.target.files[0];
          this.$emit("selectImage", {
            identifier: this.identifier,
            ...this.image,
        })
          ;
          // console.log("SELECTED =>", this.image);
        }
      },
      removeImage() {

        try {
          // await this.$axios({
          //   method: "DELETE",
          //   url: `media/${this.image_id}`,
          // });
          this.$emit("deleteImage", this.image_id);
          // this.image = {};
          // this.image_id = null;
          // this.$refs.fileInput.value = ''; // Clear file input
        } catch (error) {
          this.isWaitingRequest = false;
          this.$message.error(error.response.data.message);
        }
      },
      // End:: Select Image
    },
  };
</script>
