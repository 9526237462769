<template>
  <div class="crud_form_wrapper single_show_content_wrapper">
    <!-- Start:: Title -->
    <!--<div class="form_title_wrapper">-->
    <!--<h4>{{ $t("TITLES.showClient", {name: data.title}) }}</h4>-->
    <!--</div>-->
    <!-- End:: Title -->


    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">

      <!-- ==== Start:: Client Main Data ==== -->
      <form>
        <div class="row">
          <!-- Start:: Image Upload Input -->
          <div class="single_image_input_wrapper" @click="showMediaModal(data2.image)">
            <div class="wrapper">
              <label></label>
              <img class="image_uploaded" :src="data2.image" alt="Image To Upload" width="100" height="100"/>
            </div>
          </div>

          <!--<base-image-upload-input col="12" v-if="data.type=='image'" identifier="media" :placeholder="$t('TABLES.Posts.media')" :preSelectedImage="data.media" disabled @click="showMediaModal(data.media,data.type)" class="disabled_input"/>-->
          
          <!-- End:: Image Upload Input -->

          <!-- Start:: Ar Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.name')" v-model.trim="data2.name" disabled
                      class="disabled_input"/>
          <!-- End:: Ar Name Input -->

          <!-- Start:: Ar Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.date')" v-model.trim="data2.date" disabled
                      class="disabled_input"/>
          <!-- End:: Ar Name Input -->

          <!-- Start:: description Input -->
          <base-input col="12" type="text" :placeholder="$t('PLACEHOLDERS.type')" v-model.trim="data2.type"
                      readonly
                      class="disabled_input"/>
          <!-- End:: description Input -->

          <div class="badges_wrapper justify-content-between">
            <div class="wrapper">
              <h3>{{$t('TABLES.Chat.admins')}}</h3>
              <v-chip color="amber darken-2" text-color="white" v-for="item in data2.admins">
                {{ item.user.name }}
              </v-chip>
            </div>
          </div>

          <div class="badges_wrapper justify-content-between">
            <div class="wrapper">
              <h3>{{$t('TABLES.Chat.members')}}</h3>
              <v-chip color="amber darken-2" text-color="white" v-for="item in data2.members">
                {{ item.user.name }}
              </v-chip>
            </div>
          </div>

          <!--<div class="badges_wrapper justify-content-between">-->
          <image-modal v-if="dialogMedia" :modalIsOpen="dialogMedia" :modalImage="mediaSrc"
                       @toggleModal="dialogMedia = !dialogMedia"/>
          
          <!--<div v-if="data.comments" v-for="comment in data.comments">-->
          <!--<p>{{comment.comment}}</p>-->
          <!--</div>-->
          <!--<div>-->
          <!--<p>{{comment.user.name}} <span>&bull;</span>{{ comment.created_at}}</p>-->
          <!--</div>-->
          <!-- End:: Email Input -->

          <!-- Start:: Joining Date Input -->

        </div>
      </form>

    </div>
    <!-- End:: Single Step Form Content -->
  </div>
</template>

<script>
  export default {
    name: "SingleChat",

    props: {
      id: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        data: {},
        data2: {
          'name':null,
          'type':null,
          'image':null,
          'date':null,
          'members':{},
          'admins':{},
        },
        dialogMedia: false,
        mediaSrc: '',
        mediaType: '',
        // Start:: Loading Data
        isWaitingRequest: false,
        // End:: Loading Data

        // Start:: Data
        
      };
    },

    methods: {
      async getDataToShow(){
    try {
      let res = await
      this.$axios({
        method: "GET",
        url: `chats/${this.id}`,
      });
      this.data2.name = res.data.data.Chat.name;
      this.data2.type = res.data.data.Chat.type;
      this.data2.image = res.data.data.Chat.image;
      this.data2.date = res.data.data.Chat.created_at;
      this.data2.members = res.data.data.Chat.members;
      this.data2.admins = res.data.data.Chat.admins;
    } catch (error) {
      console.log(error.response.data.message);
    }
  },
  showMediaModal(media)
  {
      this.dialogMedia = true;
    this.mediaSrc = media;
  }
  },

  created()
  {
    this.getDataToShow();
  }
  ,
  }
  ;
</script>
