<template>
  <div class="show_all_content_wrapper">
    <!-- Start:: Main Section -->
    <main>
      <!--  =========== Start:: Filter Form =========== -->
      <div class="filter_content_wrapper" :class="{ active: filterFormIsActive }">
        <button class="filter_toggler" @click="filterFormIsActive = !filterFormIsActive">
          <i class="fal fa-times"></i>
        </button>
        <div class="filter_title_wrapper">
          <h5>{{ $t("TITLES.searchBy") }}</h5>
        </div>
        <div class="filter_form_wrapper">
          <form @submit.prevent="submitFilterForm">
            <div class="row justify-content-center align-items-center w-100">
              <base-input col="3" type="text" :placeholder="$t('PLACEHOLDERS.name')" v-model.trim="filterOptions.name"/>
              <base-input col="3" type="text" :placeholder="$t('PLACEHOLDERS.phone')" v-model.trim="filterOptions.phone"/>
              <base-input col="3" type="text" :placeholder="$t('PLACEHOLDERS.email')" v-model.trim="filterOptions.email"/>
              <base-select-input col="3" :optionsList="providerTypes" :placeholder="$t('PLACEHOLDERS.provider_type')"
                                 v-model="filterOptions.flag" />
              <base-select-input col="3" :optionsList="appSubscriptions" :placeholder="$t('PLACEHOLDERS.subscription_in_app')"
                                 v-model="filterOptions.subscriptionStatus" />
              <base-select-input col="3" :optionsList="activeStatuses" :placeholder="$t('PLACEHOLDERS.status')"
                                 v-model="filterOptions.isActive" />
            </div>


            <div class="btns_wrapper">

              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>{{ $t("BUTTONS.search") }}</span>
                </template>
                <span class="submit_btn" @click="submitFilterForm" :disabled="isWaitingRequest">
                  <i class="fal fa-search"></i>
                </span>
              </a-tooltip>

              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>{{ $t("BUTTONS.rseet_search") }}</span>
                </template>
                <span class="reset_btn" :disabled="isWaitingRequest" @click="resetFilter">
                  <i class="fal fa-redo"></i>
                </span>
              </a-tooltip>

            </div>
          </form>
        </div>
      </div>
      <!--  =========== End:: Filter Form =========== -->

      <!--  =========== Start:: Table Title =========== -->
      <div class="table_title_wrapper">
        <div class="title_text_wrapper">
          <h5>{{ $t("PLACEHOLDERS.drivers") }}</h5>
          <button v-if="!filterFormIsActive" class="filter_toggler"
                  @click.stop="filterFormIsActive = !filterFormIsActive">
            <i class="fal fa-search"></i>
          </button>
        </div>
      </div>
      <!--  =========== End:: Table Title =========== -->

      <!--  =========== Start:: Data Table =========== -->
      <v-data-table class="thumb" :loading="loading" :loading-text="$t('TABLES.loadingData')" :search="searchValue"
                    :headers="tableHeaders" :items="tableRows" item-class="ltr"
                    :items-per-page="paginations.items_per_page"
                    hide-default-footer>
        <!-- Start:: No Data State -->
        <template v-slot:no-data>
          {{ $t("TABLES.noData") }}
        </template>
        <!-- Start:: No Data State -->

        <template v-slot:[`item.id`]="{ item, index }">
          <div class="table_image_wrapper">
            <h6 class="text-danger" v-if="!item.id"> {{ $t("TABLES.noData") }} </h6>
            <p v-else>{{ (paginations.current_page - 1) * paginations.items_per_page + index + 1 }}</p>
          </div>
        </template>

        <!-- Start:: Name -->
        <template v-slot:[`item.name`]="{ item }">
          <h6 class="text-danger" v-if="!item.name"> {{ $t("TABLES.noData") }} </h6>
          <h6 v-else> {{ item.name }} </h6>
        </template>

        <template v-slot:[`item.email`]="{ item }">
          <h6 class="text-danger" v-if="!item.email"> - </h6>
          <h6 v-else> {{ item.email }} </h6>
        </template>

        <template v-slot:[`item.is_active`]="{ item }">
          <span class="text-success text-h5" v-if="item.is_active">
            <i class="far fa-check"></i>
          </span>
          <span class="text-danger text-h5" v-else>
            <i class="far fa-times"></i>
          </span>
        </template>

        <template v-slot:[`item.company_name`]="{ item }">
          <h6 class="text-danger" v-if="!item.flag"> - </h6>
          <h6 v-else> {{ item.company_name }} </h6>
        </template>

        <template v-slot:[`item.has_valid_subscription`]="{ item }">
          <h6 class="text-danger" v-if="!item.has_valid_subscription"> {{$t('PLACEHOLDERS.un_Subscribed')}} </h6>
          <h6 class="text-success" v-else> {{$t('PLACEHOLDERS.subscribed')}} </h6>
        </template>
        

        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <a-tooltip placement="bottom" v-if="$can('providers show', 'providers')">
              <template slot="title">
                <span>{{ $t("BUTTONS.show") }}</span>
              </template>
              <button class="btn_show" @click="showItem(item)">
                <i class="fal fa-eye"></i>
              </button>
            </a-tooltip>

            <template v-if="$can('providers activate', 'providers')">
              <a-tooltip placement="bottom" v-if="!item.is_active">
                <template slot="title">
                  <span>{{ $t("BUTTONS.activate") }}</span>
                </template>
                <button class="btn_activate" @click="HandlingItemActivationStatus(item)">
                  <i class="fad fa-check-circle"></i>
                </button>
              </a-tooltip>
              <a-tooltip placement="bottom" v-if="item.is_active">
                <template slot="title">
                  <span>{{ $t("BUTTONS.deactivate") }}</span>
                </template>
                <button class="btn_deactivate" @click="selectDeactivateItem(item)">
                  <i class="fad fa-times-circle"></i>
                </button>
              </a-tooltip>
            </template>

            <a-tooltip placement="bottom">
              <template slot="title">
                <span>{{ $t("PLACEHOLDERS.subscribtions_log") }}</span>
              </template>

              <button class="btn_edit" @click="showItemSubscrptions(item)">
                <i class="fal fa-wallet"></i>
              </button>
            </a-tooltip>
            

          </div>
        </template>

        <template v-slot:top>
          <v-dialog v-model="dialogDeactivate">
            <v-card>
              <v-card-title class="text-h5 justify-center" v-if="itemToChangeActivationStatus">
                {{ $t("TITLES.DeactivateConfirmingMessage", {name: itemToChangeActivationStatus.name}) }}
              </v-card-title>

              <form class="w-100">
                <base-input col="12" rows="3" type="textarea" :placeholder="$t('PLACEHOLDERS.rejectReason')"
                            v-model.trim="deactivateReason" required/>
              </form>

              <v-card-actions>
                <v-btn class="modal_confirm_btn" @click="HandlingItemActivationStatus"
                       :disabled="!(!!deactivateReason)">
                  {{ $t("BUTTONS.ok") }}
                </v-btn>

                <v-btn class="modal_cancel_btn" @click="dialogDeactivate = false">{{ $t("BUTTONS.cancel") }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>

            </v-card>
          </v-dialog>
          

        </template>

      </v-data-table>
    </main>
    <template>
      <div class="pagination_container text-center mt-3 mb-0">
        <v-pagination class="py-0" square v-model="paginations.current_page" :length="paginations.last_page"
                      :total-visible="6" @input="updateRouterQueryParam($event)" :prev-icon="getAppLocale == 'ar' ? 'fal fa-angle-right' : 'fal fa-angle-left'
            " :next-icon="getAppLocale == 'ar' ? 'fal fa-angle-left' : 'fal fa-angle-right'
    "/>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AllProviderManagements",

  computed: {
    ...mapGetters({
      getAppLocale: "AppLangModule/getAppLocale",
    }),
    providerTypes() {
      return [
        {
          id: 1,
          name: this.$t("PLACEHOLDERS.personal"),
          value: 0,
        },
        {
          id: 2,
          name: this.$t("PLACEHOLDERS.company"),
          value: 1,
        },
      ];
    },
    appSubscriptions() {
      return [
        {
          id: 1,
          name: this.$t("PLACEHOLDERS.subscribed"),
          value: 'subscribed',
        },
        {
          id: 2,
          name: this.$t("PLACEHOLDERS.un_Subscribed"),
          value: 'un_subscribed',
        },
      ];
    },
    activeStatuses() {
      return [
        {
          id: 1,
          name: this.$t("STATUS.active"),
          value: 1,
        },
        {
          id: 2,
          name: this.$t("STATUS.notActive"),
          value: 0,
        },
        {
          id: 3,
          name: this.$t("STATUS.all"),
          value: null,
        },
      ];
    },
    genders() {
      return [
        {
          id: 1,
          name: this.$t("STATUS.male"),
          value: 'male',
        },
        {
          id: 2,
          name: this.$t("STATUS.female"),
          value: 'female',
        },
        {
          id: 3,
          name: this.$t("STATUS.all"),
          value: 'both',
        },
      ];
    },
  },

  data() {
    return {
      loading: false,
      isWaitingRequest: false,
      filterFormIsActive: false,
      filterOptions: {
        name: null,
        phone: null,
        email: null,
        isActive: null,
        flag: null,
        subscriptionStatus: null,
        driverActivation: null,
      },
      searchValue: "",
      tableHeaders: [
        {
          text: this.$t("TABLES.Clients.serialNumber"),
          value: "id",
          align: "center",
          width: "80",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Clients.name"),
          value: "name",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Clients.phone"),
          value: "mobile",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Clients.email"),
          value: "email",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.provider_type"),
          value: "flag_text",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.company_name"),
          value: "company_name",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.subscription_in_app"),
          value: "has_valid_subscription",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Clients.active"),
          value: "is_active",
          align: "center",
          width: "120",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.acceptDate"),
          value: "updated_at",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Clients.actions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      tableRows: [],
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 6,
      },
      dialogDeactivate: false,
      itemToChangeActivationStatus: null,
      deactivateReason: null,
      dialogBalance: false,
      itemToBalance: null,
      dialogDelete: false,
      itemToDelete: null,
    };
  },

  watch: {
    ["$route.query.page"]() {
      this.paginations.current_page = +this.$route.query.page;
      this.setTableRows();
    },
  },

  methods: {
    showItemSubscrptions(item) {
      this.$router.push({ path: `/providers-management/subscriptions/${item.id}` });
    },
    async submitFilterForm() {
      if (this.$route.query.page !== '1') {
        await this.$router.push({path: '/providers-management/all', query: {page: 1}});
      }
      this.setTableRows();
    },
    async resetFilter() {
      this.filterOptions.name = null;
      this.filterOptions.phone = null;
      this.filterOptions.email = null;
      this.filterOptions.isActive = null;
      this.filterOptions.subscriptionStatus = null;
      this.filterOptions.driverActivation = null;
      this.filterOptions.flag = null;
      if (this.$route.query.page !== '1') {
        await this.$router.push({path: '/providers-management/all', query: {page: 1}});
      }
      this.setTableRows();
    },
    updateRouterQueryParam(pagenationValue) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: pagenationValue,
        },
      });

      // Scroll To Screen's Top After Get Products
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    async setTableRows() {
      this.loading = true;
      try {

        let nameParam = this.filterOptions.name;
        if (!nameParam) {
          nameParam = null;
        }

        let res = await this.$axios({
          method: "GET",
          url: "providers",
          params: {
            page: this.paginations.current_page,
            name: nameParam,
            mobile: this.filterOptions.phone,
            email: this.filterOptions.email,
            status: this.filterOptions.isActive?.value,
            driverGender: this.filterOptions.gender?.value,
            driverActivation: this.filterOptions.driverActivation?.value,
            flag: this.filterOptions.flag?.value,
            subscriptionStatus: this.filterOptions.subscriptionStatus?.value,
          },
        });
        this.loading = false;
        this.tableRows = res.data.data;
        this.paginations.last_page = res.data.meta.last_page;
        this.paginations.items_per_page = res.data.meta.per_page;
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },

    async changeActivationStatus(item) {
      try {
        await this.$axios({
          method: "POST",
          url: `client/${item.id}/toggle-client`,
        });
        this.setTableRows();
        this.$message.success(this.$t("MESSAGES.changeActivation"));
      } catch (error) {
        this.$message.error(error.response.data.message);
      }
    },
    editItem(item) {
      this.$router.push({ path: `/providers-management/edit/${item.id}` });
    },
    showItem(item) {
      this.$router.push({path: `/providers-management/show/${item.id}`});
    },
    selectDeleteItem(item) {
      this.dialogDelete = true;
      this.itemToDelete = item;
    },
    async confirmDeleteItem() {
      try {
        await this.$axios({
          method: "DELETE",
          url: `providers/${this.itemToDelete.id}`,
        });
        this.dialogDelete = false;
        this.tableRows = this.tableRows.filter((item) => {
          return item.id != this.itemToDelete.id;
        });
        this.setTableRows();
        this.$message.success(this.$t("MESSAGES.deletedSuccessfully"));
      } catch (error) {
        this.dialogDelete = false;
        this.$message.error(error.response.data.message);
      }
    },
    selectDeactivateItem(item) {
      this.dialogDeactivate = true;
      this.itemToChangeActivationStatus = item;
    },
    async HandlingItemActivationStatus(selectedItem, status) {
      if (!status) {
        status = 'rejected';
      }
      this.dialogDeactivate = false;
      let targetItem = this.itemToChangeActivationStatus ? this.itemToChangeActivationStatus : selectedItem;
      const REQUEST_DATA = {};
      REQUEST_DATA.deactivate_reason = this.deactivateReason;
      try {
        await this.$axios({
          method: "POST",
          url: `provider/${targetItem.id}/toggle-provider`,
          data: REQUEST_DATA,
        });
        this.$message.success(this.$t("MESSAGES.changeActivation"));
        this.setTableRows();
        this.itemToChangeActivationStatus = null;
        this.deactivateReason = null;
      } catch (error) {
        this.$message.error(error.response.data.message);
      }
    },
    selectAcceptItem(item) {
      this.dialogBalance = true;
      this.itemToBalance = item;
    },
    async confirmAcceptItem(item) {

      const REQUEST_DATA = new FormData();
      REQUEST_DATA.append("amount", this.balance_package);

      try {
        await this.$axios({
          method: "POST",
          url: `client/charge-client-wallet/${this.itemToBalance.id}`,
          data: REQUEST_DATA,
        });
        this.dialogBalance = false;
        this.balance_package = null,
          this.setTableRows();
        this.$message.success(this.$t("MESSAGES.verifiedSuccessfully"));
      } catch (error) {
        this.dialogBalance = false;
        this.$message.error(error.response.data.message);
      }
    },
    // ==================== End:: Crud ====================
  },

  created() {
    // Start:: Fire Methods
    window.addEventListener("click", () => {
      this.filterFormIsActive = false;
    });
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setTableRows();
    // End:: Fire Methods
  },
};
</script>
<style>
span.submit_btn {
  width: 45px;
  height: 45px;
  font-size: 16px;
  border-radius: 10px;
  color: var(--white_clr);
  transition: all 0.3s linear;
  background-color: #9B6300;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>