<template>
  <div class="show_all_content_wrapper">
    <!-- Start:: Main Section -->
    <main>
      <!--  =========== Start:: Filter Form =========== -->
      <div class="filter_content_wrapper" :class="{ active: filterFormIsActive }">
        <button class="filter_toggler" @click="filterFormIsActive = !filterFormIsActive">
          <i class="fal fa-times"></i>
        </button>
        <div class="filter_title_wrapper">
          <h5>{{ $t("TITLES.searchBy") }}</h5>
        </div>
        <div class="filter_form_wrapper">
          <form @submit.prevent="submitFilterForm">
            <div class="row justify-content-center align-items-center w-100">
              <base-input col="3" type="text" :placeholder="$t('PLACEHOLDERS.post_name')" v-model.trim="filterOptions.post" />
              
              <base-input col="3" type="text" :placeholder="$t('TABLES.Clients.username')" v-model.trim="filterOptions.user" />
              
              <base-select-input col="3" :optionsList="activeStatuses" :placeholder="$t('PLACEHOLDERS.status')"
                v-model="filterOptions.isActive" />
            </div>
            
            <div class="btns_wrapper">

              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>{{ $t("BUTTONS.search") }}</span>
                </template>
                <span class="submit_btn" @click="submitFilterForm" :disabled="isWaitingRequest">
                  <i class="fal fa-search"></i>
                </span>
              </a-tooltip>

              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>{{ $t("BUTTONS.rseet_search") }}</span>
                </template>
                <span class="reset_btn" :disabled="isWaitingRequest" @click="resetFilter">
                  <i class="fal fa-redo"></i>
                </span>
              </a-tooltip>

            </div>
          </form>
        </div>
      </div>
      <!--  =========== End:: Filter Form =========== -->

      <!--  =========== Start:: Table Title =========== -->
      <div class="table_title_wrapper">
        <div class="title_text_wrapper">
          <h5>{{ $t("TABLES.PromotedPosts.name") }}</h5>
          <button v-if="!filterFormIsActive" class="filter_toggler"
            @click.stop="filterFormIsActive = !filterFormIsActive">
            <i class="fal fa-search"></i>
          </button>
        </div>
      </div>
      <!--  =========== End:: Table Title =========== -->

      <!--  =========== Start:: Data Table =========== -->
      <v-data-table class="thumb" :loading="loading" :loading-text="$t('TABLES.loadingData')" :search="searchValue"
        :headers="tableHeaders" :items="tableRows" item-class="ltr" :items-per-page="paginations.items_per_page"
        hide-default-footer>
        <!-- Start:: No Data State -->
        <template v-slot:no-data>
          {{ $t("TABLES.noData") }}
        </template>
        <!-- Start:: No Data State -->

        <template v-slot:[`item.id`]="{ item, index }">
          <div class="table_image_wrapper">
            <h6 class="text-danger" v-if="!item.id"> {{ $t("TABLES.noData") }} </h6>
            <p v-else>{{ (paginations.current_page - 1) * paginations.items_per_page + index + 1 }}</p>
          </div>
        </template>

        <!-- Start:: Name -->
        <template v-slot:[`item.user.name`]="{ item }">
          <h6 class="text-danger" v-if="!item.user.name"> {{ $t("TABLES.noData") }} </h6>
          <h6 v-else> {{ item.user.name }}
          <i v-if="item.user.is_documented" class="fa fa-certificate" style="color: blue"></i>
          </h6>
        </template>
        <!-- End:: Name -->

        <template v-slot:[`item.media`]="{ item }">
          <template>
            <h6 class="text-danger" v-if="!item.media"> {{ $t("TABLES.noData") }} </h6>
            <div class="actions" v-else>
              <button class="btn_show" @click="showMediaModal(item.media,item.type)">
                <i class="fal fa-video" v-if="item.type=='video'"></i>
                <i class="fal fa-image" v-else></i>
              </button>
            </div>
          </template>
        </template>

        <!-- Start:: Activation -->
        <template v-slot:[`item.user.is_active`]="{ item }">
          <div class="activation" dir="ltr" style="z-index: 1" v-if="hasPermission('posts-activate')">
            <v-switch class="mt-2" color="success" v-model="item.user.is_active" hide-details
              @change="changeActivationStatus(item)"></v-switch>
          </div>

          <template v-else>
            <span class="text-success text-h5" v-if="item.user.is_active">
              <i class="far fa-check"></i>
            </span>
            <span class="text-danger text-h5" v-else>
              <i class="far fa-times"></i>
            </span>
          </template>
        </template>
        <!-- End:: Activation -->

        <!-- Start:: Actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <a-tooltip placement="bottom" v-if="hasPermission('promoted-posts-show')">
              <template slot="title">
                <span>{{ $t("BUTTONS.show") }}</span>
              </template>
              <button class="btn_show" @click="showItem(item)">
                <i class="fal fa-eye"></i>
              </button>
            </a-tooltip>

            <!--<a-tooltip placement="bottom">-->
              <!--<template slot="title">-->
                <!--<span>{{ $t("PLACEHOLDERS.documented_subscribtions") }}</span>-->
              <!--</template>-->
              <!--<button class="btn_edit" @click="showItemDocumentedSubscrptions(item)">-->
                <!--<i class="fa fa-certificate"></i>-->
              <!--</button>-->
            <!--</a-tooltip>-->
            
            <!--<a-tooltip placement="bottom">-->
              <!--<template slot="title">-->
                <!--<span>{{ $t("PLACEHOLDERS.ads_subscribtions") }}</span>-->
              <!--</template>-->
              <!---->
              <!--<button class="btn_edit" @click="showItemAdsSubscrptions(item)">-->
                <!--<i class="fal fa-wallet"></i>-->
              <!--</button>-->
            <!--</a-tooltip>-->

            <!-- <template v-else>
              <i class="fal fa-lock-alt fs-5 blue-grey--text text--darken-1"></i>
            </template> -->

            <!--<template v-if="hasPermission('posts-activate')">-->
              <!--<a-tooltip placement="bottom" v-if="item.status!='published'">-->
                <!--<template slot="title">-->
                  <!--<span>{{ $t("TABLES.Posts.publish") }}</span>-->
                <!--</template>-->
                <!--<button class="btn_activate" @click="HandlingItemActivationStatus(item)">-->
                  <!--<i class="fad fa-check-circle"></i>-->
                <!--</button>-->
              <!--</a-tooltip>-->
              <!--<a-tooltip placement="bottom" v-else>-->
                <!--<template slot="title">-->
                  <!--<span>{{ $t("TABLES.Posts.block") }}</span>-->
                <!--</template>-->
                <!--<button class="btn_deactivate" @click="selectDeactivateItem(item)">-->
                  <!--<i class="fad fa-times-circle"></i>-->
                <!--</button>-->
              <!--</a-tooltip>-->
            <!--</template>-->

          </div>
        </template>
        <!-- End:: Actions -->

        <!-- ======================== Start:: Dialogs ======================== -->
        <template v-slot:top>
          <image-modal v-if="dialogMedia" :modalIsOpen="dialogMedia" :modalImage="mediaSrc" @toggleModal="dialogMedia = !dialogMedia" />
          
          <video-modal v-if="dialogVideo" :modalIsOpen="dialogVideo" :modalVideo="mediaSrc" @toggleModal="dialogVideo = !dialogVideo" />
          <!-- Start:: Deactivate Modal -->
          <v-dialog v-model="dialogDeactivate">
            <v-card>
              <v-card-title class="text-h5 justify-center" v-if="itemToChangeActivationStatus">
                {{ $t("TITLES.DeactivateConfirmingMessage", { name: itemToChangeActivationStatus.title }) }}
              </v-card-title>

              <form class="w-100">
                <base-input col="12" rows="3" type="textarea" :placeholder="$t('PLACEHOLDERS.deactivateReason')"
                  v-model.trim="deactivateReason" required />
              </form>

              <v-card-actions>
                <v-btn class="modal_confirm_btn" @click="HandlingItemActivationStatus" :disabled="!(!!deactivateReason)">
                  {{ $t("BUTTONS.ok") }}
                </v-btn>

                <v-btn class="modal_cancel_btn" @click="dialogDeactivate = false">{{ $t("BUTTONS.cancel") }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- End:: Deactivate Modal -->
          

        </template>
        <!-- ======================== End:: Dialogs ======================== -->

      </v-data-table>
      <!--  =========== End:: Data Table =========== -->
    </main>
    <!-- End:: Main Section -->

    <!-- Start:: Pagination -->
    <template>
      <div class="pagination_container text-center mt-3 mb-0">
        <v-pagination class="py-0" square v-model="paginations.current_page" :length="paginations.last_page"
          :total-visible="6" @input="updateRouterQueryParam($event)" :prev-icon="getAppLocale == 'ar' ? 'fal fa-angle-right' : 'fal fa-angle-left'
            " :next-icon="getAppLocale == 'ar' ? 'fal fa-angle-left' : 'fal fa-angle-right'
    " />
      </div>
    </template>
    <!-- End:: Pagination -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AllPromotedPosts",

  computed: {
    ...mapGetters({
      getAppLocale: "AppLangModule/getAppLocale",
    }),

    activeStatuses() {
      return [
        {
          id: 1,
          name: this.$t("PLACEHOLDERS.sari"),
          value: 'active',
        },
        {
          id: 2,
          name: this.$t("PLACEHOLDERS.montahi"),
          value: 'finished',
        },
      ];
    },
  },

  data() {
    return {
      dialogMedia:false,
      dialogVideo:false,
      mediaSrc:'',
      mediaType:'',
      // Start:: Loading Data
      loading: false,
      promoted: false,
      isWaitingRequest: false,
      // End:: Loading Data

      // Start:: Filter Data
      filterFormIsActive: false,
      filterOptions: {
        user: null,
        post: null,
        isActive: null,
      },
      user_id:null,
      // End:: Filter Data

      // Start:: Table Data
      searchValue: "",
      tableHeaders: [
        {
          text: this.$t("TABLES.Clients.serialNumber"),
          value: "id",
          align: "center",
          width: "80",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Clients.name"),
          value: "user.name",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Clients.phone"),
          value: "user.mobile",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.post_name"),
          value: "post.title",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.PromotedPosts.duration"),
          value: "duration",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.PromotedPosts.total"),
          value: "total",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("TABLES.PromotedPosts.active"),
          value: "status",
          align: "center",
          width: "120",
          sortable: false,
        },
        {
          text: this.$t("TABLES.Clients.actions"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      tableRows: [],
      // End:: Table Data

      // Start:: Pagination Data
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 6,
      },
      // End:: Pagination Data

      dialogDeactivate: false,
      itemToChangeActivationStatus: null,
      deactivateReason: null,

      dialogBalance: false,
      itemToBalance: null,

    };
  },

  watch: {
    // Start:: Page Query Param Watcher To Get Page Data Based On It's Change
    ["$route.query.page"]() {
      this.paginations.current_page = +this.$route.query.page;
      this.setTableRows();
    },
    // End:: Page Query Param Watcher To Get Page Data Based On It's Change
  },

  methods: {

    showMediaModal(media,type){
      if(type=='image'){
        this.dialogMedia = true;
      }else{
        this.dialogVideo = true;
      }
      this.mediaType = type;
      this.mediaSrc = media;
    },
    // Start:: Handel Filter
    async submitFilterForm() {
      if (this.$route.query.page !== '1') {
        await this.$router.push({ path: '/promotedPosts/all', query: { page: 1 } });
      }
      this.setTableRows();
    },
    async resetFilter() {
      this.filterOptions.post = null;
      this.filterOptions.phone = null;
      this.filterOptions.username = null;
      this.filterOptions.title = null;
      this.filterOptions.isActive = null;
      if (this.$route.query.page !== '1') {
        await this.$router.push({ path: '/promotedPosts/all', query: { page: 1 } });
      }
      this.setTableRows();
    },
    // End:: Handel Filter

    // Start:: Set Table Rows
    updateRouterQueryParam(pagenationValue) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: pagenationValue,
        },
      });

      // Scroll To Screen's Top After Get Products
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    async setTableRows() {
      this.loading = true;
      try {

        let nameParam = this.filterOptions.name;
        if (!nameParam) {
          nameParam = null;
        }
        
        let res = await this.$axios({
          method: "GET",
          url: "promoted-post",
          params: {
            page: this.paginations.current_page,
            user: this.filterOptions.user,
            user_id: this.userId,
            post: this.filterOptions.post,
            status: this.filterOptions.isActive?.value,
          },
        });
        this.loading = false;
        // console.log("All Data ==>", res.data.data);
        this.tableRows = res.data.data;
        this.paginations.last_page = res.data.meta.last_page;
        this.paginations.items_per_page = res.data.meta.per_page;
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },

    async changeActivationStatus(item) {
      try {
        await this.$axios({
          method: "POST",
          url: `posts/activate/${item.user.id}`,
        });
        this.setTableRows();
        this.$message.success(this.$t("MESSAGES.changeActivation"));
      } catch (error) {
        this.$message.error(error.response.data.message);
      }
    },

    showItem(item) {
      this.$router.push({ path: `/promotedPosts/show/${item.id}` });
    },

    selectDeactivateItem(item) {
      this.dialogDeactivate = true;
      this.itemToChangeActivationStatus = item;
    },
    async HandlingItemActivationStatus(selectedItem) {
      this.dialogDeactivate = false;
      let targetItem = this.itemToChangeActivationStatus ? this.itemToChangeActivationStatus : selectedItem;
      const REQUEST_DATA = {};
      REQUEST_DATA.status = targetItem.status=='published'?'blocked':'published';
      if(targetItem.status=='published'){
      REQUEST_DATA.reason = this.deactivateReason;
    }
      try {
        await this.$axios({
          method: "PUT",
          url: `posts/${targetItem.id}`,
          data: REQUEST_DATA,
        });
        this.$message.success(this.$t("MESSAGES.changeActivation"));
        this.setTableRows();
        this.itemToChangeActivationStatus = null;
        this.deactivateReason = null;
      } catch (error) {
        this.$message.error(error.response.data.message);
      }
    },
    // ===== End:: Handling Activation & Deactivation

    selectAcceptItem(item) {
      this.dialogBalance = true;
      this.itemToBalance = item;
    },
    async confirmAcceptItem(item) {
      
      try {
        await this.$axios({
          method: "POST",
          url: `client/pay-un-paid-trip-taxes/${this.itemToBalance.id}`
        }).then((data) => {
          this.dialogBalance = false;
          this.setTableRows();
          this.$message.success(data.data.message);
        });

      } catch (error) {
        this.dialogBalance = false;
        this.$message.error(error.response.data.message);
      }
    },
    // ==================== End:: Crud ====================
  },

  created() {
    // Start:: Fire Methods
    window.addEventListener("click", () => {
      this.filterFormIsActive = false;
    });
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    
    if (this.$route.query.user_id) {
      this.user_id = this.$route.query.user_id;
    }
    this.setTableRows();
    // End:: Fire Methods
  },
};
</script>
<style>
span.submit_btn {
  width: 45px;
  height: 45px;
  font-size: 16px;
  border-radius: 10px;
  color: var(--white_clr);
  transition: all 0.3s linear;
  background-color: #9B6300;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>