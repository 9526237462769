<template>
  <div class="crud_form_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("SIDENAV.Teams.edit") }}</h4>
    </div>
    <!-- End:: Title -->

    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameAr')" v-model.trim="data.nameAr" required/>
          <!-- End:: Name Input -->

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameEn')" v-model.trim="data.nameEn" required/>
          <!-- End:: Name Input -->
          <!-- Start:: Sport Input -->
          <base-select-input col="6" :optionsList="sportList" :placeholder="$t('SIDENAV.Sports.name')"
                             v-model="data.sport"/>
          <!-- End:: Sport Input -->
          <!-- Start:: Image Upload Input -->
          <base-image-upload-input col="6" identifier="image" :preSelectedImage="data.image.path"
                                    @selectImage="selectImage" :placeholder="$t('PLACEHOLDERS.image')" required />
          <!-- End:: Image Upload Input -->
          <!-- Start:: Deactivate Switch Input -->
          <div class="input_wrapper switch_wrapper my-5">
            <v-switch color="green" :label="data.active ? $t('PLACEHOLDERS.active') : $t('PLACEHOLDERS.notActive')"
                      v-model="data.active" hide-details ></v-switch>
          </div>

          <!-- Start:: Submit Button Wrapper -->
          <div class="btn_wrapper">
            <base-button class="mt-2" styleType="primary_btn" :btnText="$t('BUTTONS.save')" :isLoading="isWaitingRequest"
              :disabled="isWaitingRequest" />
          </div>
          <!-- End:: Submit Button Wrapper -->
        </div>
      </form>
    </div>
    <!-- END:: Single Step Form Content -->
  </div>
</template>

<script>

export default {
  name: "EditTeam",

  data() {
    return {
      // Start:: Loader Control Data
      isWaitingRequest: false,
      // End:: Loader Control Data
      sportList: [],
      // Start:: Data Collection To Send
      data: {
        nameAr: null,
        nameEn: null,
        sport: null,
        active: true,
        image: {
          path: null,
          file: null,
        },
      },
      // End:: Data Collection To Send
    };
  },

  computed: {
  },

  methods: {
    // Start:: Select Upload Image
    selectImage(selectedImage) {
      console.log(selectedImage);
      this.data.image = selectedImage;
    },
    // End:: Select Upload Image
    async getSports() {
    try {
      let res = await this.$axios({
        method: "GET",
        url: `sports?page=0&limit=0`,
      });
      this.sportList=res.data.data;
    } catch (error) {
      console.log(error.response.data.message);
    }
  },
    // Start:: validate Form Inputs
    validateFormInputs() {
      this.isWaitingRequest = true;

      if (!this.data.nameAr) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.nameAr"));
        return;
      } else if (!this.data.nameEn) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.nameEn"));
        return;
      } else if (!this.data.sport) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.sport"));
        return;
      } else {
        this.submitForm();
        return;
      }
    },
    // End:: validate Form Inputs

    // Start:: Submit Form
    async submitForm() {
      const REQUEST_DATA = new FormData();
      // Start:: Append Request Data
  REQUEST_DATA.append("name[ar]", this.data.nameAr);
  REQUEST_DATA.append("name[en]", this.data.nameEn);
  REQUEST_DATA.append("sport_id", this.data.sport.id);
  if (this.data.image.file) {
    REQUEST_DATA.append("image", this.data.image.file);
  }
      REQUEST_DATA.append("is_active", +this.data.active);
      REQUEST_DATA.append("_method", "PUT");
      // Start:: Append Request Data

      try {
        await this.$axios({
          method: "POST",
          url: `teams/${this.$route.params.id}`,
          data: REQUEST_DATA,
        });
        this.isWaitingRequest = false;
        this.$message.success(this.$t("MESSAGES.editedSuccessfully"));
        this.$router.push({ path: "/teams/all" });
      } catch (error) {
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.message);
      }
    },

    // start show data
    async showTeam() {
      try {
        let res = await this.$axios({
          method: "GET",
          url: `teams/${this.$route.params.id}`,
        });
        this.data.nameAr = res.data.data.Team.trans.name.ar;
        this.data.nameEn = res.data.data.Team.trans.name.en;
        this.data.active = res.data.data.Team.is_active;
        this.data.image.path = res.data.data.Team.image;
        this.data.sport = res.data.data.Team.sport;
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },
    // end show data

  },

  created() {
    // Start:: Fire Methods
    this.showTeam();
    this.getSports();
    // End:: Fire Methods
  },
};
</script>
