<template>
  <div class="show_all_content_wrapper">
    <main>
      <div class="filter_content_wrapper" :class="{ active: filterFormIsActive }">
        <button class="filter_toggler" @click="filterFormIsActive = !filterFormIsActive">
          <i class="fal fa-times"></i>
        </button>
        <div class="filter_title_wrapper">
          <h5>{{ $t("TITLES.searchBy") }}</h5>
        </div>

        <div class="filter_form_wrapper">
          <form @submit.prevent="submitFilterForm">
            <div class="row justify-content-center align-items-center w-100">

              <base-picker-input col="6" type="date" :placeholder="$t('TABLES.Coupons.startDate')"
                                 v-model.trim="filterOptions.dateFrom"/>

              <base-picker-input col="6" type="date" :placeholder="$t('TABLES.Coupons.endDate')"
                                 v-model.trim="filterOptions.dateTo"/>
            </div>

            <div class="btns_wrapper">
              <button class="submit_btn" :disabled="isWaitingRequest">
                <i class="fal fa-search"></i>
              </button>
              <button class="reset_btn" type="button" :disabled="isWaitingRequest" @click="resetFilter">
                <i class="fal fa-redo"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
      <!--  =========== End:: Filter Form =========== -->

      <!--  =========== Start:: Table Title =========== -->
      <div class="table_title_wrapper">
        <div class="title_text_wrapper">
          <h5>{{ $t("SIDENAV.Reports.title") }}</h5>
          <button v-if="!filterFormIsActive" class="filter_toggler"
                  @click.stop="filterFormIsActive = !filterFormIsActive">
            <i class="fal fa-search"></i>
          </button>
        </div>
        <div class="title_route_wrapper">
          <base-button class="mt-0 pdf_btn" styleType="solid_btn" :btnText="$t('BUTTONS.downloadPdf')"
                       @fireClick="downloadPdf" :disabled="pdfDownloadBtnIsLoading">
            <template v-slot:btn_icon>
              <i class="fal fa-file-pdf"></i>
            </template>
          </base-button>


          <download-excel
            class="btn btn-default"
            :data="json_data"
            :fields="json_fields"
            worksheet="My Worksheet"
            :name="$t('SIDENAV.subscription_reports.title')">

            <base-button
              class="mt-0 excel_btn"
              styleType="solid_btn"
              :btnText="$t('BUTTONS.downloadExcel')"
            >
              <template v-slot:btn_icon>
                <i class="fal fa-file-excel"></i>
              </template>
            </base-button>

          </download-excel>
        </div>
      </div>
      <!--  =========== End:: Table Title =========== -->

      <!--  =========== Start:: Data Table =========== -->
      <v-data-table class="thumb" :loading="loading" :loading-text="$t('TABLES.loadingData')" :search="searchValue"
                    :headers="tableHeaders" :items="tableRows" item-class="ltr"  :items-per-page="paginations.items_per_page"
                    hide-default-footer>
        <!-- Start:: No Data State -->
        <template v-slot:no-data>
          {{ $t("TABLES.noData") }}
        </template>

        <!--<template v-slot:[`item.id`]="{ item, index }">-->
          <!--<div class="table_image_wrapper">-->
            <!--<h6 class="text-danger" v-if="!item.id"> {{ $t("TABLES.noData") }} </h6>-->
            <!--<p v-else>{{ index + 1 }}</p>-->
          <!--</div>-->
        <!--</template>-->

        <!--<template v-slot:[`item.title`]="{ item }">-->
          <!--<p class="text-danger" v-if="!item.title"> {{ $t("TABLES.noData") }} </p>-->
          <!--<p v-else>{{ item.title }}</p>-->
        <!--</template>-->

        <template v-slot:[`item.orders_sum_total`]="{ item }">
          <!--<router-link :to="{path:`/orders?user_id=${item.id}`} ">-->
          <p style="color: darkblue;" v-if="!item.orders_sum_total"> 0.000 </p>
          <p style="color: darkblue;" v-else>{{ parseFloat(item.orders_sum_total).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</p>
          <!--</router-link>-->
        </template>
        <template v-slot:[`item.live_package_sum_total`]="{ item }">
          <!--<router-link :to="{path:`/orders?user_id=${item.id}`} ">-->
          <p style="color: darkblue;" v-if="!item.live_package_sum_total"> 0.000 </p>
          <p style="color: darkblue;" v-else>{{ parseFloat(item.live_package_sum_total).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</p>
          <!--</router-link>-->
        </template>
        <template v-slot:[`item.documented_sum_total`]="{ item }">
          <router-link :to="{path:`/clients/documented-subscriptions/{item.id}`} ">
          <p style="color: darkblue;" v-if="!item.documented_sum_total"> 0.000 </p>
          <p style="color: darkblue;" v-else>{{ parseFloat(item.documented_sum_total).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</p>
          </router-link>
        </template>
        <template v-slot:[`item.promoted_posts_sum_total`]="{ item }">
          <router-link :to="{path:`/clients/subscriptions/${item.id}`} ">
          <p style="color: darkblue;" v-if="!item.promoted_posts_sum_total"> 0.000 </p>
          <p style="color: darkblue;" v-else>{{ parseFloat(item.promoted_posts_sum_total).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</p>
          </router-link>
        </template>

        <template v-slot:[`item.app_amount`]="{ item }">
          <p v-if="!item.app_amount"> 0.000</p>
          <p v-else>{{ parseFloat(item.app_amount).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</p>
        </template>

        <template v-slot:[`item.tax_amount`]="{ item }">
          <p v-if="!item.tax_amount"> 0.000</p>
          <p v-else>{{ parseFloat(item.tax_amount).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</p>
        </template>

        <template slot="body.append">
          <tr class="text-center">
            <td colspan="2"><strong>{{ $t('PLACEHOLDERS.total_with_taxs') }}</strong></td>
            <td ><strong>{{ parseFloat(total_items_amount).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
            <td colspan="2"><strong>{{ $t('PLACEHOLDERS.Total app profit') }}</strong></td>
            <td><strong>{{ parseFloat(total_app_amount).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
            <td colspan="2"><strong>{{ $t('PLACEHOLDERS.Total added tax') }}</strong></td>
            <td><strong>{{ parseFloat(total_tax_amount).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
          </tr>
        </template>

      </v-data-table>
    </main>
    <template>
      <div class="pagination_container text-center mt-3 mb-0">
        <v-pagination class="py-0" square v-model="paginations.current_page" :length="paginations.last_page"
                      :total-visible="6" @input="updateRouterQueryParam($event)" :prev-icon="getAppLocale == 'ar' ? 'fal fa-angle-right' : 'fal fa-angle-left'
            " :next-icon="getAppLocale == 'ar' ? 'fal fa-angle-left' : 'fal fa-angle-right'
    " />
      </div>
    </template>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
                  :filename="$t('SIDENAV.subscription_reports.title')" :pdf-quality="2" pdf-format="a4" :manual-pagination="false"
                  :paginate-elements-by-height="1400" pdf-content-width="100%"
                  @progress="bdfDownloadBtnIsLoading = true"
                  @hasGenerated="$message.success($t('PLACEHOLDERS.generatedSuccessfully'))" ref="html2Pdf">
      <section slot="pdf-content">
        <div class="pdf_file_content text-center">
          <h1 class="file_title"> {{ $t('SIDENAV.Reports.title') }} </h1>

          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th v-for="header in tableHeaders" :key="header.value">
                  {{ header.text }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="row in tableRows" :key="row.id">
                <td>{{ row.id }}</td>
                <td>{{ row.name }}</td>
                <td>{{ row.mobile }}</td>
                <td>{{ row.orders_sum_total ? parseFloat(row.orders_sum_total).toFixed(3) : '0.000' }}
                  {{ $t("PLACEHOLDERS.sar") }}
                </td>
                <td>{{ row.live_package_sum_total ? parseFloat(row.live_package_sum_total).toFixed(3) : '0.000' }}
                  {{ $t("PLACEHOLDERS.sar") }}
                </td>
                <td>{{ row.documented_sum_total ? parseFloat(row.documented_sum_total).toFixed(3) : '0.000' }}
                  {{ $t("PLACEHOLDERS.sar") }}
                </td>
                <td>{{ row.promoted_posts_sum_total ? parseFloat(row.promoted_posts_sum_total).toFixed(3) : '0.000' }}
                  {{ $t("PLACEHOLDERS.sar") }}
                </td>
                <td>{{ row.app_amount ? parseFloat(row.app_amount).toFixed(3) : '0.000' }}
                  {{ $t("PLACEHOLDERS.sar") }}
                </td>
                <td>{{ row.tax_amount ? parseFloat(row.tax_amount).toFixed(3) : '0.000' }}
                  {{ $t("PLACEHOLDERS.sar") }}
                </td>
              </tr>
              <tr class="text-center">
                <td colspan="2"><strong>{{ $t('PLACEHOLDERS.total_with_taxs') }}</strong></td>
                <td ><strong>{{ parseFloat(total_items_amount).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
                <td colspan="2"><strong>{{ $t('PLACEHOLDERS.Total app profit') }}</strong></td>
                <td><strong>{{ parseFloat(total_app_amount).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
                <td colspan="2"><strong>{{ $t('PLACEHOLDERS.Total added tax') }}</strong></td>
                <td><strong>{{ parseFloat(total_tax_amount).toFixed(3) }} {{ $t("PLACEHOLDERS.sar") }}</strong></td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </div>
      </section>
    </vue-html2pdf>

  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import VueHtml2pdf from 'vue-html2pdf';

export default {
  name: "AllReports",
  components: {
    VueHtml2pdf,
  },
  computed: {
    ...mapGetters({
      getAppLocale: "AppLangModule/getAppLocale",
    }),
    activeStatuses() {
      return [
        {
          id: 1,
          name: this.$t("STATUS.active"),
          value: 1,
        },
        {
          id: 2,
          name: this.$t("STATUS.notActive"),
          value: 0,
        },
        {
          id: null,
          name: this.$t("STATUS.all"),
          value: 'both',
        },
      ];
    },
  },

  data() {
    return {
      drivers: [],
      json_fields: {},
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      loading: false,
      pdfDownloadBtnIsLoading: false,
      showTotals: true,
      isWaitingRequest: false,
      filterFormIsActive: false,
      filterOptions: {
        dateFrom: null,
        dateTo: null,
      },
      searchValue: "",
      tableHeaders: [
        {
          text: this.$t("TABLES.StoresTypes.serialNumber"),
          value: "id",
          align: "center",
          width: "80",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.name"),
          value: "name",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("PLACEHOLDERS.mobile"),
          value: "mobile",
          align: "center",
          sortable: false,
          width: "120",
        },
        {
          text: this.$t("PLACEHOLDERS.Total orders"),
          value: "orders_sum_total",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.Total live packages"),
          value: "live_package_sum_total",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.Total document packages"),
          value: "documented_sum_total",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.Total promoted posts"),
          value: "promoted_posts_sum_total",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("PLACEHOLDERS.Total app profit"),
          value: "app_amount",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("PLACEHOLDERS.Total added tax"),
          value: "tax_amount",
          sortable: false,
          align: "center",
        },
      ],
      tableRows: [],
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 6,
      },
      total_app_amount: 0,
      total_tax_amount: 0,
      total_items_amount: 0,
      // End:: Dialogs Control Data
    };
  },
watch: {
  // Start:: Page Query Param Watcher To Get Page Data Based On It's Change
  ["$route.query.page"]() {
    this.paginations.current_page = +this.$route.query.page;
    this.setTableRows();
  },
  // End:: Page Query Param Watcher To Get Page Data Based On It's Change
},

  methods: {
    async downloadPdf() {
      await this.$refs.html2Pdf.generatePdf();
      this.pdfDownloadBtnIsLoading = false;
    },
    sumTotals(prop) {
      console.log(prop)
      let total = 0;
      for (const item of this.tableRows) {
        total += parseFloat(item[prop]) || 0;
      }
      return total.toFixed(3);
    },

    async submitFilterForm() {
      this.setTableRows();
    },
    async resetFilter() {
      this.filterOptions.dateFrom = null;
      this.filterOptions.dateTo = null;
      this.setTableRows();
    },
    updateRouterQueryParam(pagenationValue) {
      this.$router.push({
        query: {
          ...this.$route.query,
        page: pagenationValue,
      },
    });

      // Scroll To Screen's Top After Get Products
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    async setTableRows() {
      this.loading = true;
      try {
        let res = await this.$axios({
          method: "GET",
          url: "reports",
          params: {
            page: this.paginations.current_page,
            from: this.filterOptions.dateFrom,
            to: this.filterOptions.dateTo,
          },
        });
        this.loading = false;
        let response=res.data.report_paginate.data;
        if (typeof response === 'object' && response !== null && !Array.isArray(response)) {
          this.tableRows = Object.keys(response).map(key => response[key]);
        }else{
          this.tableRows = res.data.report_paginate.data
        }
        this.total_app_amount = res.data.total_app_amount;
        this.total_tax_amount = res.data.total_tax_amount;
        this.total_items_amount = res.data.total_items_amount;
        this.json_data = res.data.report
        this.paginations.last_page = res.data.report_paginate.last_page;
        this.paginations.items_per_page = res.data.report_paginate.per_page;
      } catch (error) {
        this.loading = false;
        console.log(error.response.data.message);
      }
    },
    handleExcel() {
      this.tableHeaders.map((item) => {
        this.json_fields[item.text] = item.value;
      })

      for (let key in this.json_fields) {
        if (this.json_fields.hasOwnProperty(key)) {
          if (this.json_fields[key] === 'orders_sum_total') {
            this.json_fields[key] = {
              field: "orders_sum_total",
              callback: value => {
                return value > 0 ? value : '0.000';
              }
            }
          }

          if (this.json_fields[key] === 'live_package_sum_total') {
            this.json_fields[key] = {
              field: "live_package_sum_total",
              callback: value => {
                return value > 0 ? value : '0.000';
              }
            }
          }
          if (this.json_fields[key] === 'documented_sum_total') {
            this.json_fields[key] = {
              field: "documented_sum_total",
              callback: value => {
                return value > 0 ? value : '0.000';
              }
            }
          }

          if (this.json_fields[key] === 'promoted_posts_sum_total') {
            this.json_fields[key] = {
              field: "promoted_posts_sum_total",
              callback: value => {
                return value > 0 ? value : '0.000';
              }
            }
          }
          if (this.json_fields[key] === 'app_amount') {
            this.json_fields[key] = {
              field: "app_amount",
              callback: value => {
                return value > 0 ? value : '0.000';
              }
            }
          }
          if (this.json_fields[key] === 'tax_amount') {
            this.json_fields[key] = {
              field: "tax_amount",
              callback: value => {
                return value > 0 ? value : '0.000';
              }
            }
          }
        }
      }

    }
  },

  created() {
    window.addEventListener("click", () => {
      // this.filterFormIsActive = false;
    });
  if (this.$route.query.page) {
    this.paginations.current_page = +this.$route.query.page;
  }
    this.setTableRows();
    this.handleExcel()
  },
};
</script>
